import React from 'react';
import type { FC } from 'react';

import type { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { useSelector } from 'react-redux';
import { hasAccessTo } from 'store/selectors/permissions';

export interface IAccessControlledOrComponentProps {
    feature: PermissionFeature;
    action: PermissionAction;
    can?: JSX.Element;
    not?: JSX.Element;
}

export const AccessControlledOrComponent: FC<IAccessControlledOrComponentProps> = (props) => {
    const canAccess = useSelector(hasAccessTo(props.feature, props.action));

    return (
        <React.Fragment>
            { canAccess ? props.can : props.not }
        </React.Fragment>
    );
}

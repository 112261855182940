export interface ITaxInfo {
    short: string;
    id?: string;
    type: TaxIDType;
}

export enum TaxIDType {
    SSN = 'ssn',
    EIN = 'ein',
    ITIN = 'itin',
}

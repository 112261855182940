import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';

import { GlobalState } from 'store';
import { getUser } from 'store/selectors/auth';

const mapStateToProps = (state: GlobalState) => ({
    user: getUser(state),
});

interface ISentryUserProps extends ReturnType<typeof mapStateToProps> {}

const SentryUserBase: React.FC<ISentryUserProps> = (props) => {
    useEffect(() => {
        if (props.user) {
            Sentry.setUser({
                id: props.user.id,
                email: props.user.email,
            });
        } else {
            Sentry.setUser(null);
        }
    }, [props.user]);

    return null;
}

export const SentryUser = connect(mapStateToProps)(SentryUserBase);

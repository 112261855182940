import { ThunkAction } from 'redux-thunk';

import { GlobalState } from 'store';
import { getInventoriesForSelectedOrg } from 'store/selectors/inventories';
import { getSelectedOrgShortId } from 'store/selectors/org';
import {
    TractActionType,
    TractsFetchPendingAction,
    TractsFetchSuccessCacheAction,
    TractsFetchSuccessAction,
    TractsFetchFailureAction,
    TractsFetchAllSuccessAction,
    TractRelatedAction,
    TractsFetchRelatedPendingAction,
    TractsFetchRelatedSuccessAction,
    TractsFetchRelatedFailureAction,
} from 'store/types/tracts';

import { IRestError } from 'models/common/restResult';
import { ITract } from 'models/tract';
import { IRelatedTo } from 'models/common/relatedTo';

import { getTracts, getTractByID } from 'api/tracts';
import { InventoryCategory } from 'models/inventory';

export const fetchTracts = (): ThunkAction<Promise<TractsFetchAllSuccessAction | TractsFetchFailureAction>, GlobalState, null, TractsFetchAllSuccessAction | TractsFetchFailureAction> => {
    return async (dispatch, getState) => {
        const inventories = getInventoriesForSelectedOrg(getState(), InventoryCategory.Land);
        console.log('inventories to fetch tracts for:', inventories);

        try {
            await Promise.all(inventories.map((p) => dispatch(fetchTractsForInventory(p.id))));
            return dispatch(tractsFetchingAllSuccess());
        } catch (e) {
            return dispatch(tractsFetchingFailure('all', e as IRestError));
        }
    }
}

export const fetchRelatedTracts = (relatedTo: IRelatedTo[]): ThunkAction<Promise<TractRelatedAction>, GlobalState, null, TractRelatedAction> => {
    return async (dispatch, getState) => {
        dispatch(tractsFetchingRelatedPending(relatedTo));

        const orgId = getSelectedOrgShortId(getState());
        const toFetch = relatedTo.map((r) => getTractByID(orgId, r.parentId!, r.id));

        try {
            const tracts = await Promise.all(toFetch);
            return dispatch(tractsFetchingRelatedSuccess(tracts));
        } catch (e) {
            return dispatch(tractsFetchingRelatedFailure(e as IRestError));
        }
    }
}

export const fetchTractsForInventory = (inventoryId: string): ThunkAction<Promise<TractsFetchSuccessAction | TractsFetchSuccessCacheAction | TractsFetchFailureAction>, GlobalState, null, TractsFetchPendingAction | TractsFetchSuccessCacheAction | TractsFetchSuccessAction | TractsFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(tractsFetchingPending(inventoryId));

        try {
            const tracts = await getTracts(getSelectedOrgShortId(getState()), inventoryId);
            return dispatch(tractsFetchingSuccess(inventoryId, tracts));
        } catch (e) {
            return dispatch(tractsFetchingFailure(inventoryId, e as IRestError));
        }
    }
}

export function tractsFetchingAllSuccess(): TractsFetchAllSuccessAction {
    return {
        type: TractActionType.FETCHING_ALL,
    };
}

export function tractsFetchingPending(inventoryId: string): TractsFetchPendingAction {
    return {
        type: TractActionType.FETCHING_MULTIPLE_PENDING,
        inventoryId: inventoryId,
    };
}

export function tractsFetchingSuccess(inventoryId: string, tracts: ITract[]): TractsFetchSuccessAction {
    return {
        type: TractActionType.FETCHING_MULTIPLE_SUCCESS,
        inventoryId: inventoryId,
        tracts: tracts,
    }
}

export function tractsFetchingFailure(inventoryId: string, error?: IRestError): TractsFetchFailureAction {
    return {
        type: TractActionType.FETCHING_MULTIPLE_FAILURE,
        inventoryId: inventoryId,
        error,
    };
}

export function tractsFetchingRelatedPending(relatedTos: IRelatedTo[]): TractsFetchRelatedPendingAction {
    return {
        type: TractActionType.FETCHING_RELATED_PENDING,
        relatedTos,
    };
}

export function tractsFetchingRelatedSuccess(tracts: ITract[]): TractsFetchRelatedSuccessAction {
    return {
        type: TractActionType.FETCHING_RELATED_SUCCESS,
        tracts: tracts,
    }
}

export function tractsFetchingRelatedFailure(error?: IRestError): TractsFetchRelatedFailureAction {
    return {
        type: TractActionType.FETCHING_RELATED_FAILURE,
        error,
    };
}

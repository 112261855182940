import React from 'react';
import { Tag } from 'antd';

import { TractStatus } from 'models/tract';

interface ITractStatusTagProps {
    status?: TractStatus;
};

export const TractStatusTag: React.FC<ITractStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case TractStatus.Available:
            color = 'green';
            break;
        case TractStatus.InDefault:
        case TractStatus.Late:
            color = 'red';
            break;
        case TractStatus.Pending:
        case TractStatus.UnderContract:
            color = 'gold';
            break;
        case TractStatus.ResendContract:
            color = 'magenta';
            break;
        case TractStatus.Sold:
            color = 'cyan';
            break;
        case TractStatus.PaidOff:
            color = 'geekblue';
            break;
        default:
            color = 'purple';
            break;
    }

    let label = 'Unknown';
    switch(props.status) {
        case TractStatus.Available:
            label = 'Available';
            break;
        case TractStatus.InDefault:
            label = 'In Default';
            break;
        case TractStatus.Late:
            label = 'Late';
            break;
        case TractStatus.Pending:
            label = 'Pending';
            break;
        case TractStatus.UnderContract:
            label = 'Under Contract';
            break;
        case TractStatus.ResendContract:
            label = 'Resend Contract';
            break;
        case TractStatus.Sold:
            label = 'Sold';
            break;
        case TractStatus.PaidOff:
            label = 'Paid Off';
            break;
        default:
            label = 'Unknown';
            break;
    }

    return (
        <Tag color={color}>{label}</Tag>
    );
});

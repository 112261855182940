import { ThunkAction } from 'redux-thunk';

import { ILookup } from 'models/common/lookup';
import { IPagination } from 'models/common/pagination';
import { IRestError } from 'models/common/restResult';
import { ClientStatus, ClientType, IClient } from 'models/client';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { getClients, getClientByID } from 'api/clients';
import { IRestRequestFilter } from 'models/common/restRequest';

import {
    ClientActionType,
    ClientsFetchPendingAction,
    ClientsFetchSuccessAction,
    ClientsFetchFailureAction,
    ClientCreatedEventRecievedAction,
    ClientUpdatedEventRecievedAction,
    ClientDeletedEventRecievedAction,
    ClientFetchPendingAction,
    ClientFetchSuccessAction,
    ClientFetchFailureAction,
} from 'store/types/clients';

export const fetchClients = (page: number, pageSize: number, sortBy: string, displayNameFilter?: string, typeFilter?: ClientType[], statusFilter?: ClientStatus[], accountNumberFilter?: string): ThunkAction<Promise<ClientsFetchSuccessAction | ClientsFetchFailureAction>, GlobalState, null, ClientsFetchPendingAction | ClientsFetchSuccessAction | ClientsFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(clientsFetchingPending());

        try {
            let filters: IRestRequestFilter[] | undefined = undefined;
            if (Array.isArray(typeFilter)) {
                filters = [];

                typeFilter.forEach((v) => filters!.push({ key: 'type', value: v }));
            }

            if (Array.isArray(statusFilter)) {
                if (!Array.isArray(filters)) {
                    filters = [];
                }

                statusFilter.forEach((v) => filters!.push({ key: 'status', value: v }));
            }

            if (typeof accountNumberFilter === 'string' && accountNumberFilter.length !== 0) {
                if (!Array.isArray(filters)) {
                    filters = [];
                }

                filters.push({ key: 'accountNumber', value: accountNumberFilter });
            }

            const clientResults = await getClients(getSelectedOrgShortId(getState()), { limit: pageSize, offset: (page - 1) * pageSize, orderBy: sortBy, search: displayNameFilter, filters });
            return dispatch(clientsFetchingSuccess(clientResults.data, !!displayNameFilter, clientResults.pagination));
        } catch (e) {
            return dispatch(clientsFetchingFailure(e as IRestError));
        }
    }
}

export const fetchClientById = (clientId: string): ThunkAction<Promise<ClientFetchSuccessAction | ClientFetchFailureAction>, GlobalState, null, ClientFetchPendingAction | ClientFetchSuccessAction | ClientFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(clientFetchingPending());

        try {
            const orgId = getSelectedOrgShortId(getState());
            const client = await getClientByID(orgId, clientId);

            return dispatch(clientFetchingSuccess(client));
        } catch (e) {
            return dispatch(clientFetchingFailure(e as IRestError));
        }
    }
}

export function clientsFetchingPending(): ClientsFetchPendingAction {
    return {
        type: ClientActionType.FETCHING_MULTIPLE_PENDING,
    };
}

export function clientsFetchingSuccess(clients: IClient[], filtered: boolean, pagination?: IPagination): ClientsFetchSuccessAction {
    return {
        type: ClientActionType.FETCHING_MULTIPLE_SUCCESS,
        clients,
        pagination,
        filtered,
    }
}

export function clientsFetchingFailure(error?: IRestError): ClientsFetchFailureAction {
    return {
        type: ClientActionType.FETCHING_MULTIPLE_FAILURE,
        error,
    };
}

export function clientFetchingPending(): ClientFetchPendingAction {
    return {
        type: ClientActionType.FETCHING_ONE_PENDING,
    }
}

export function clientFetchingSuccess(client: IClient): ClientFetchSuccessAction {
    return {
        type: ClientActionType.FETCHING_ONE_SUCCESS,
        client,
    }
}

export function clientFetchingFailure(error?: IRestError): ClientFetchFailureAction {
    return {
        type: ClientActionType.FETCHING_ONE_FAILURE,
        error,
    };
}

export function clientCreatedEventRecieved(client: IClient): ClientCreatedEventRecievedAction {
    return {
        type: ClientActionType.EVENT_CREATED,
        client,
    };
}

export function clientUpdatedEventRecieved(client: IClient): ClientUpdatedEventRecievedAction {
    return {
        type: ClientActionType.EVENT_UPDATED,
        client,
    };
}

export function clientDeletedEventRecieved(lookup: Partial<ILookup>): ClientDeletedEventRecievedAction {
    return {
        type: ClientActionType.EVENT_DELETED,
        lookup,
    };
}

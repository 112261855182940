import { Currency } from '../currency';

export interface IInvoice {
    id: string,
    invoiceId: string, //Stripe's invoice id
    number: string,
    amount: Currency,
    amountRemaining: Currency,
    items: IInvoiceItem[],
    date: string,
    dueDate: string,
    status: InvoiceStatus,
    paid: boolean,
    pdfUrl: string,
    paymentIntent: IInvoicePaymentIntent,
}

export interface IInvoiceItem {
    id: string,
    date: string,

    description: string,
    amount: Currency,
    quantity: number,
}

export enum InvoiceStatus {
    Draft = 'draft',
    Open = 'open',
    Paid = 'paid',
    Void = 'void',
    Uncollectible = 'uncollectible',
}

export interface IInvoicePaymentIntent {
    id: string,
    status: InvoicePaymentIntentStatus,
}

export enum InvoicePaymentIntentStatus {
    Cancelled = 'canceled',
    Processing = 'processing',
    RequiresAction = 'requires_action',
    RequiresCapture = 'requires_capture',
    RequiresConfirmation = 'requires_confirmation',
    RequiresPaymentMethod = 'requires_payment_method',
    Succeeded = 'succeeded',
    Unknown = 'unknown',
}

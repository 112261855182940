import React from 'react';
import type { FC } from 'react';
import dayjs from 'dayjs';
import dayjsAdvanceFormat from 'dayjs/plugin/advancedFormat';
import currency from 'currency.js';
import { Badge, Tooltip } from 'antd';

import { OrganizationBillingStatus } from 'models/organization';
import { Currency } from 'models/currency';

dayjs.extend(dayjsAdvanceFormat);

interface OrgStatusBadgeProps {
    status: OrganizationBillingStatus;
    cancelsAtEndOfPeriod?: boolean;
}

export const OrgStatusBadge: FC<OrgStatusBadgeProps> = (props) => {
    if (props.cancelsAtEndOfPeriod) {
        return <Badge status="error" text="Cancelling" />
    }

    const status = props.status.replace('_', ' ');

    switch (props.status) {
        case OrganizationBillingStatus.Active:
            return <Badge status="success" text={status} />;
        case OrganizationBillingStatus.Incomplete:
        case OrganizationBillingStatus.Trialing:
            return <Badge status="processing" text={status} />;
        case OrganizationBillingStatus.Cancelled:
        case OrganizationBillingStatus.IncompleteExpired:
        case OrganizationBillingStatus.PastDue:
        case OrganizationBillingStatus.Unpaid:
            return <Badge status="error" text={status} />;
        default:
            return <Badge status="default" text="Unknown" />;
    }
}

export const formatDateForTooltip = (date?: string): string => {
    if (!date) {
        return 'No date value set';
    }

    const d = dayjs(date);

    if (!d.isValid() || d.year() === 0 || d.year() === 1) {
        return 'No date value set';
    }

    return d.format('MMM D, YYYY hh:mm:ss.SSS a');
}

export const SimpleDate: FC<{ date?: string, simplier?: boolean, longer?: boolean, tooltip?: boolean }> = React.memo((props) => {
    let simpler = false;
    if (props.simplier) {
        simpler = true;
    }

    if (!props.tooltip) {
        return (
            <span>{ props.longer ? formatDateForTooltip(props.date) : formatSimpleDate(props.date, simpler) }</span>
        );
    }

    const tooltipValue = formatDateForTooltip(props.date);

    return (
        <Tooltip title={tooltipValue}>
            <span>{ props.longer ? formatDateForTooltip(props.date) : formatSimpleDate(props.date, simpler) }</span>
        </Tooltip>
    );
});

export const formatSimpleDate = (date: string | undefined, simplier: boolean): string => {
    if (!date) {
        return '-';
    }

    const d = dayjs(date);

    if (!d.isValid() || d.year() === 0 || d.year() === 1) {
        return '-';
    }

    return d.format(simplier ? 'M/D/YY' : 'MM/DD/YYYY');
}

export const ShortDate: FC<{ value: string, tooltip?: boolean }> = (props) => {
    const d = dayjs(props.value);

    if (!d.isValid() || d.year() === 0 || d.year() === 1) {
        return (
            <span>-</span>
        );
    }

    if (!props.tooltip) {
        return (
            <span>{ d.format('MMM Do, \'YY') }</span>
        );
    }

    const tooltipValue = formatDateForTooltip(props.value);

    return (
        <Tooltip title={tooltipValue}>
            <span>{ d.format('MMM Do, \'YY') }</span>
        </Tooltip>
    );
}

export const LongCurrency: React.FC<{ value?: Currency, tooltip?: boolean, precision?: number }> = React.memo((props) => {
    if (!props.value) {
        return (
            <React.Fragment>-</React.Fragment>
        );
    }

    const c = currency(props.value, { precision: typeof props.precision === 'number' ? props.precision : 2 }).format(true);

    if (!props.tooltip) {
        return (
            <React.Fragment>
                { c }
            </React.Fragment>
        );
    }

    const tooltipValue = currency(props.value, { precision: 14 }).format(true);

    return (
        <Tooltip title={tooltipValue}>
            { c }
        </Tooltip>
    );
});

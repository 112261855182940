import { GlobalPermissionState, PermissionActionType, PermissionAction } from 'store/types/permissions';

const initialPermissionState: GlobalPermissionState = {
    isLoading: false,
    hasError: false,
};

export function permissionReducer(state = initialPermissionState, action: PermissionAction): GlobalPermissionState {
    switch (action.type) {
        case PermissionActionType.PURGE:
            return initialPermissionState;
        case PermissionActionType.LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case PermissionActionType.LOADING_ORG:
            const cached = {
                ...state.cachedEnforcements,
            };
            delete cached[action.orgLongId];

            return {
                ...state,
                isLoading: true,
                cachedEnforcements: cached,
            };
        case PermissionActionType.LOADING_FAILURE:
        case PermissionActionType.LOADING_ORG_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.error,
            };
        case PermissionActionType.LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                error: undefined,
                enforcer: action.enforcer,
                myPermissions: action.permissions,
            };
        case PermissionActionType.LOADING_ORG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                error: undefined,
                cachedEnforcements: {
                    ...state.cachedEnforcements,
                    [action.orgLongId]: action.cachedEnforcements,
                },
            };
        default:
            return state;
    }
}

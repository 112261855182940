import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';

import { setInventoryName } from 'api/inventories';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

interface IFormValues {
    name: string;
}

interface IInventoryRenameModalProps {
    visible: boolean;
    orgId: string;
    inventoryId: string;
    initialName: string;
    close: () => void;
}

export const InventoryRenameModal: React.FC<IInventoryRenameModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const onOkayClick = () => {
        setSaving(true);

        form
            .validateFields()
            .then(async (values) => {
                try {
                    await setInventoryName(props.orgId, props.inventoryId, values.name);
                    trackUmami('Rename Inventory');

                    props.close();
                    form.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    setSaving(false);
                }
            })
            .catch((e) => {
                console.warn('failed to validate the relabel loan form:', e);
            });
    };

    return (
        <Modal
            open={props.visible}
            title={`Rename the Inventory: ${ props.initialName }`}
            okText="Rename"
            closable={!isSaving}
            maskClosable={!isSaving}
            okButtonProps={{ disabled: isSaving, loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
            onOk={onOkayClick}
            onCancel={props.close}
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                initialValues={{ name: props.initialName }}
            >
                <Form.Item name="name" rules={[{ required: true, message: 'A name is required for the rename to be effective.' }]} style={{ marginBottom: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

import { get, post, put } from './index';

import { INotification, NotificationType } from './../models/notification';

export const getNotifications = (since?: Date) => {
    let url = '/notifications';

    if (since) {
        url = url + '?since=' + since.toJSON();
    }

    return get<INotification[]>(url, true);
};

export const markNotificationAsRead = (id: string) => post<{}, INotification>(`/notifications/${ id }/read`, {}, true);
export const clearNotificationOfType = (type: NotificationType) => put<{ type: NotificationType }, void>('/notifications', { type }, true);

import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { OrganizationBillingStatus } from 'models/organization';

export function wrapUnableToCreateTooltip(what: React.ReactNode, status: OrganizationBillingStatus): React.ReactNode {
    const s = status.replace('_', ' ');

    return (
        <Tooltip overlay={`Your organization status is ${ s }; creation is disabled.`}>
            {what}
        </Tooltip>
    );
}

export const SimpleTooltip: React.FunctionComponent<{ title: React.ReactNode, content?: React.ReactNode, hasQuestion?: boolean }> = (props) => {
    return (
        <Tooltip title={props.title}>
            { props.content ? props.content : null }&nbsp;
            { props.hasQuestion ? <QuestionCircleOutlined /> : null }
        </Tooltip>
    );
}

import React from 'react';
import { Layout, Row, Col, Form, FormInstance, Divider, Switch, Input, Button } from 'antd';
import { ReactStripeElements, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Rule } from 'antd/lib/form';

import { IUser } from 'models/user';
import { IOrganization } from 'models/organization';

interface IOrgCreationSecondStepProps {
    form: FormInstance;
    isSaving: boolean;
    user: IUser;
    organization?: IOrganization;
    onNext: () => void;
    onPrevious: () => void;
}

interface IOrgCreationSecondStepState {
    card: {
        number: {
            valid: boolean,
            touched: boolean,
        },
        exp: {
            valid: boolean,
            touched: boolean,
        },
        cvc: {
            valid: boolean,
            touched: boolean,
        },
    };
}

export class OrgCreationSecondStep extends React.PureComponent<IOrgCreationSecondStepProps, IOrgCreationSecondStepState> {
    state: Readonly<IOrgCreationSecondStepState> = {
        card: {
            number: {
                valid: false,
                touched: false,
            },
            exp: {
                valid: false,
                touched: false,
            },
            cvc: {
                valid: false,
                touched: false,
            },
        },
    }

    componentDidMount() {
        if (!this.props.organization && this.props.form.getFieldValue('sameAsMailing')) {
            this.setBillingSameAsMailing();
        }
    }

    onNextClick = () => {
        if (this.checkCreditCardInputs()) {
            this.props.onNext();
        }
    }

    checkCreditCardInputs = (): boolean => {
        const { card } = this.state;

        let goNext = true;
        if (!this.state.card.number.valid) {
            card.number.touched = true;
            goNext = false;
        }

        if (!this.state.card.exp.valid) {
            card.exp.touched = true;
            goNext = false;
        }

        if (!this.state.card.cvc.valid) {
            card.cvc.touched = true;
            goNext = false;
        }

        this.setState({ card });
        return goNext;
    }

    //#region billing same as mailing
    onSameAsMailingChange = (checked: boolean) => {
        if (!checked) {
            return;
        }

        this.setBillingSameAsMailing();
    }

    setBillingSameAsMailing = () => {
        this.props.form.setFieldsValue({
            billingLine1: this.props.form.getFieldValue('mailingLine1'),
            billingLine2: this.props.form.getFieldValue('mailingLine2'),
            billingCity: this.props.form.getFieldValue('mailingCity'),
            billingState: this.props.form.getFieldValue('mailingState'),
            billingZip: this.props.form.getFieldValue('mailingZip'),
        });
    }
    //#endregion

    //#region billing inputs
    get billingSameAsMailingSwitch() {
        return (
            <Form.Item>
                <Divider type="horizontal" orientation="left">
                    <Form.Item name="sameAsMailing" valuePropName="checked" preserve noStyle>
                        <Switch checkedChildren="Same as mailing" unCheckedChildren="Same as mailing" onChange={this.onSameAsMailingChange} />
                    </Form.Item>
                </Divider>
            </Form.Item>
        );
    }

    get billingStreetOneInput() {
        const { getFieldValue } = this.props.form;

        return (
            <Form.Item name="billingLine1" rules={[{ required: true, message: 'The billing address street is required.' }]} preserve>
                <Input
                    size="large"
                    placeholder="Billing street one"
                    autoComplete="billing address-line1"
                    disabled={this.props.isSaving || getFieldValue('sameAsMailing') as boolean}
                />
            </Form.Item>
        );
    }

    get billingStreetTwoInput() {
        const { getFieldValue } = this.props.form;

        return (
            <Form.Item name="billingLine2" preserve>
                <Input
                    size="large"
                    placeholder="Billing street two"
                    autoComplete="billing address-line2"
                    disabled={this.props.isSaving || getFieldValue('sameAsMailing') as boolean}
                />
            </Form.Item>
        );
    }

    get billingCityInput() {
        const { getFieldValue } = this.props.form;

        return (
            <Form.Item name="billingCity" rules={[{ required: true, message: 'The billing address city is required.' }]} preserve>
                <Input
                    size="large"
                    placeholder="Billing city"
                    autoComplete="billing address-level2"
                    disabled={this.props.isSaving || getFieldValue('sameAsMailing') as boolean}
                />
            </Form.Item>
        );
    }

    get billingStateAndZipInput() {
        const { getFieldValue } = this.props.form;
        const sameAs = getFieldValue('sameAsMailing') as boolean;

        const zipRules: Rule[] = [
            { required: true, message: 'The billing zip code is required.' },
            { pattern: /^\d{5}$/, message: 'Invalid number format, include numbers only.' },
        ];

        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="billingState" rules={[{ required: true, message: 'The billing address state is requried.' }]} preserve>
                        <Input
                            size="large"
                            placeholder="Billing state"
                            autoComplete="billing address-level1"
                            disabled={this.props.isSaving || sameAs}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="billingZip" rules={zipRules} preserve>
                        <Input
                            min={0}
                            minLength={5}
                            maxLength={5}
                            size="large"
                            style={{ width: '100%' }}
                            placeholder="Billing zip"
                            autoComplete="billing zip"
                            disabled={this.props.isSaving || sameAs}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
    //#endregion

    //#region card inputs
    get creditCardNameInput() {
        return (
            <Form.Item name="creditCardName" rules={[{ required: true, message: 'The full name of the person on the card is required.' }]} style={{ marginTop: '21px' }}>
                <Input
                    size="large"
                    placeholder="Full Name on Card"
                    autoComplete="cc-name"
                    disabled={this.props.isSaving}
                />
            </Form.Item>
        );
    }

    stripeChange = (res: ReactStripeElements.ElementChangeResponse) => {
        const { card } = this.state;
        switch (res.elementType) {
            case 'cardNumber':
                card.number.valid = !res.empty && res.complete;
                break;
            case 'cardExpiry':
                card.exp.valid = !res.empty && res.complete;
                break;
            case 'cardCvc':
                card.cvc.valid = !res.empty && res.complete;
                break;
            default:
                return;
        }

        this.setState({ card }, () => this.props.form.validateFields(['creditCardName']));
    }

    stripeFocus = (res: ReactStripeElements.ElementChangeResponse) => {
        const { card } = this.state;
        switch (res.elementType) {
            case 'cardNumber':
                card.number.touched = true;
                break;
            case 'cardExpiry':
                card.exp.touched = true;
                break;
            case 'cardCvc':
                card.cvc.touched = true;
                break;
            default:
                return;
        }

        this.setState({ card });
    }

    get creditCardNumberInput() {
        const help = 'A valid credit card number is required.';
        const { number } = this.state.card;

        return (
            <Form.Item className="stripe-item" validateStatus={number.touched && !number.valid ? 'error' : ''} help={number.touched && !number.valid ? help : ''}>
                <CardNumberElement
                    className={`ant-input ant-input-lg card-input ${ this.props.isSaving ? 'disabled' : '' }`}
                    onChange={this.stripeChange}
                    onFocus={this.stripeFocus}
                    disabled={this.props.isSaving}
                    style={{
                        base: {
                            fontSize: '16px',
                            color: 'rgba(0, 0, 0, 0.65)',
                            letterSpacing: '0.025em',
                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                            '::placeholder': {
                                color: '#bfbfbf',
                            },
                        },
                    }}
                />
            </Form.Item>
        );
    }

    get creditCardExpInputs() {
        const expHelp = 'A valid expiration date is required.';
        const cvcHelp = 'A valid CVC number is required.';
        const { exp, cvc } = this.state.card;

        return (
            <Row>
                <Col span={6}>
                    <Form.Item className="stripe-item" validateStatus={exp.touched && !exp.valid ? 'error' : ''} help={exp.touched && !exp.valid ? expHelp : ''}>
                        <CardExpiryElement
                            className={`ant-input ant-input-lg card-input ${ this.props.isSaving ? 'disabled' : '' }`}
                            onChange={this.stripeChange}
                            onFocus={this.stripeFocus}
                            disabled={this.props.isSaving}
                            style={{
                                base: {
                                    fontSize: '16px',
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    letterSpacing: '0.025em',
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                                    '::placeholder': {
                                        color: '#bfbfbf',
                                    },
                                },
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6} push={1}>
                    <Form.Item className="stripe-item" validateStatus={cvc.touched && !cvc.valid ? 'error' : ''} help={cvc.touched && !cvc.valid ? cvcHelp : ''}>
                        <CardCVCElement
                            className={`ant-input ant-input-lg card-input ${ this.props.isSaving ? 'disabled' : '' }`}
                            onChange={this.stripeChange}
                            onFocus={this.stripeFocus}
                            disabled={this.props.isSaving}
                            style={{
                                base: {
                                    fontSize: '16px',
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    letterSpacing: '0.025em',
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                                    '::placeholder': {
                                        color: '#bfbfbf',
                                    },
                                },
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
    //#endregion

    //#region buttons
    get buttons() {
        return (
            <Form.Item>
                <Row gutter={16}>
                    <Col span={4}>
                        <Button
                            key="nextBtn"
                            type="primary"
                            size="large"
                            disabled={this.props.isSaving}
                            loading={this.props.isSaving}
                            onClick={this.onNextClick}>Next</Button>
                    </Col>
                    <Col span={4}>
                        <Button key="previous" size="large" onClick={this.props.onPrevious} disabled={this.props.isSaving}>Previous</Button>
                    </Col>
                </Row>
            </Form.Item>
        );
    }
    //#endregion

    render() {
        return (
            <Layout>
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sameAsMailing !== curr.sameAsMailing}>
                    {() => (
                        <React.Fragment>
                            {this.billingSameAsMailingSwitch}
                            {this.billingStreetOneInput}
                            {this.billingStreetTwoInput}
                            {this.billingCityInput}
                            {this.billingStateAndZipInput}
                        </React.Fragment>
                    )}
                </Form.Item>

                <Divider type="horizontal" orientation="left" dashed>Card Information</Divider>
                {this.creditCardNameInput}
                {this.creditCardNumberInput}
                {this.creditCardExpInputs}

                {this.buttons}
            </Layout>
        );
    }
}

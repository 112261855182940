import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { IRelatedTo } from './common/relatedTo';

export interface ITimelineEntry extends IObjectDates, IOrganizational {
    id: string;
    content: string;
    date: Date;
    color: string;
    icon: TimelineEntryIcons;
    relatedToItems?: IRelatedTo[];
}

export enum TimelineEntryIcons {
    QuestionMark = "question-circle",
    ExclamationMark = "exclamation",
    ExclamationMarkCircle = "exclamation-circle",
    Info = "info",
    InfoCircle = "info-circle",
    PauseCircle = "pause-circle",
    Minus = "minus",
    MinusCircle = "minus-circle",
    Plus = "plus",
    PlusCircle = "plus-circle",
    CloseCircle = "close-circle",
    CheckMarkCircle = "check-circle",
    IssueClose = "issues-close",
    Clock = "clock-circle",
    Message = "message",
}

import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BellOutlined } from '@ant-design/icons';
import { Dropdown, Badge, Tabs, theme } from 'antd';

import { NotificationType } from 'models/notification';
import type { INotification } from 'models/notification';

import { fetchNotifications, markNotificationRead, clearNotificationType } from 'store/actions/notifications';
import { getReminderNotifications, getNotifications, getMessageNotifications, getUnreadCount } from 'store/selectors/notifications';

import { NotificationListTab } from './notificationList';

const NotificationTabLabel: FC<{ label: string, items?: INotification[] }> = (props) => {
    if (!props.items || props.items.length === 0) {
        return (
            <React.Fragment>
                { props.label }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            { props.label } ({ props.items.length })
        </React.Fragment>
    );
}

export const NotificationsView: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const unreadCount = useSelector(getUnreadCount);
    const notifications = useSelector(getNotifications);
    const reminders = useSelector(getReminderNotifications);
    const messages = useSelector(getMessageNotifications);
    const { token } = theme.useToken();

    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        padding: '0 16px',
    };

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    const itemClick = useCallback((item: INotification) => {
        if (item.read) {
            return;
        }

        dispatch(markNotificationRead(item.id));
    }, [dispatch]);

    const clearTab = useCallback((which: NotificationType) => {
        dispatch(clearNotificationType(which));
    }, [dispatch]);

    return (
        <Dropdown
            placement="bottomRight"
            trigger={['click']}
            open={open}
            onOpenChange={setOpen}
            dropdownRender={() => (
                <div id="lendiom-notifications-view" style={contentStyle}>
                    <Tabs
                        className="notifications-tabs"
                        defaultActiveKey={NotificationType.NOTIFICATIONS}
                        items={[
                            {
                                key: NotificationType.NOTIFICATIONS,
                                label: <NotificationTabLabel label='Notifications' items={notifications} />,
                                children: (
                                    <NotificationListTab
                                        title="Notifications"
                                        list={notifications}
                                        showClear
                                        onClear={() => clearTab(NotificationType.NOTIFICATIONS)}
                                        onClick={itemClick}
                                    />
                                )
                            },
                            {
                                key: NotificationType.REMINDERS,
                                label: <NotificationTabLabel label='Reminders' items={reminders} />,
                                children: (
                                    <NotificationListTab
                                        title="Reminders"
                                        list={reminders}
                                        showClear
                                        onClear={() => clearTab(NotificationType.REMINDERS)}
                                        onClick={itemClick}
                                    />
                                ),
                            },
                            {
                                key: NotificationType.MESSAGES,
                                label: <NotificationTabLabel label='Messages' items={messages} />,
                                children: (
                                    <NotificationListTab
                                        title="Messages"
                                        list={messages}
                                        showClear
                                        onClear={() => clearTab(NotificationType.MESSAGES)}
                                        onClick={itemClick}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            )}
        >
            <Badge count={unreadCount} style={{ margin: '8px' }}>
                <BellOutlined style={{ fontSize: '18px', margin: '8px' }} />
            </Badge>
        </Dropdown>
    );
}

import React from 'react';
import type { FC } from 'react';
import { Layout, Spin } from 'antd';

export const Loading: FC = React.memo(() => (
    <Layout className="loading" style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: '25px' }}>
        <Spin size="large" />
    </Layout>
));

export const FullScreenLoading: FC = React.memo(() => (
    <Layout className="fullscreen-loading">
        <Spin size="large" />
    </Layout>
));

import { GlobalState } from 'store';
import { IClient } from 'models/client';

export const isLoadingClients = (state: GlobalState) => state.clients.isFetching;
export const hasLoadingError = (state: GlobalState) => state.clients.hasError;
export const loadingError = (state: GlobalState) => state.clients.error;
export const getClientsPagination = (state: GlobalState) => state.clients.pagination;
export const getClientsForSelectedOrg = (state: GlobalState): IClient[] => Object.values(state.clients.clientsById).filter((c) => c.organization.id === state.org.selectedOrgLongId);
export const getClientById = (state: GlobalState, orgId: string, clientId: string): IClient | undefined => {
    const c = state.clients.clientsById[clientId];
    if (!c) {
        return c;
    }

    if (c.organization.shortId !== orgId) {
        return undefined;
    }

    return c;
};

import React, { useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';

import { store } from 'store';
import { authLogin } from 'store/actions/auth';
import { UnauthorizedError } from 'models/common/restResult';
import { displayErrorNotification } from 'utils/errors';

interface IAuthFormValues {
    email: string;
    password: string;
}

interface IAuthorizationModalContentProps {
    close: () => void;
}

const AuthorizationModalContent: React.FC<IAuthorizationModalContentProps> = (props: IAuthorizationModalContentProps) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<IAuthFormValues>();
    const dispatch = useDispatch();

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            const values = await form.validateFields();

            const result = await dispatch(authLogin(values) as any);
            if (result.error) {
                setSubmitting(false);

                if (result.error.code === 88) {
                    notification.error({ message: 'Invalid password.' });
                    return;
                } else if (result.error.code === 1) {
                    notification.error({ message: 'Unknown email provided, no account found.' });
                    return;
                }

                notification.error({ message: 'Internal error while logging in.' });
                return;
            }

            setTimeout(() => {
                setSubmitting(false);
                props.close();
            }, 550);
        } catch (e) {
            displayErrorNotification(e);
        }
    };

    return (
        <Form<IAuthFormValues> form={form} onFinish={onSubmit} layout="vertical" name="authRequired">
            <Typography.Paragraph>You appear to be logged out, please sign back in to continue using Lendiom.</Typography.Paragraph>

            <Form.Item name="email" label="Email">
                <Input placeholder="me@mydomain.com" disabled={isSubmitting} />
            </Form.Item>

            <Form.Item name="password" label="Password">
                <Input.Password disabled={isSubmitting} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>Submit</Button>
            </Form.Item>
        </Form>
    );
}

export function displayUnauthorizedModal(e: UnauthorizedError, refresh?: () => void) {
    const close = () => {
        modal.destroy();

        if (typeof refresh === 'function') {
            refresh();
        }
    }

    const modal = Modal.error({
        title: 'Authorization Required',
        content: (
            <Provider store={store}>
                <AuthorizationModalContent close={close} />
            </Provider>
        ),
        keyboard: false,
        className: 'authorization-required-modal',
    });
}

import React from 'react';
import { Modal, Form, Input, FormInstance, InputNumber } from 'antd';

import { displayErrorNotification } from 'utils/errors';
import { setTractLabelAndNumber } from 'api/tracts';

interface IFormValues {
    label: string;
    number: number;
}

interface ITractRenameModalProps {
    visible: boolean;
    orgId: string;
    inventoryId: string;
    tractId: string;
    initialLabel: string;
    initialNumber: number;
    close: (saved: boolean) => void;
}

interface ITractRenameModalState {
    isSaving: boolean;
}

export class TractRenameModal extends React.PureComponent<ITractRenameModalProps, ITractRenameModalState> {
    state: Readonly<ITractRenameModalState> = {
        isSaving: false,
    };

    formRef = React.createRef<FormInstance<IFormValues>>();

    close = () => {
        if (typeof this.props.close === 'function') {
            this.props.close(false);
        }
    }

    onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!this.formRef.current) {
            return;
        }

        this.formRef.current.validateFields().then((values) => {
            this.setState({ isSaving: true }, async () => {
                try {
                    await setTractLabelAndNumber(this.props.orgId, this.props.inventoryId, this.props.tractId, values.label, values.number);
                    this.props.close(true);
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        });
    }

    render() {
        return (
            <Modal
                open={this.props.visible}
                title={`Rename the Tract: ${ this.props.initialLabel }`}
                okText="Rename"
                closable={!this.state.isSaving}
                okButtonProps={{ disabled: this.state.isSaving, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
                onOk={this.onSubmit}
                onCancel={this.close}
                keyboard={false}
                maskClosable={false}
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    layout="vertical"
                    initialValues={{
                        label: this.props.initialLabel,
                        number: this.props.initialNumber,
                    }}
                >
                    <Form.Item name="number" label="Number" rules={[{ required: true, message: 'The tract number is required; we use it for displaying and sorting.' }]}>
                        <InputNumber min={1} step={1} disabled={this.state.isSaving} />
                    </Form.Item>

                    <Form.Item name="label" label="Label" style={{ marginBottom: 0 }} rules={[{ required: true, message: 'A label is required for the rename to be effective.' }]}>
                        <Input disabled={this.state.isSaving} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

import React from 'react';
import { DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Row, Col, Form, FormInstance, Button, Input, Switch, Descriptions, Divider } from 'antd';

import { PaymentMethodCard } from 'components/billing/paymentMethodCard';
import { CancelSubscriptionModal } from 'components/billing/cancelSubscriptionModal';
import { ReactivateSubscriptionModal } from 'components/billing/reactivateSubscriptionModal';
import { BillingAddonsCard } from 'components/billing/billingAddonsCard';
import { displayErrorNotification } from 'utils/errors';
import { OrgStatusBadge, SimpleDate } from 'utils/formatting';

import { IOrganization, OrganizationBillingStatus } from 'models/organization';
import { IOrganizationUpdateBillingDetailsPayload } from 'models/payloads/organization';

import { updateBillingOrganizationInfo } from 'api/organizations';
import { orgRefresh } from 'store/actions/org';
import { authRefreshUser } from 'store/actions/auth';

// import 'components/settings/basic.less';

interface IFormValues {
    areSame: boolean;
    billingLine1: string;
    billingLine2: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
}

interface IBillingProps extends DispatchProp, RouteComponentProps {
    org: IOrganization;
}

interface IBillingState {
    isSaving: boolean;
    isCancelVisible: boolean;
    isReactivateVisible: boolean;
}

class BillingViewBase extends React.PureComponent<IBillingProps, IBillingState> {
    state: Readonly<IBillingState> = {
        isSaving: false,
        isCancelVisible: false,
        isReactivateVisible: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search.replace('?', ''));
        if (!params.get('open-cancel') || this.props.org.billing.cancelsAtEndOfPeriod) {
            return;
        }

        this.setState({ isCancelVisible: true });
    }

    //#region billing status
    toggleCancelModal = () => {
        this.setState({ isCancelVisible: !this.state.isCancelVisible });
    }

    toggleReactivateModal = () => {
        this.setState({ isReactivateVisible: !this.state.isReactivateVisible });
    }

    get actionDescription() {
        const { billing } = this.props.org;

        if (billing.status === OrganizationBillingStatus.Cancelled) {
            //TODO: figure out how they can subscribe again
            const mailToPortion = 'mailto:support@lendiom.com?subject=' + encodeURIComponent(`Restart Subscription (${ this.props.org.shortId })`);

            return (
                <Descriptions.Item label="Action">
                    <Button type="primary" size="small" href={mailToPortion} target="_blank">Contact Support</Button>
                </Descriptions.Item>
            );
        }

        if (billing.cancelsAtEndOfPeriod) {
            return (
                <Descriptions.Item label="Action">
                    <Button type="primary" size="small" onClick={this.toggleReactivateModal}>Reactivate</Button>
                </Descriptions.Item>
            );
        }

        return (
            <Descriptions.Item label="Action">
                <Button type="dashed" size="small" onClick={this.toggleCancelModal}>Cancel</Button>
            </Descriptions.Item>
        );
    }

    get billingDescriptions() {
        const { billing } = this.props.org;

        return (
            <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Status" className="title-caps"><OrgStatusBadge status={billing.status} cancelsAtEndOfPeriod={billing.cancelsAtEndOfPeriod} /></Descriptions.Item>
                <Descriptions.Item label="Plan" className="title-caps">{ billing.plan ? billing.plan : '-' }</Descriptions.Item>
                <Descriptions.Item label="Period Start"><SimpleDate date={billing.periodStartsAt} /></Descriptions.Item>
                { !billing.cancelsAtEndOfPeriod && billing.status === OrganizationBillingStatus.Active ? <Descriptions.Item label="Period Renews"><SimpleDate date={billing.periodEndsAt} /></Descriptions.Item> : null }
                { !billing.cancelsAtEndOfPeriod && billing.status === OrganizationBillingStatus.Trialing ? <Descriptions.Item label="Trial Ends At"><SimpleDate date={billing.periodEndsAt} /></Descriptions.Item> : null}
                { billing.cancelsAtEndOfPeriod ? <Descriptions.Item label="Cancels At"><SimpleDate date={billing.cancelsAt} /></Descriptions.Item> : null }
                { billing.status === OrganizationBillingStatus.Cancelled ? <Descriptions.Item label="Canceled At"><SimpleDate date={billing.canceledAt} /></Descriptions.Item> : null}
                { this.actionDescription }
            </Descriptions>
        );
    }
    //#endregion billing status

    //#region billing address
    handleSubmit = () => {
        if (!this.formRef.current) {
            return;
        }

        this.formRef.current.validateFields().then((values) => {
            const payload: IOrganizationUpdateBillingDetailsPayload = {
                areSame: values.areSame,
                billing: {
                    label: 'Billing',
                    streetAddresses: [values.billingLine1],
                    city: values.billingCity,
                    state: values.billingState,
                    zipCode: values.billingZip,
                },
            };

            if (values.billingLine2) {
                payload.billing.streetAddresses.push(values.billingLine2);
            }

            this.setState({ isSaving: true }, async () => {
                const orgId = this.props.org.id;

                try {
                    await updateBillingOrganizationInfo(orgId, payload);
                    await this.props.dispatch(orgRefresh(orgId) as any);
                    await this.props.dispatch(authRefreshUser() as any);
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        })
    }

    get isSameAsShipping() {
        //TODO: when this is checked to on, ensure the values are set to what shipping is
        return (
            <Form.Item name="areSame" valuePropName="checked">
                <Switch checkedChildren="Same as Shipping" unCheckedChildren="Different than Shipping" />
            </Form.Item>
        );
    }

    get billingAddress() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.areSame !== curr.areSame}>
                {({ getFieldValue }) => {
                    const areSame = getFieldValue('areSame') as boolean;

                    return (
                        <div className="address-container">
                            <Form.Item name="billingLine1" label="Billing Address" rules={[{ required: true, message: 'The billing address street is required.' }]}>
                                <Input
                                    placeholder="Street"
                                    autoComplete="billing address-line1"
                                    disabled={this.state.isSaving || areSame}
                                />
                            </Form.Item>

                            <Form.Item name="billingLine2">
                                <Input
                                    placeholder="Street two"
                                    autoComplete="billing address-line2"
                                    disabled={this.state.isSaving || areSame}
                                />
                            </Form.Item>

                            <Form.Item name="billingCity" rules={[{ required: true, message: 'The billing address city is required.' }]}>
                                <Input
                                    placeholder="City"
                                    autoComplete="billing address-level2"
                                    disabled={this.state.isSaving || areSame}
                                />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="billingState" rules={[{ required: true, message: 'The billing address state is required.' }]}>
                                        <Input
                                            placeholder="State"
                                            autoComplete="billing address-level1"
                                            disabled={this.state.isSaving || areSame}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="billingZip" rules={[{ required: true, message: 'The billing zip code is required.' }, { pattern: /^\d{5}$/, message: 'Invalid number format, include numbers only.' }]}>
                                        <Input
                                            min={0}
                                            minLength={5}
                                            maxLength={5}
                                            style={{ width: '100%' }}
                                            placeholder="Zip"
                                            autoComplete="billing zip"
                                            disabled={this.state.isSaving || areSame}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Form.Item>
        );
    }

    get saveButton() {
        return (
            <Form.Item>
                <Button type="primary" htmlType="submit" className="submit" disabled={this.state.isSaving} loading={this.state.isSaving}>Save</Button>
            </Form.Item>
        );
    }
    //#endregion billing address

    render() {
        const { billing } = this.props.org.addresses;

        return (
            <React.Fragment>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        {this.billingDescriptions}

                        <Divider type="horizontal" children="Billing Address" />

                        <Form<IFormValues>
                            ref={this.formRef}
                            layout="vertical"
                            requiredMark={false}
                            onFinish={this.handleSubmit}
                            initialValues={{
                                areSame: this.props.org.addresses.areSame,
                                billingLine1: billing.streetAddresses.length >= 1 ? billing.streetAddresses[0] : '',
                                billingLine2: billing.streetAddresses.length >= 2 ? billing.streetAddresses[1] : '',
                                billingCity: billing.city,
                                billingState: billing.state,
                                billingZip: billing.zipCode,
                            }}
                        >
                            {this.isSameAsShipping}
                            {this.billingAddress}
                            {this.saveButton}
                        </Form>
                    </Col>
                    <Col xs={24} md={12}>
                        <PaymentMethodCard for={this.props.org} />
                        <BillingAddonsCard org={this.props.org} />
                    </Col>
                </Row>

                <CancelSubscriptionModal
                    visible={this.state.isCancelVisible}
                    close={this.toggleCancelModal}
                    orgId={this.props.org.id}
                    billingInfo={this.props.org.billing}
                />

                <ReactivateSubscriptionModal
                    visible={this.state.isReactivateVisible}
                    close={this.toggleReactivateModal}
                    orgId={this.props.org.id}
                    billingInfo={this.props.org.billing}
                />
            </React.Fragment>
        );
    }
}

export const BillingView = withRouter(BillingViewBase);

import { GlobalState } from '../../store';

export const getUserId = (state: GlobalState) => state.auth.userId;
export const getUser = (state: GlobalState) => state.auth.user;
export const getToken = (state: GlobalState) => state.auth.token;
export const hasValidToken = (state: GlobalState) => new Date().getTime() < new Date(state.auth.tokenExpiresAt).getTime();
export const isLoggingIn = (state: GlobalState) => state.auth.isLoggingIn;
export const hasError = (state: GlobalState) => state.auth.hasError;
export const getError = (state: GlobalState) => state.auth.error;

export const getAuthState = (state: GlobalState) => state.auth;

import React, { useState } from 'react';
import { Modal, Typography } from 'antd';

import { IOrganizationBilling } from 'models/organization';

import { ConfirmCancelSubscriptionModal } from './confirmCancelSubscriptionModal';

interface ICancelSubscriptionModalProps {
    visible: boolean;
    close: () => void;
    orgId: string;
    billingInfo: IOrganizationBilling;
}

export const CancelSubscriptionModal: React.FC<ICancelSubscriptionModalProps> = (props) => {
    const [confirmVisible, setConfirmVisible] = useState(false);

    const onCloseClick = () => props.close();
    const confirmClick = () => setConfirmVisible(true);
    const closeConfirm = (cancelled: boolean) => {
        if (cancelled) {
            props.close();
        }

        setConfirmVisible(false);
    };

    const periodEndDate = new Date(props.billingInfo.periodEndsAt);
    const mailTo = `mailto:support@lendiom.com?subject=Subscription%20Cancel%20Questions%20(${ encodeURIComponent(props.billingInfo.subscriptionId) })`;

    return (
        <React.Fragment>
            <Modal
                open={props.visible}
                title="Lendiom Subscription Cancel"
                okButtonProps={{ danger: true }}
                okText="Confirm"
                cancelText="Opps!"
                onCancel={onCloseClick}
                onOk={confirmClick}
                cancelButtonProps={{ type: 'primary' }}
            >
                <Typography.Paragraph>Canceling your subscription to Lendiom will result in the following:</Typography.Paragraph>
                <Typography.Paragraph>
                    <ul>
                        <li>Inability to create, update or delete information</li>
                        <li>Buyers no long able to access Lendiom Pay</li>
                        <li>Online payments disabled</li>
                        <li>Payment reminders unscheduled</li>
                        <li>Account discounts removed</li>
                        <li>Inability to download files</li>
                        <li>Inability to run reports</li>
                    </ul>
                </Typography.Paragraph>
                <Typography.Paragraph>By clicking <Typography.Text strong>Cancel</Typography.Text>, your subscription will end on { periodEndDate.toLocaleDateString() } at { periodEndDate.toLocaleTimeString() }. If you have any questions, please send us an email at: <a href={ mailTo } target="_blank" rel="noopener noreferrer">support@lendiom.com</a></Typography.Paragraph>
            </Modal>

            <ConfirmCancelSubscriptionModal visible={confirmVisible} close={closeConfirm} orgId={props.orgId} />
        </React.Fragment>
    );
}

import React from 'react';
import { BarcodeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Card, Table, Empty, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IClient } from 'models/client';
import { IMail } from 'models/mail';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { SimpleDate } from 'utils/formatting';

import { ViewMailDetailsModal } from './viewMailDetailsModal';

interface IMailCardProps {
    client: IClient;
    mail: IMail[];
    simpleList?: boolean;
}

interface IMailCardState {
    isDetailsVisible: boolean;
    selectedMail?: IMail;
}

export class MailCard extends React.PureComponent<IMailCardProps, IMailCardState> {
    state: Readonly<IMailCardState> = {
        isDetailsVisible: false,
    };

    columns: ColumnProps<IMail>[] = [
        {
            title: 'Description', key: 'description', dataIndex: 'description',
        },
        {
            title: 'Status', key: 'status', dataIndex: ['externalInfo', 'status'],
            render: (value: string) => value ? <Tag className="title-caps">{ value.replaceAll('_', ' ') }</Tag> : '-',
        },
        {
            title: 'Sent', key: 'sent', dataIndex: ['externalInfo', 'sentDate'],
            render: (value: string) => <SimpleDate date={value} />,
        },
        {
            title: 'Expected Delivery', key: 'expectedDeliveryDate', dataIndex: ['externalInfo', 'expectedDeliveryDate'],
            render: (value: string) => <SimpleDate date={value} />,
        },
        {
            title: '', dataIndex: '', key: 'action',
            render: (nothing: any, record: IMail) => <ActionsMenu<IMail> record={record} actions={this.actions} onClick={this.onActionMenuClick} />,
        },
    ]

    //#region action menu
    onActionMenuClick = async (mail: IMail, actionKey: string) => {
        switch (actionKey) {
            case 'details':
                this.setState({ isDetailsVisible: true, selectedMail: mail });
                return;
            case 'tracking':
                window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${ mail.externalInfo.trackingNumber }`, '_blank');
                return;
            default:
                console.log(actionKey, 'for', mail);
        }
    }

    actions: ActionMenuItemMap<IMail> = {
        details: { icon: <InfoCircleOutlined />, text: 'View Details' },
        tracking: { icon: <BarcodeOutlined />, text: 'View Tracking', disabled: (mail) => !mail.externalInfo.trackingNumber },
    };
    //#endregion action menu

    get emptyItem() {
        return (
            <Empty description={`No letters have been sent to ${ this.props.client.displayName } yet.`} />
        );
    }

    get mailTable() {
        return (
            <Table
                rowKey="id"
                columns={this.columns}
                dataSource={this.props.mail}
                locale={{ emptyText: this.emptyItem }}
                pagination={{ hideOnSinglePage: true }}
            />
        );
    }

    get mailCard() {
        return (
            <Card title="Letters" bordered={false}>
                {this.mailTable}
            </Card>
        );
    }

    closeDetailsModal = () => {
        this.setState({ isDetailsVisible: false, selectedMail: undefined });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.simpleList ? this.mailTable : this.mailCard}

                <ViewMailDetailsModal
                    isVisible={this.state.isDetailsVisible}
                    mail={this.state.selectedMail}
                    close={this.closeDetailsModal}
                />
            </React.Fragment>
        );
    }
}

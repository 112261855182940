import { displayErrorNotification } from 'utils/errors';
import { GlobalTractState, TractAction, TractActionType, TractsById } from '../types/tracts';

const initialAuthState: GlobalTractState = {
    isFetching: false,
    hasError: false,
    tractsByInventoryId: {},
};

export function tractReducer(state = initialAuthState, action: TractAction): GlobalTractState {
    switch (action.type) {
        case TractActionType.FETCHING_MULTIPLE:
        case TractActionType.FETCHING_MULTIPLE_PENDING:
        case TractActionType.FETCHING_ONE:
        case TractActionType.FETCHING_ONE_PENDING:
        case TractActionType.FETCHING_RELATED_PENDING:
            return { ...state, isFetching: true, hasError: false };
        case TractActionType.FETCHING_ONE_SUCCESS:
            const os: GlobalTractState = { ...state, isFetching: false, tractsByInventoryId: { ...state.tractsByInventoryId } };
            if (!os.tractsByInventoryId[action.inventoryId]) {
                state.tractsByInventoryId[action.inventoryId] = {};
            }

            os.tractsByInventoryId[action.inventoryId][action.tract.id] = action.tract;

            return os;
        case TractActionType.FETCHING_MULTIPLE_SUCCESS:
            const ms: GlobalTractState = { ...state, isFetching: false, tractsByInventoryId: { ...state.tractsByInventoryId } };
            if (!ms.tractsByInventoryId[action.inventoryId]) {
                ms.tractsByInventoryId[action.inventoryId] = {};
            }

            ms.tractsByInventoryId[action.inventoryId] = { ...ms.tractsByInventoryId[action.inventoryId], ...action.tracts.reduce((v: TractsById, t) => { v[t.id] = t; return v; }, {}) };

            return ms;
        case TractActionType.FETCHING_MULTIPLE_FAILURE:
        case TractActionType.FETCHING_ONE_FAILURE:
        case TractActionType.FETCHING_RELATED_FAILURE:
            displayErrorNotification(action.error);
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case TractActionType.FETCHING_MULTIPLE_SUCCESS_CACHED:
        case TractActionType.FETCHING_ALL:
            return { ...state, isFetching: false };
        case TractActionType.PURGE:
            return { ...state, isFetching: false, hasError: false, tractsByInventoryId: {} };
        case TractActionType.FETCHING_RELATED_SUCCESS:
            const tractsByInventoryId = { ...state.tractsByInventoryId };

            action.tracts.forEach((t) => {
                if (!tractsByInventoryId[t.inventoryId!]) {
                    tractsByInventoryId[t.inventoryId!] = {};
                }

                tractsByInventoryId[t.inventoryId!][t.id] = t;
            });

            return {
                ...state,
                tractsByInventoryId,
                isFetching: false,
                hasError: false,
                error: undefined,
            };
        default:
            return state;
    }
}

import { IRestError } from 'models/common/restResult';
import { GlobalOrgState, OrgAction, OrgActionType } from '../types/org';

const initialAuthState: GlobalOrgState = {
    isFetching: false,
    isCreating: false,
    hasError: false,
    createdId: '',
    selectedOrgShortId: '',
    selectedOrgLongId: '',
    orgsByShortId: {},
    orgsByLongId: {},
    connectedStripeAccount: undefined,
};

export function orgReducer(state = initialAuthState, action: OrgAction): GlobalOrgState {
    switch (action.type) {
        case OrgActionType.SELECT_PENDING:
            return { ...state, isFetching: true, hasError: false, error: undefined, };
        case OrgActionType.SELECT_SUCCESS:
            return {
                ...state, isFetching: false, hasError: false,
                selectedOrgShortId: action.org.shortId, selectedOrgLongId: action.org.id,
                orgsByShortId: { ...state.orgsByShortId, [action.org.shortId]: action.org },
                orgsByLongId: { ...state.orgsByLongId, [action.org.id]: action.org },
            };
        case OrgActionType.SELECT_FAILURE:
            return { ...state, isFetching: false, selectedOrgShortId: '', selectedOrgLongId: '', hasError: true, error: action.error };
        case OrgActionType.CREATE_PENDING:
            return { ...state, isCreating: true, createdId: '' };
        case OrgActionType.CREATE_PAYMENT_METHOD_PENDING:
            return { ...state, isCreating: true };
        case OrgActionType.CREATE_SUCCESS:
            return {
                ...state, hasError: false, error: undefined, createdId: action.org.id,
                orgsByShortId: { ...state.orgsByShortId, [action.org.shortId]: action.org },
                orgsByLongId: { ...state.orgsByLongId, [action.org.id]: action.org },
            };
        case OrgActionType.CREATE_CLEAR:
            return {
                ...state, createdId: '', isCreating: false,
            };
        case OrgActionType.CREATE_FAILURE:
        case OrgActionType.REFRESH_FAILURE:
            return { ...state, isFetching: false, isCreating: false, hasError: true, error: action.error as IRestError };
        case OrgActionType.REFRESH:
            return {
                ...state, isFetching: false, hasError: false, error: undefined,
                orgsByShortId: { ...state.orgsByShortId, [action.org.shortId]: action.org },
                orgsByLongId: { ...state.orgsByLongId, [action.org.id]: action.org },
            };
        case OrgActionType.DESELECT:
                return initialAuthState;
        case OrgActionType.EVENT_CREATED:
        case OrgActionType.EVENT_UPDATED:
            return {
                ...state,
                orgsByShortId: { ...state.orgsByShortId, [action.org.shortId]: action.org },
                orgsByLongId: { ...state.orgsByLongId, [action.org.id]: action.org },
            };
        case OrgActionType.EVENT_DELETED:
            if (!action.lookup.id || !action.lookup.shortId) {
                return state;
            }

            const orgsByShortId = { ...state.orgsByShortId };
            delete orgsByShortId[action.lookup.shortId];

            const orgsByLongId = { ...state.orgsByLongId };
            delete orgsByLongId[action.lookup.id];

            return {
                ...state,
                orgsByShortId,
                orgsByLongId,
            };
        case OrgActionType.SET_STRIPEACCOUNT:
            return {
                ...state,
                connectedStripeAccount: action.accountId,
            };
        case OrgActionType.UNSET_STRIPEACCOUNT:
            return {
                ...state,
                connectedStripeAccount: undefined,
            };
        default:
            return state;
    }
}

import React from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { withRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { ServerEventSource } from 'components/sse/eventSource';

import { GlobalState } from 'store';
import { getUser } from 'store/selectors/auth';

//views
import { WrappedOrganizationCreation } from 'views/organization/new';

import { hasValidToken } from 'utils/auth';

const SentryRoute = Sentry.withSentryRouting(Route);

const mapStateToProps = (state: GlobalState) => ({
    loggedInUser: getUser(state),
});

interface IFullScreenLayoutProps extends ReturnType<typeof mapStateToProps>, RouteComponentProps {}

interface IFullScreenLayoutState {}

class FullScreenLayoutBase extends React.PureComponent<IFullScreenLayoutProps, IFullScreenLayoutState> {
    state: Readonly<IFullScreenLayoutState> = {}

    componentDidMount() {
        const previous = `?previous=${ encodeURIComponent(this.props.location.pathname+this.props.location.search) }`;

        if (!hasValidToken()) {
            console.log('fullscreen layout: has INVALID token');
            this.props.history.push(`/auth/login${ previous }`);
            return;
        }

        if (!this.props.loggedInUser) {
            console.log('fullscreen layout: no logged in user');
            this.props.history.push(`/auth/login${ previous }`);
            return;
        }

        if (!this.props.loggedInUser.emailVerified) {
            console.log('fullscreen layout: email not verified');
            this.props.history.push(`/auth/verify-email${ previous }`);
            return;
        }
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <SentryRoute exact path="/org/new" render={() => <WrappedOrganizationCreation />} />
                </Switch>

                <ServerEventSource />
            </Layout>
        )
    }
}

export default connect(mapStateToProps)(withRouter(FullScreenLayoutBase));

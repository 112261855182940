import React from 'react';
import { gt } from 'semver';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';

import { getWebAppVersion } from 'api/app';

interface IClientVersionCheckerProps {}

interface IClientVersionCheckerState {}

export class ClientVersionChecker extends React.PureComponent<IClientVersionCheckerProps, IClientVersionCheckerState> {
    static versionStorageKey = 'LendiomClientVersion';
    static version = process.env.REACT_APP_VERSION as string;
    static checkInterval = 1000 * 60 * 30; //every 30 minutes

    state: Readonly<IClientVersionCheckerState> = {};
    intervalId: number = -1;

    componentDidMount() {
        localStorage.setItem(ClientVersionChecker.versionStorageKey, ClientVersionChecker.version);
        this.intervalId = window.setInterval(this.checkVersion, ClientVersionChecker.checkInterval);

        this.checkVersion();
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
    }

    checkVersion = async () => {
        try {
            let storedVersion = localStorage.getItem(ClientVersionChecker.versionStorageKey);
            if (typeof storedVersion !== 'string') {
                storedVersion = ClientVersionChecker.version;
                localStorage.setItem(ClientVersionChecker.versionStorageKey, ClientVersionChecker.version);
            }

            const webApp = await getWebAppVersion();

            console.log(webApp.version, 'vs', storedVersion);

            if (!gt(webApp.version, storedVersion)) {
                return;
            }

            notification.info({
                key: 'update-notification',
                placement: 'bottomRight',
                duration: 0,
                message: 'New Version! 🎉',
                description: `There is a new version of Lendiom: v${ webApp.version }! Please refresh the page to load the latest version.`,
                btn: this.refreshButton,
            });
        } catch (e) {
            console.log('Failed to check the version', process.env.NODE_ENV === 'production' && e);
        }
    }

    onRefreshClick = () => {
        window.location.reload();
    }

    get refreshButton() {
        return <Button type="primary" size="small" onClick={this.onRefreshClick} icon={<ReloadOutlined />}>Refresh</Button>;
    }

    render() {
        return null;
    }
}

import { GlobalClientState, ClientAction, ClientActionType, ClientsById } from "store/types/clients";
import { displayErrorNotification } from "utils/errors";

const initialAuthState: GlobalClientState = {
    isFetching: false,
    hasError: false,
    clientsById: {},
    pagination: {
        offset: 0,
        limit: 10,
        count: 0,
    },
};

export function clientReducer(state = initialAuthState, action: ClientAction): GlobalClientState {
    switch (action.type) {
        case ClientActionType.FETCHING_MULTIPLE:
        case ClientActionType.FETCHING_MULTIPLE_PENDING:
        case ClientActionType.FETCHING_ONE:
        case ClientActionType.FETCHING_ONE_PENDING:
            return { ...state, isFetching: true, hasError: false, error: undefined };
        case ClientActionType.FETCHING_ONE_SUCCESS:
            return { ...state, isFetching: false, clientsById: { ...state.clientsById, [action.client.id]: action.client } };
        case ClientActionType.FETCHING_MULTIPLE_SUCCESS:
            return { ...state, isFetching: false, clientsById: { ...action.clients.reduce((v: ClientsById, p) => { v[p.id] = p; return v; }, {}) }, pagination: { ...state.pagination, ...action.pagination } };
        case ClientActionType.FETCHING_MULTIPLE_FAILURE:
        case ClientActionType.FETCHING_ONE_FAILURE:
            displayErrorNotification(action.error);
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case ClientActionType.PURGE:
            //return { ...state, fetchedAt: '', isFetching: false, hasError: false, clientsById: {} };
            return { ...state, isFetching: false, hasError: false, error: undefined, clientsById: {} };
        case ClientActionType.EVENT_CREATED:
        case ClientActionType.EVENT_UPDATED:
            return {
                ...state,
                clientsById: { ...state.clientsById, [action.client.id]: action.client },
            };
        case ClientActionType.EVENT_DELETED:
            if (!action.lookup.id) {
                return state;
            }

            const clientsById = { ...state.clientsById };
            delete clientsById[action.lookup.id];

            return {
                ...state,
                clientsById,
            };
        default:
            return state;
    }
}

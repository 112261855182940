import React from 'react';
import type { FC } from 'react';
import { useRouteMatch } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { List, Button, Row, Col, Typography, Tag } from 'antd';
import type { PaginationConfig } from 'antd/lib/pagination';

import type { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import type { IClient } from 'models/client';

import { ClientStatusTag } from './clientStatusTag';

interface IClientsListProps {
    isLoading: boolean;
    clients: IClient[];
    pagination: PaginationConfig;
}

export const ClientsList: FC<IClientsListProps> = (props) => {
    const match = useRouteMatch<IOrgIdPathParams>();

    return (
        <List<IClient>
            className="client-list"
            itemLayout="vertical"
            loading={props.isLoading}
            dataSource={props.clients}
            pagination={props.pagination}
            renderItem={(client: IClient) => {
                return (
                    <List.Item
                        key={client.id}
                        actions={[
                            <Button key={client.id+'_view'} icon={<InfoCircleOutlined />} href={`/${ match.params.orgId }/clients/${ client.id }`}>
                                View Details
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={client.displayName}
                            description={
                                <React.Fragment>
                                    <ClientStatusTag status={client.status} />
                                    <Tag>
                                        <Typography.Paragraph copyable style={{ marginBottom: 'unset' }}>
                                            { client.accountNumber }
                                        </Typography.Paragraph>
                                    </Tag>
                                </React.Fragment>
                            }
                        />

                        <Row gutter={16}>
                            <Col span={24}>
                                <Typography.Title level={4} style={{ marginTop: '3px', marginBottom: '0.1em' }}>Type</Typography.Title>
                                <Typography.Text className="title-caps">{ client.type }</Typography.Text>
                            </Col>
                            <Col span={24} className="second-description">
                                <Typography.Title level={4} style={{ marginTop: '8px', marginBottom: '0.1em' }}>Entities</Typography.Title>
                                <Typography.Text className="title-caps">{ client.entities.map((l) => l.label).join(', ') }</Typography.Text>
                            </Col>
                        </Row>
                    </List.Item>
                );
            }}
        />
    );
}

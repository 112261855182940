import React from 'react';
import { connect, DispatchProp, useStore } from 'react-redux';
import { Modal, Form, FormInstance, Input, Row, Col } from 'antd';
import { StripeProvider, Elements, injectStripe, ReactStripeElements, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

import { timeoutToPromise } from 'utils/promises';
import { displayErrorNotification } from 'utils/errors';
import { stripeApiKey } from 'utils/auth';
import { trackUmami } from 'utils/umami';

import { GlobalState } from 'store';
import { getUser } from 'store/selectors/auth';
import { getSelectedOrg } from 'store/selectors/org';
import { orgRefresh } from 'store/actions/org';

import { IClient } from 'models/client';
import { IOrganization } from 'models/organization';

import { getPaymentIntentSecret } from 'api/organizations';
import { getClientEntity, getClientPaymentIntentSecret } from 'api/clients';

import './addPaymentMethodModal.css';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    user: getUser(state),
});

interface IAddPaymentMethodBaseProps extends ReturnType<typeof mapStateToProps>, DispatchProp, ReactStripeElements.InjectedStripeProps {
    visible: boolean;
    for: IClient | IOrganization;
    close: (added: boolean, paymentMethod: string) => void;
}

interface IAddPaymentMethodBaseState {
    isAdding: boolean;
    card: {
        number: {
            valid: boolean,
            touched: boolean,
        },
        exp: {
            valid: boolean,
            touched: boolean,
        },
        cvc: {
            valid: boolean,
            touched: boolean,
        },
    };
}

class AddPaymentMethodBase extends React.PureComponent<IAddPaymentMethodBaseProps, IAddPaymentMethodBaseState> {
    state: Readonly<IAddPaymentMethodBaseState> = {
        isAdding: false,
        card: {
            number: {
                valid: false,
                touched: false,
            },
            exp: {
                valid: false,
                touched: false,
            },
            cvc: {
                valid: false,
                touched: false,
            },
        },
    }

    formRef = React.createRef<FormInstance>();

    isForClient(arg: IClient | IOrganization): arg is IClient {
        return 'primaryEntity' in arg;
    }

    onCancel = () => {
        this.reset(false, '');
    }

    reset = (added: boolean, paymentMethod: string) => {
        this.setState({
            isAdding: false,
            card: {
                number: {
                    valid: false,
                    touched: false,
                },
                exp: {
                    valid: false,
                    touched: false,
                },
                cvc: {
                    valid: false,
                    touched: false,
                },
            },
        }, () => this.props.close(added, paymentMethod));
    }

    getBillingDetails = async (): Promise<stripe.BillingDetails> => {
        const { getFieldValue } = this.formRef.current!;
        const org = this.props.selectedOrg!;

        if (this.isForClient(this.props.for)) {
            const client = this.props.for;

            const primaryEntity = await getClientEntity(org.id, client.id, client.primaryEntity.id);
            const address = primaryEntity.addresses![0];

            return {
                name: getFieldValue('creditCardName'),
                email: primaryEntity.email,
                phone: primaryEntity.phoneNumbers![0].number,
                address: {
                    line1: address.streetAddresses[0],
                    line2: address.streetAddresses.length === 2 ? address.streetAddresses[1] : undefined,
                    city: address.city,
                    state: address.state,
                    postal_code: address.zipCode,
                },
            };
        }

        return {
            name: getFieldValue('creditCardName'),
            email: this.props.user!.email,
            address: {
                line1: org.addresses.billing.streetAddresses[0],
                line2: org.addresses.billing.streetAddresses.length === 2 ? org.addresses.billing.streetAddresses[1] : undefined,
                city: org.addresses.billing.city,
                state: org.addresses.billing.state,
                postal_code: org.addresses.billing.zipCode,
            },
        };
    }

    onAddClick = () => {
        if (!this.checkCreditCardInputs()) {
            return;
        }

        this.setState({ isAdding: true }, async () => {
            const org = this.props.selectedOrg!;

            try {
                const secretResult = this.isForClient(this.props.for) ? await getClientPaymentIntentSecret(org.id, this.props.for.id) : await getPaymentIntentSecret(org.id);

                const result = await this.props.stripe!.handleCardSetup(secretResult.secret, {
                    payment_method_data: {
                        billing_details: await this.getBillingDetails(),
                    }
                });

                if (result.error) {
                    this.setState({ isAdding: false });
                    console.log('error from stripe:', result.error);
                    displayErrorNotification({ message: result.error.message });
                    return;
                }

                if (!result.setupIntent) {
                    //shouldn't really happen
                    displayErrorNotification({ message: 'Invalid result (no setup intent).' });
                    return;
                }

                await timeoutToPromise(4500);
                await this.props.dispatch(orgRefresh(org.id) as any);

                this.reset(true, result.setupIntent.payment_method!);
                trackUmami('Payment Method Added');
            } catch (e) {
                this.setState({ isAdding: false });
                displayErrorNotification(e);
                return;
            }
        });
    }

    checkCreditCardInputs = (): boolean => {
        const { card } = this.state;

        let goNext = true;
        if (!this.state.card.number.valid) {
            card.number.touched = true;
            goNext = false;
        }

        if (!this.state.card.exp.valid) {
            card.exp.touched = true;
            goNext = false;
        }

        if (!this.state.card.cvc.valid) {
            card.cvc.touched = true;
            goNext = false;
        }

        this.setState({ card });

        return goNext;
    }

    //#region card inputs
    get creditCardNameInput() {
        if (!this.props.selectedOrg) {
            return null;
        }

        return (
            <Form.Item name="creditCardName" label="Name on Card" rules={[{ required: true, message: 'The full name of the person on the card is required.' }]}>
                <Input
                    placeholder="Full Name on Card"
                    autoComplete="cc-name"
                    disabled={this.state.isAdding}
                />
            </Form.Item>
        );
    }

    stripeChange = (res: ReactStripeElements.ElementChangeResponse) => {
        const { card } = this.state;

        switch (res.elementType) {
            case 'cardNumber':
                card.number.valid = !res.empty && res.complete;
                break;
            case 'cardExpiry':
                card.exp.valid = !res.empty && res.complete;
                break;
            case 'cardCvc':
                card.cvc.valid = !res.empty && res.complete;
                break;
            default:
                return;
        }

        this.setState({ card }, () => this.formRef.current?.validateFields(['creditCardName']));
    }

    stripeFocus = (res: ReactStripeElements.ElementChangeResponse) => {
        const { card } = this.state;
        switch (res.elementType) {
            case 'cardNumber':
                card.number.touched = true;
                break;
            case 'cardExpiry':
                card.exp.touched = true;
                break;
            case 'cardCvc':
                card.cvc.touched = true;
                break;
            default:
                return;
        }

        this.setState({ card });
    }

    get creditCardNumberInput() {
        const help = 'A valid credit card number is required.';
        const { number } = this.state.card;

        return (
            <Form.Item label="Card Number" required className="stripe-item" validateStatus={number.touched && !number.valid ? 'error' : ''} help={number.touched && !number.valid ? help : ''}>
                <CardNumberElement className={`ant-input ant-input-lg card-input ${this.state.isAdding ? 'disabled' : ''}`}
                    disabled={this.state.isAdding}
                    onChange={this.stripeChange}
                    onFocus={this.stripeFocus}
                    style={{
                        base: {
                            fontSize: '14px',
                            color: 'rgba(0, 0, 0, 0.65)',
                            letterSpacing: '0.025em',
                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                            '::placeholder': {
                                color: '#bfbfbf',
                            },
                        },
                    }}
                />
            </Form.Item>
        );
    }

    get creditCardExpInputs() {
        const expHelp = 'A valid expiration date is required.';
        const cvcHelp = 'A valid CVC number is required.';
        const { exp, cvc } = this.state.card;

        return (
            <Row>
                <Col span={6}>
                    <Form.Item label="Card Exp" required className="stripe-item" validateStatus={exp.touched && !exp.valid ? 'error' : ''} help={exp.touched && !exp.valid ? expHelp : ''}>
                        <CardExpiryElement className={`ant-input ant-input-lg card-input ${this.state.isAdding ? 'disabled' : ''}`}
                            disabled={this.state.isAdding}
                            onChange={this.stripeChange}
                            onFocus={this.stripeFocus}
                            style={{
                                base: {
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    letterSpacing: '0.025em',
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                                    '::placeholder': {
                                        color: '#bfbfbf',
                                    },
                                },
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6} push={1}>
                    <Form.Item label="CVC" required className="stripe-item" validateStatus={cvc.touched && !cvc.valid ? 'error' : ''} help={cvc.touched && !cvc.valid ? cvcHelp : ''}>
                        <CardCVCElement className={`ant-input ant-input-lg card-input ${this.state.isAdding ? 'disabled' : ''}`}
                            disabled={this.state.isAdding}
                            onChange={this.stripeChange}
                            onFocus={this.stripeFocus}
                            style={{
                                base: {
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    letterSpacing: '0.025em',
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                                    '::placeholder': {
                                        color: '#bfbfbf',
                                    },
                                },
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
    //#endregion

    render() {
        if (!this.props.selectedOrg) {
            return null;
        }

        return (
            <Modal
                open={this.props.visible}
                title="Add Payment Method"
                okText="Add"
                onOk={this.onAddClick}
                okButtonProps={{ disabled: this.state.isAdding, loading: this.state.isAdding }}
                onCancel={this.onCancel}
                cancelButtonProps={{ disabled: this.state.isAdding }}
                closable={!this.state.isAdding}
                className="add-payment-method-modal"
                destroyOnClose
                keyboard={false}
                maskClosable={false}
            >
                <Form ref={this.formRef} layout="vertical">
                    {this.creditCardNameInput}
                    {this.creditCardNumberInput}
                    {this.creditCardExpInputs}
                </Form>
            </Modal>
        );
    }
}

export const AddPaymentMethodModalBase = connect(mapStateToProps)(injectStripe(AddPaymentMethodBase));

interface IAddPaymentMethodModalProps {
    visible: boolean;
    for: IClient | IOrganization;
    close: (added: boolean, paymentMethod: string) => void;
}

export const AddPaymentMethodModal: React.FunctionComponent<IAddPaymentMethodModalProps> = (props) => {
    const s = useStore<GlobalState>();

    if (!props.visible) {
        return null;
    }

    return (
        <StripeProvider apiKey={stripeApiKey} stripeAccount={s.getState().org.connectedStripeAccount}>
            <Elements>
                <AddPaymentMethodModalBase visible={props.visible} close={props.close} for={props.for} />
            </Elements>
        </StripeProvider>
    );
}

import React, { useState, lazy, Suspense } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { PageContainer } from '@ant-design/pro-layout';
import type { BreadcrumbProps } from 'antd/lib/breadcrumb';

import { getUser } from 'store/selectors/auth';
import { getSelectedOrg, getSelectedOrgShortId } from 'store/selectors/org';

import { StripeConnectButton } from 'components/stripe/stripeConnectButton';
import { breadCrumbItemRender } from 'utils/breadCrumbs';

import { DashboardOverviewTab } from './overviewTab';

const DashboardLateTab = lazy(() => import('./lateTab'));

//https://preview.pro.ant.design/dashboard/analysis/ && https://github.com/ant-design/pro-blocks/blob/antd%403/DashboardAnalysis/src/index.tsx

type activeDashboardTab = 'overview' | 'late';

export const Dashboard: FC = () => {
    const [activeTab, setActiveTab] = useState<activeDashboardTab>('overview');
    const user = useSelector(getUser);
    const org = useSelector(getSelectedOrg);
    const shortOrgId = useSelector(getSelectedOrgShortId);

    const onTabChange = (tab: string) => {
        //TODO: make this add something to the url query
        setActiveTab(tab as activeDashboardTab);
    };

    const breadcrumb: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        items: [
            {
                path: `/${ shortOrgId }`,
                breadcrumbName: `${org ? org.name : 'Organization'}'s Dashboard`,
            },
        ],
    };

    return (
        <PageContainer
            title="Dashboard"
            subTitle={<React.Fragment>Greetings, { user ? user.firstName : 'Lendiom User' }!</React.Fragment>}
            breadcrumb={breadcrumb}
            extra={<StripeConnectButton />}
            tabList={[
                { key: 'overview', tab: 'Overview' },
                { key: 'late', tab: 'Late' },
            ]}
            tabActiveKey={activeTab}
            onTabChange={onTabChange}
            childrenContentStyle={{
                paddingTop: 0,
            }}
        >
            { activeTab === 'overview' ? <DashboardOverviewTab /> : null }
            { activeTab === 'late' ? <Suspense fallback={null}><DashboardLateTab /> </Suspense> : null}
        </PageContainer>
    );
}

import type { ThunkAction } from "redux-thunk";
import * as casbin from "casbin";

import type { GlobalState } from "store";
import type { IRestError } from "models/common/restResult";
import type { IMyPermissions } from "models/permissions/myPermissions";
import type { PermissionLoadingAction, PermissionLoadingSuccessAction, PermissionLoadingFailureAction, PermissionOrgLoadingSuccessAction, PermissionOrgLoadingAction, PermissionOrgLoadingFailureAction, cachedEnforcements } from "store/types/permissions";
import { PermissionActionType } from "store/types/permissions";

import { PermissionAction, PermissionFeature } from "models/permissions/features";
import { serializeEnforceRequest } from "utils/permissions";

import { getUserPermissions } from "api/user";

export const permissionsLoad = (): ThunkAction<Promise<PermissionLoadingFailureAction | PermissionLoadingSuccessAction>, GlobalState, null, PermissionLoadingAction | PermissionLoadingFailureAction | PermissionLoadingSuccessAction> => {
    return async (dispatch, getState) => {
        dispatch(permissionLoading());

        try {
            const result = await getUserPermissions();

            const model = casbin.newModelFromString(result.model);
            const enforcer = await casbin.newEnforcer(model);

            const state = getState();

            const orgsPolicies = result.orgs.map((o) => {
                const orgPolicies = o.policies.map((p) => enforcer.addNamedPolicy('p', ...p));
                const policy = enforcer.addNamedGroupingPolicy('g', state.auth.userId, o.role, o.orgId);

                return Promise.all([...orgPolicies, policy]);
            });

            await Promise.all(orgsPolicies);

            return dispatch(permissionLoadingSuccess(result, enforcer));
        } catch (e) {
            return dispatch(permissionLoadingFailure(e as IRestError));
        }
    }
};

export const permissionLoadForOrg = (orgLongId: string): ThunkAction<Promise<PermissionOrgLoadingFailureAction | PermissionOrgLoadingSuccessAction>, GlobalState, null, PermissionOrgLoadingAction | PermissionOrgLoadingFailureAction | PermissionOrgLoadingSuccessAction> => {
    return async (dispatch, getState) => {
        dispatch(permissionOrgLoading(orgLongId));

        let state = getState();
        if (!state.permission.enforcer || !state.permission.myPermissions) {
            return dispatch(permissionOrgLoadingFailure({
                status: 400,
                code: 7779818,
                error: 'Permissions system did not loan',
                requestId: 'client side',
            }));
        }

        try {
            const cached: Record<string, boolean> = {};
            const promises: Promise<void>[] = [];

            Object.values(PermissionFeature).forEach((f) => {
                const items = Object.values(PermissionAction).map(async (a) => {
                    const key = serializeEnforceRequest(orgLongId, state.auth.userId, f, a);
                    const res = await state.permission.enforcer!.enforce(state.auth.userId, state.org.selectedOrgLongId, f, a);

                    cached[key] = res;
                });

                promises.push(...items);
            });

            await Promise.all(promises);

            if (!Object.values(cached).some((v) => v)) {
                //TODO: this is technical debt!
                console.log('nothing, seems like we failed or load the permissions for the organization...retrying');
                setTimeout(() => dispatch(permissionLoadForOrg(orgLongId)), 10);
            }

            return dispatch(permissionOrgLoadingSuccess(orgLongId, cached));
        } catch (e) {
            return dispatch(permissionOrgLoadingFailure(e as IRestError));
        }
    };
};

export function permissionLoading(): PermissionLoadingAction {
    return {
        type: PermissionActionType.LOADING,
    };
}

export function permissionLoadingFailure(error?: IRestError): PermissionLoadingFailureAction {
    return {
        type: PermissionActionType.LOADING_FAILURE,
        error,
    };
}

export function permissionLoadingSuccess(permissions: IMyPermissions, enforcer: casbin.Enforcer): PermissionLoadingSuccessAction {
    return {
        type: PermissionActionType.LOADING_SUCCESS,
        permissions,
        enforcer,
    };
}

export function permissionOrgLoading(orgId: string): PermissionOrgLoadingAction {
    return {
        type: PermissionActionType.LOADING_ORG,
        orgLongId: orgId,
    };
}

export function permissionOrgLoadingFailure(error?: IRestError): PermissionOrgLoadingFailureAction {
    return {
        type: PermissionActionType.LOADING_ORG_FAILURE,
        error,
    };
}

export function permissionOrgLoadingSuccess(orgId: string, cache: cachedEnforcements): PermissionOrgLoadingSuccessAction {
    return {
        type: PermissionActionType.LOADING_ORG_SUCCESS,
        orgLongId: orgId,
        cachedEnforcements: cache,
    };
}

import { IObjectDates } from './common/objectDates';

export interface INotification extends IObjectDates {
    id: string,
    type: NotificationType,
    status: NotificationStatus,
    title: string,
    description: string,
    read: boolean,
    visible: boolean,
    displayDate: string,
}

export enum NotificationType {
    MESSAGES = 'messages',
    NOTIFICATIONS = 'notifications',
    REMINDERS = 'reminders',
}

export enum NotificationStatus {
    URGENT = 'urgent',
    HIGHEST = 'highest',
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low',
    LOWEST = 'lowest',
    NONE = 'none',
}

import { Action } from 'redux';

import { ILoan } from 'models/loan';
import { IRestError } from 'models/common/restResult';
import { ILookup } from 'models/common/lookup';
import { IPagination } from 'models/common/pagination';

export type LoansById = { [loanId: string]: ILoan };

export type GlobalLoanState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    loansById: LoansById;
    pagination: IPagination;
};

export enum LoanActionType {
    PURGE = 'loans/purge',
    EVENT_CREATED = 'loans/event/created',
    EVENT_UPDATED = 'loans/event/updated',
    EVENT_DELETED = 'loans/event/deleted',
    FETCHING_MULTIPLE = 'loans/fetch',
    FETCHING_MULTIPLE_PENDING = 'loans/fetch/pending',
    FETCHING_MULTIPLE_SUCCESS = 'loans/fetch/success',
    FETCHING_MULTIPLE_FAILURE = 'loans/fetch/failure',
    FETCHING_ONE = 'loan/fetch',
    FETCHING_ONE_PENDING = 'loan/fetch/pending',
    FETCHING_ONE_SUCCESS = 'loan/fetch/success',
    FETCHING_ONE_FAILURE = 'loan/fetch/failure',
    FETCHING_RELATED_PENDING = 'loans/fetch-related/pending',
    FETCHING_RELATED_SUCCESS = 'loans/fetch-related/success',
    FETCHING_RELATED_FAILURE = 'loans/fetch-related/failure',
}

export interface LoansPurgeAction extends Action {
    type: LoanActionType.PURGE;
}

export interface LoansFetchAction extends Action {
    type: LoanActionType.FETCHING_MULTIPLE;
}

export interface LoansFetchPendingAction extends Action {
    type: LoanActionType.FETCHING_MULTIPLE_PENDING;
}

export interface LoansFetchSuccessAction extends Action {
    type: LoanActionType.FETCHING_MULTIPLE_SUCCESS;
    loans: ILoan[];
    pagination?: IPagination;
    filtered: boolean;
}

export interface LoansFetchFailureAction extends Action {
    type: LoanActionType.FETCHING_MULTIPLE_FAILURE;
    error?: IRestError;
}

export interface LoanFetchAction extends Action {
    type: LoanActionType.FETCHING_ONE;
}

export interface LoanFetchPendingAction extends Action {
    type: LoanActionType.FETCHING_ONE_PENDING;
}

export interface LoanFetchSuccessAction extends Action {
    type: LoanActionType.FETCHING_ONE_SUCCESS;
    loan: ILoan;
}

export interface LoanFetchFailureAction extends Action {
    type: LoanActionType.FETCHING_ONE_FAILURE;
    error?: IRestError;
}

export interface LoanCreatedEventRecievedAction extends Action {
    type: LoanActionType.EVENT_CREATED;
    loan: ILoan;
}

export interface LoanUpdatedEventRecievedAction extends Action {
    type: LoanActionType.EVENT_UPDATED;
    loan: ILoan;
}

export interface LoanDeletedEventRecievedAction extends Action {
    type: LoanActionType.EVENT_DELETED;
    lookup: Partial<ILookup>;
}

export interface LoansFetchRelatedPendingAction extends Action {
    type: LoanActionType.FETCHING_RELATED_PENDING;
}

export interface LoansFetchRelatedSuccessAction extends Action {
    type: LoanActionType.FETCHING_RELATED_SUCCESS;
    loans: ILoan[];
}

export interface LoansFetchRelatedFailureAction extends Action {
    type: LoanActionType.FETCHING_RELATED_FAILURE;
    error?: IRestError;
}

export type LoanRelatedAction = LoansFetchRelatedPendingAction | LoansFetchRelatedSuccessAction | LoansFetchRelatedFailureAction;
export type LoanEventAction = LoanCreatedEventRecievedAction | LoanUpdatedEventRecievedAction | LoanDeletedEventRecievedAction;

export type LoanAction = LoanFetchAction | LoanFetchPendingAction | LoanFetchSuccessAction | LoanFetchFailureAction | LoansFetchAction | LoansFetchPendingAction | LoansFetchSuccessAction | LoansFetchFailureAction | LoansPurgeAction | LoanEventAction | LoanRelatedAction;

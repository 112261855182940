import React from 'react';
import type { FC } from 'react';
import { Button, Popover, Typography } from 'antd';
import type { ButtonProps } from 'antd';

import type { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { useSelector } from 'react-redux';
import { hasAccessTo } from 'store/selectors/permissions';

export interface IAccessControlledButtonProps extends ButtonProps {
    feature: PermissionFeature;
    action: PermissionAction;
    prevent: 'hide' | 'disable' | 'tooltip';
}

export const AccessControlledButton: FC<IAccessControlledButtonProps> = (props) => {
    const canAccess = useSelector(hasAccessTo(props.feature, props.action));

    const { feature, action, prevent, ...remainingProps } = props;

    if (!canAccess && prevent === 'hide') {
        return null;
    }

    const button = (
        <Button
            {...remainingProps}
            disabled={props.disabled || !canAccess}
        />
    );

    // if we aren't going to show the tooltip, then just return the button
    // or, if they can access it, just return the button
    // since the tooltip should only be visible when they can't
    if (prevent === 'disable' || canAccess) {
        return button;
    }

    const content = (
        <div>
            You are missing the<Typography.Text keyboard>{ action }</Typography.Text>permission
            <br />
            on the<Typography.Text keyboard>{ feature.replaceAll('::', ' ') }</Typography.Text>feature.
        </div>
    );

    return (
        <Popover placement="bottomLeft" title="No Permission" content={content}>
            { button }
        </Popover>
    );
}

import { GlobalInventoryState, InventoryAction, InventoryActionType, InventoriesById } from 'store/types/inventories';
import { displayErrorNotification } from 'utils/errors';

const initialAuthState: GlobalInventoryState = {
    // fetchedAt: '',
    isFetching: false,
    hasError: false,
    inventoriesById: {},
};

export function inventoryReducer(state = initialAuthState, action: InventoryAction): GlobalInventoryState {
    switch (action.type) {
        case InventoryActionType.FETCHING_MULTIPLE:
        case InventoryActionType.FETCHING_MULTIPLE_PENDING:
        case InventoryActionType.FETCHING_ONE:
        case InventoryActionType.FETCHING_ONE_PENDING:
            return { ...state, isFetching: true, hasError: false };
        case InventoryActionType.FETCHING_ONE_SUCCESS:
            return { ...state, isFetching: false, inventoriesById: { ...state.inventoriesById, [action.inventory.id]: action.inventory } };
        case InventoryActionType.FETCHING_MULTIPLE_SUCCESS:
            return { ...state, isFetching: false, inventoriesById: { ...state.inventoriesById, ...action.inventories.reduce((v: InventoriesById, p) => { v[p.id] = p; return v; }, {}) } };
        case InventoryActionType.FETCHING_MULTIPLE_FAILURE:
        case InventoryActionType.FETCHING_ONE_FAILURE:
            displayErrorNotification(action.error);
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case InventoryActionType.FETCHING_MULTIPLE_SUCCESS_CACHED:
            return { ...state, isFetching: false };
        case InventoryActionType.PURGE:
            return { ...state, isFetching: false, hasError: false, inventoriesById: {} };
        case InventoryActionType.EVENT_CREATED:
        case InventoryActionType.EVENT_UPDATED:
            return {
                ...state,
                inventoriesById: { ...state.inventoriesById, [action.inventory.id]: action.inventory },
            };
        case InventoryActionType.EVENT_DELETED:
            if (!action.lookup.id) {
                return state;
            }

            const inventoriesById = { ...state.inventoriesById };
            delete inventoriesById[action.lookup.id];

            return {
                ...state,
                inventoriesById,
            };
        default:
            return state;
    }
}

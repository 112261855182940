import React from 'react';
import type { FC } from 'react';
import { EditOutlined, StarTwoTone } from '@ant-design/icons';
import { List, Row, Col, Tooltip, Button } from 'antd';

import type { IClient } from 'models/client';
import type { IEntity } from 'models/entity';

import { SimpleDate } from 'utils/formatting';

import { EntityAddress } from './address';
import { EntityPhoneNumber } from './phoneNumber';

interface IEntityListProps {
    client: IClient;
    entities: IEntity[];
    pageSize: number;
    refresh: () => void;
    openEdit: (entity: IEntity) => void;
}

export const EntityList: FC<IEntityListProps> = (props) => {
    return (
        <List<IEntity>
            itemLayout="vertical"
            dataSource={props.entities}
            renderItem={(entity: IEntity) => {
                const isPrimary = props.client.primaryEntity.id === entity.id;
                let name: JSX.Element | string = `${entity.title ? entity.title + ' ' : ''}${entity.fullName}`;

                if (isPrimary) {
                    name = (
                        <Tooltip overlay={`Primary Entity for ${props.client.displayName}`}>
                            <StarTwoTone twoToneColor="#ffd700" /> {name}
                        </Tooltip>
                    );
                }

                return (
                    <List.Item
                        key={entity.id}
                        actions={[
                            <Button
                                key={`edit-btn-for-${ entity.id }`}
                                icon={<EditOutlined />}
                                onClick={() => props.openEdit(entity)}
                            >Edit</Button>
                        ]}
                    >
                        <List.Item.Meta className="title-caps" title={name} />

                        <Row gutter={[16, 16]}>
                            <Col style={{ marginBottom: '15px' }}>
                                <EntityAddress entity={entity} showTags />
                            </Col>
                            <Col style={{ marginBottom: '15px' }}>
                                Email: <br /> { entity.email ? <a href={'mailto:'+entity.email} target="_blank" rel="noreferrer">{entity.email}</a> : '-'}
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col style={{ marginBottom: '15px' }}>
                                Phone number: <br /> <EntityPhoneNumber entity={entity} />
                            </Col>
                            <Col>
                                Birthday: <SimpleDate date={entity.birthDate} />
                            </Col>
                        </Row>
                    </List.Item>
                );
            }}
        />
    );
}

import { Action } from 'redux';

import { IRestError } from 'models/common/restResult';
import { IRelatedTo } from 'models/common/relatedTo';
import { ITract } from 'models/tract';

export type TractsById = { [tractId: string]: ITract };
export type TractsByInventoryId = { [inventoryId: string]: TractsById };

export type GlobalTractState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    tractsByInventoryId: TractsByInventoryId;
};

export enum TractActionType {
    PURGE = 'tracts/purge',
    FETCHING_ALL = 'tracts/fetch/all',
    FETCHING_MULTIPLE = 'tracts/fetch',
    FETCHING_MULTIPLE_PENDING = 'tracts/fetch/pending',
    FETCHING_MULTIPLE_SUCCESS = 'tracts/fetch/success',
    FETCHING_MULTIPLE_FAILURE = 'tracts/fetch/failure',
    FETCHING_MULTIPLE_SUCCESS_CACHED = 'tracts/fetch/success/cached',
    FETCHING_ONE = 'tract/fetch',
    FETCHING_ONE_PENDING = 'tract/fetch/pending',
    FETCHING_ONE_SUCCESS = 'tract/fetch/success',
    FETCHING_ONE_FAILURE = 'tract/fetch/failure',
    FETCHING_RELATED_PENDING = 'tracts/fetch-related/pending',
    FETCHING_RELATED_SUCCESS = 'tracts/fetch-related/success',
    FETCHING_RELATED_FAILURE = 'tracts/fetch-related/failure',
}

export interface TractsPurgeAction extends Action {
    type: TractActionType.PURGE;
}

export interface TractsFetchAllSuccessAction extends Action {
    type: TractActionType.FETCHING_ALL;
}

export interface TractsFetchAction extends Action {
    type: TractActionType.FETCHING_MULTIPLE;
    inventoryId: string;
}

export interface TractsFetchPendingAction extends Action {
    type: TractActionType.FETCHING_MULTIPLE_PENDING;
    inventoryId: string;
}

export interface TractsFetchSuccessAction extends Action {
    type: TractActionType.FETCHING_MULTIPLE_SUCCESS;
    inventoryId: string;
    tracts: ITract[];
}

export interface TractsFetchFailureAction extends Action {
    type: TractActionType.FETCHING_MULTIPLE_FAILURE;
    inventoryId: string;
    error?: IRestError;
}

export interface TractsFetchSuccessCacheAction extends Action {
    type: TractActionType.FETCHING_MULTIPLE_SUCCESS_CACHED;
    inventoryId: string;
}

export interface TractFetchAction extends Action {
    type: TractActionType.FETCHING_ONE;
    inventoryId: string;
}

export interface TractFetchPendingAction extends Action {
    type: TractActionType.FETCHING_ONE_PENDING;
    inventoryId: string;
}

export interface TractFetchSuccessAction extends Action {
    type: TractActionType.FETCHING_ONE_SUCCESS;
    inventoryId: string;
    tract: ITract;
}

export interface TractFetchFailureAction extends Action {
    type: TractActionType.FETCHING_ONE_FAILURE;
    inventoryId: string;
    error?: IRestError;
}

export interface TractsFetchRelatedPendingAction extends Action {
    type: TractActionType.FETCHING_RELATED_PENDING;
    relatedTos: IRelatedTo[];
}

export interface TractsFetchRelatedSuccessAction extends Action {
    type: TractActionType.FETCHING_RELATED_SUCCESS;
    tracts: ITract[];
}

export interface TractsFetchRelatedFailureAction extends Action {
    type: TractActionType.FETCHING_RELATED_FAILURE;
    error?: IRestError;
}

export type TractRelatedAction = TractsFetchRelatedPendingAction | TractsFetchRelatedSuccessAction | TractsFetchRelatedFailureAction;

export type TractAction = TractFetchAction | TractsFetchAllSuccessAction | TractFetchPendingAction | TractFetchSuccessAction | TractFetchFailureAction | TractsFetchAction | TractsFetchPendingAction | TractsFetchSuccessAction | TractsFetchFailureAction | TractsFetchSuccessCacheAction | TractsPurgeAction | TractRelatedAction;

import React from 'react';
import * as Sentry from '@sentry/react';
import { Layout } from 'antd';
import { withRouter, Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

//views
import { UserRegistration } from 'views/auth/register';
import { UserLogin } from 'views/auth/login';
import { UserForgotPassword } from 'views/auth/forgot-password';
import { UserVerifyEmail } from 'views/auth/verify-email';
import { UserAcceptInvite } from 'views/auth/invite-accept';

import { hasValidToken } from 'utils/auth';

const SentryRoute = Sentry.withSentryRouting(Route);

interface ILayoutForNoAuthProps extends RouteComponentProps { }

interface ILayoutForNoAuthState { }

class LayoutForNoAuthBase extends React.PureComponent<ILayoutForNoAuthProps, ILayoutForNoAuthState> {
    state: Readonly<ILayoutForNoAuthState> = {}

    componentDidUpdate() {
        const { pathname } = this.props.history.location;

        if (pathname.includes('/auth/verify-email') || pathname.includes('/auth/invite-accept')) {
            return;
        }

        if (hasValidToken()) {
            this.props.history.push('/');
            return;
        }
    }

    render() {
        console.log('layout for no auth base is showing');
        return (
            <Layout>
                <Switch>
                    <Redirect exact from="/auth" to="/auth/login" />
                    <SentryRoute exact path="/auth/register" component={UserRegistration} />
                    <SentryRoute exact path="/auth/login/:email?" component={UserLogin} />
                    <SentryRoute exact path="/auth/forgot-password/:token?/:email?" component={UserForgotPassword} />
                    <SentryRoute exact path="/auth/verify-email/:token?" component={UserVerifyEmail} />
                    <SentryRoute exact path="/auth/invite-accept/:code/:email" component={UserAcceptInvite} />
                </Switch>
            </Layout>
        )
    }
}

export default withRouter(LayoutForNoAuthBase);

import { ThunkAction } from 'redux-thunk';

import { GlobalState } from 'store';
import { checkErrorForUnauthorized } from './actions';
import {
    NotificationActionType,
    NotificationsFetchPendingAction,
    NotificationsFetchSuccessCacheAction,
    NotificationsFetchSuccessAction,
    NotificationsFetchFailureAction,
    Notficiations,
    NotificationRead,
    NotificationClearType,
    NotificationEventRecievedAction,
} from 'store/types/notifications';

import { getNotifications, markNotificationAsRead, clearNotificationOfType } from 'api/notifications';
import { IRestError } from 'models/common/restResult';
import { INotification, NotificationType } from 'models/notification';

export const fetchNotifications = (): ThunkAction<Promise<Notficiations>, GlobalState, null, Notficiations> => {
    return async (dispatch, getState) => {
        dispatch(notificationsFetchingPending());

        let date;
        if (getState().notifications.fetchedAt !== '') {
            const d = new Date(getState().notifications.fetchedAt);

            if (!isNaN(d.getTime())) {
                date = d;
            }
        }

        try {
            const notifications = await getNotifications(date);
            return dispatch(notificationsFetchingSuccess(notifications));
        } catch (e) {
            await checkErrorForUnauthorized(e as IRestError, dispatch);

            return dispatch(notificationsFetchingFailure(e as IRestError));
        }
    }
}

export const markNotificationRead = (notificationId: string): ThunkAction<Promise<NotificationRead>, GlobalState, null, NotificationRead> => {
    return async (dispatch) => {
        dispatch({ type: NotificationActionType.MARK_AS_READ_PENDING });

        try {
            const result = await markNotificationAsRead(notificationId);

            return dispatch({ type: NotificationActionType.MARK_AS_READ_SUCCESS, notification: result });
        } catch (e) {
            return dispatch({ type: NotificationActionType.MARK_AS_READ_FAILURE, error: e as IRestError })
        }
    }
}

export const clearNotificationType = (notificationType: NotificationType): ThunkAction<Promise<NotificationClearType>, GlobalState, null, NotificationClearType> => {
    return async (dispatch) => {
        dispatch({ type: NotificationActionType.CLEAR_TYPE_PENDING });

        try {
            await clearNotificationOfType(notificationType);

            return dispatch({ type: NotificationActionType.CLEAR_TYPE_SUCCESS, notificationType });
        } catch (e) {
            return dispatch({ type: NotificationActionType.CLEAR_TYPE_FAILURE, error: e as IRestError })
        }
    }
}

export function notificationsFetchingPending(): NotificationsFetchPendingAction {
    return {
        type: NotificationActionType.FETCHING_MULTIPLE_PENDING,
    };
}

export function notificationsFetchingSuccess(notifications: INotification[]): NotificationsFetchSuccessAction {
    return {
        type: NotificationActionType.FETCHING_MULTIPLE_SUCCESS,
        notifications,
    }
}

export function notificationsFetchingSuccessFromCache(): NotificationsFetchSuccessCacheAction {
    return {
        type: NotificationActionType.FETCHING_MULTIPLE_SUCCESS_CACHED,
    }
}

export function notificationsFetchingFailure(error?: IRestError): NotificationsFetchFailureAction {
    return {
        type: NotificationActionType.FETCHING_MULTIPLE_FAILURE,
        error,
    };
}

export function notificationEventRecieved(notification: INotification): NotificationEventRecievedAction {
    return {
        type: NotificationActionType.EVENT,
        notification,
    };
}

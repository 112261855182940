import React, { useState } from 'react';
import { Modal, Input, Form } from 'antd';

import { displayErrorNotification } from 'utils/errors';
import { setLoanLabel } from 'api/loans';
import { trackUmami } from 'utils/umami';

interface IFormValues {
    label: string;
}

interface ILoanRenameModalProps {
    visible: boolean;
    orgId: string;
    loanId: string;
    initialLabel: string;
    close: () => void;
}

export const LoanRenameModal: React.FC<ILoanRenameModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const onOkayClick = () => {
        setSaving(true);

        form
            .validateFields()
            .then(async (values) => {
                try {
                    await setLoanLabel(props.orgId, props.loanId, values.label);
                    trackUmami('Relabel Loan');

                    props.close();
                    form.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    setSaving(false);
                }
            })
            .catch((e) => {
                console.warn('failed to validate the relabel loan form:', e);
            });
    };

    return (
        <Modal
            open={props.visible}
            title={`Rename the Loan: ${ props.initialLabel }`}
            okText="Rename"
            closable={!isSaving}
            okButtonProps={{ disabled: isSaving, loading: isSaving, htmlType: 'submit' }}
            cancelButtonProps={{ disabled: isSaving }}
            onOk={onOkayClick}
            onCancel={props.close}
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                initialValues={{ label: props.initialLabel }}
            >
                <Form.Item name="label" rules={[{ required: true, message: 'A label is required for the rename to be effective.' }]} style={{ marginBottom: 0 }}>
                    <Input size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

import React from 'react';
import { Card, Row, Col, Statistic, Divider } from 'antd';

import { IUsageInfo } from 'models/dashboard';
import { getUsageInfo } from 'api/dashboard';

import { displayErrorNotification } from 'utils/errors';

interface IStorageUsedProps {
    orgId: string;
}

interface IStorageUsedState {
    loading: boolean;
    usage?: IUsageInfo;
}

//TODO: IDEA! Change this card to be a very general card.
//- how many developments
//- how many tracts
//- how many active clients
//- how many active loans
//- how many late loans
//- how many on time loans
//- how many etc

export class StorageUsedCard extends React.PureComponent<IStorageUsedProps, IStorageUsedState> {
    state: Readonly<IStorageUsedState> = {
        loading: true,
    };

    componentDidMount() {
        if (this.props.orgId) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps: IStorageUsedProps) {
        if (prevProps.orgId === this.props.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const info = await getUsageInfo(this.props.orgId);

                this.setState({ usage: info });
            } catch (e) {
                displayErrorNotification(e, this.loadData);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    get statistics() {
        if (!this.state.usage) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col span={8}>
                        <Statistic title="Folders" value={this.state.usage.files.folderCount} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Files" value={this.state.usage.files.fileCount} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Space Used" value={this.state.usage.files.storageUsed / 1024 / 1024} precision={2} suffix="MB" />
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col span={8}>
                        <Statistic title="Texts This Month" value={this.state.usage.texts.month} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Texts This Year" value={this.state.usage.texts.year} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Texts All Time" value={this.state.usage.texts.allTime} />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Card loading={this.state.loading} bordered={false} title="Usage Info" style={{ height: '100%' }}>
                { this.statistics }
            </Card>
        );
    }
}

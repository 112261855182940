export const getWebAppVersion = async (): Promise<{ version: string, commit: string }> => {
    let url = process.env.REACT_APP_BASEURL as string;
    if (process.env.NODE_ENV === 'development') {
        url = process.env.REACT_APP_LOCAL_SERVER as string;
    }

    const res = await fetch(`${ url }/app/version`);

    return res.json();
}

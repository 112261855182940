import React from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { isMobileOnly } from 'react-device-detect';
import { Drawer, Row, Col, Form, FormInstance, DatePicker, TimePicker, Select, Input, Button, Space } from 'antd';

import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { IClient } from 'models/client';
import { RelatedToType } from 'models/common/relatedTo';
import { ITimelineEntry, TimelineEntryIcons } from 'models/timelineEntry';

import { addTractTimelineEntry } from 'api/tracts';
import { addClientTimelineEntry } from 'api/clients';

import { trackUmami } from 'utils/umami';
import { displayErrorNotification } from 'utils/errors';

interface IFormValues {
    date: Dayjs;
    time: Dayjs;
    color?: string;
    icon?: TimelineEntryIcons;
    content: string;
}

interface INewTimelineEntryDrawerProps {
    visible: boolean;
    close: (saved: boolean) => Promise<void>;
    related: IInventory | ITract | IClient;
    relatedType: RelatedToType;
}

interface INewTimelineEntryDrawerState {
    isSaving: boolean;
}

export class NewTimelineEntryDrawer extends React.PureComponent<INewTimelineEntryDrawerProps, INewTimelineEntryDrawerState> {
    state: Readonly<INewTimelineEntryDrawerState> = {
        isSaving: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    onClose = () => {
        this.resetAndClose(false);
    }

    resetAndClose = async (saved: boolean) => {
        await this.props.close(saved);

        this.setState({ isSaving: false });
        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }

    onSubmit = async () => {
        if (!this.formRef.current) {
            return;
        }

        try {
            const values = await this.formRef.current.validateFields();

            this.setState({ isSaving: true }, async () => {
                const entry: Partial<ITimelineEntry> = {
                    color: values.color,
                    icon: values.icon,
                    content: values.content,
                };

                const m = dayjs(values.date);
                m.hour(values.time.hour())
                m.minute(values.time.minute())
                m.second(values.time.second());
                m.millisecond(values.time.millisecond());

                entry.date = m.toDate();

                try {
                    if (this.props.relatedType === RelatedToType.TRACT) {
                        const related = this.props.related as ITract;

                        await addTractTimelineEntry(related.organization.id, related.inventoryId!, related.id, entry);
                        trackUmami('Create Tract Timeline Entry');
                    } else if (this.props.relatedType === RelatedToType.CLIENT) {
                        const related = this.props.related as IClient;

                        await addClientTimelineEntry(related.organization.id, related.id, entry);
                        trackUmami('Create Client Timeline Entry');
                    }

                    this.resetAndClose(true);
                } catch (e) {
                    displayErrorNotification(e);
                    this.setState({ isSaving: false });
                }
            });
        } catch (e) {
            console.warn("new timeline entry field validation failed", e);
        }
    }

    get dateInput() {
        return (
            <Form.Item name="date" label="Date">
                <DatePicker
                    format="MM/DD/YYYY"
                    disabled={this.state.isSaving}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        );
    }

    get timeInput() {
        return (
            <Form.Item name="time" label="Time">
                <TimePicker
                    use12Hours
                    format="h:mm a"
                    disabled={this.state.isSaving}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        );
    }

    get typeSelector() {
        return (
            <Form.Item name="color" label="Dot/Icon Color" rules={[{ required: true, message: 'Please select the color.' }]}>
                <Select placeholder="Color of the dot/icon" disabled={this.state.isSaving}>
                    <Select.Option value="green">Green</Select.Option>
                    <Select.Option value="red">Red</Select.Option>
                    <Select.Option value="blue">Blue</Select.Option>
                    <Select.Option value="gray">Gray</Select.Option>
                    <Select.Option value="purple">Purple</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get iconSelector() {
        return (
            <Form.Item name="icon" label="Icon">
                <Select<TimelineEntryIcons> disabled={this.state.isSaving}>
                    <Select.Option value="">No Icon</Select.Option>
                    { Object.values(TimelineEntryIcons).map((i) => (
                        <Select.Option key={i} value={i} className="title-caps">{ `${ i }`.replaceAll('-', ' ') }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
        );
    }

    get contentInput() {
        return (
            <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please input the content of the timeline entry' }]}>
                <Input.TextArea rows={4} placeholder="Timeline entry content" disabled={this.state.isSaving} />
            </Form.Item>
        );
    }

    render() {
        let width = '448px';
        let span = 12;
        if (isMobileOnly) {
            width = '100vw';
            span = 24;
        }

        return (
            <Drawer
                title="New Timeline Entry"
                placement="right"
                width={width}
                open={this.props.visible}
                rootClassName="timeline-entry-drawer"
                maskClosable={this.state.isSaving}
                closable={this.state.isSaving}
                extra={
                    <Space>
                        <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.state.isSaving}>Cancel</Button>
                        <Button onClick={this.onSubmit} type="primary" disabled={this.state.isSaving} loading={this.state.isSaving}>Submit</Button>
                    </Space>
                }
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={this.onSubmit}
                    initialValues={{
                        date: dayjs(),
                        time: dayjs(),
                    }}
                >
                    <Row gutter={16}>
                        <Col span={span}>
                            {this.dateInput}
                        </Col>
                        <Col span={span}>
                            {this.timeInput}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={span}>
                            {this.typeSelector}
                        </Col>
                        <Col span={span}>
                            {this.iconSelector}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            {this.contentInput}
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        );
    }
}

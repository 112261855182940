import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NotificationOutlined } from '@ant-design/icons';
import { Avatar, List, Tag, Empty, theme } from 'antd';
import TimeAgo from 'react-timeago';

import { NotificationStatus } from 'models/notification';
import type { INotification } from 'models/notification';

import './notificationList.css';

interface INoticeIconTabProps {
    title: ReactNode;
    list: INotification[];
    showClear?: boolean;
    onClick?: (item: INotification) => void;
    onClear?: () => void;
}

const statusToColor = {
    [NotificationStatus.URGENT]: 'red',
    [NotificationStatus.HIGHEST]: 'volcano',
    [NotificationStatus.HIGH]: 'orange',
    [NotificationStatus.MEDIUM]: 'cyan',
    [NotificationStatus.LOW]: 'bluegeek',
    [NotificationStatus.LOWEST]: 'blue',
};

export const NotificationListTab: FC<INoticeIconTabProps> = (props) => {
    const [hoveringClear, setHoveringClear] = useState<boolean>(false);
    const [hoveringItem, setHoveringItem] = useState<string>('');
    const { token } = theme.useToken();

    const clearStyle: React.CSSProperties = {
        color: hoveringClear ? token.colorTextHeading : token.colorText
    };

    if (props.list.length === 0) {
        return (
            <div className="notFound" style={{ color: token.colorTextSecondary }}>
                <Empty description={'No ' + props.title} />
            </div>
        );
    }

    function getExtra(item: INotification) {
        if (item.status === NotificationStatus.NONE) {
            return null;
        }

        return (
            <div className="extra" style={{ color: token.colorTextSecondary }}>
                <Tag color={statusToColor[item.status]} style={{ marginRight: 0 }}>
                    {item.status.toLowerCase()}
                </Tag>
            </div>
        );
    }

    return (
        <div>
            <List<INotification>
                className="notification-list"
                dataSource={props.list}
                renderItem={(item: INotification) => (
                    <List.Item
                        className={'item' + (item.read ? ' read' : '')}
                        key={item.id}
                        onClick={() => typeof props.onClick === 'function' && props.onClick(item)}
                        onMouseEnter={() => setHoveringItem(item.id)}
                        onMouseLeave={() => hoveringItem === item.id ? setHoveringItem('') : undefined}
                        style={{
                            backgroundColor: hoveringItem === item.id ? token.colorPrimaryBgHover : undefined,
                        }}
                    >
                        <List.Item.Meta
                            className="meta"
                            avatar={<Avatar icon={<NotificationOutlined />} />}
                            title={
                                <div className="title">
                                    {item.title}
                                    {getExtra(item)}
                                </div>
                            }
                            description={
                                <div>
                                    <div className="description">
                                        {item.description}
                                    </div>
                                    <div className="datetime">
                                        <TimeAgo date={item.displayDate} />
                                    </div>
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />

            <div
                className="bottomBar"
                style={{
                    borderTop: `1px solid ${ token.colorBorder }`,
                    borderRadius: `0 0 ${ token.borderRadius } ${ token.borderRadius }`,
                }}
            >
                {props.showClear ?
                    <div
                        onClick={props.onClear}
                        onMouseEnter={() => setHoveringClear(true)}
                        onMouseLeave={() => setHoveringClear(false)}
                        style={clearStyle}
                    >
                        Clear {props.title}
                    </div>
                : null}
            </div>
        </div>
    );
}

import { Action } from 'redux';

import { INotification, NotificationType } from './../../models/notification';
import { IRestError } from '../../models/common/restResult';

export type NotificationsById = { [notificationId: string]: INotification };

export type GlobalNotificationState = {
    fetchedAt: string,
    isFetching: boolean,
    hasError: boolean,
    error?: IRestError,
    notificationsById: NotificationsById;
};

export enum NotificationActionType {
    PURGE = 'notifications/purge',
    EVENT = 'notification/event', //used when we get notified via the server sent events that there is a new notification
    FETCHING_MULTIPLE = 'notifications/fetch',
    FETCHING_MULTIPLE_PENDING = 'notifications/fetch/pending',
    FETCHING_MULTIPLE_SUCCESS = 'notifications/fetch/success',
    FETCHING_MULTIPLE_FAILURE = 'notifications/fetch/failure',
    FETCHING_MULTIPLE_SUCCESS_CACHED = 'notifications/fetch/success/cached',
    FETCHING_ONE = 'notification/fetch',
    FETCHING_ONE_PENDING = 'notification/fetch/pending',
    FETCHING_ONE_SUCCESS = 'notification/fetch/success',
    FETCHING_ONE_FAILURE = 'notification/fetch/failure',
    MARK_AS_READ = 'notification/read',
    MARK_AS_READ_PENDING = 'notification/read/pending',
    MARK_AS_READ_SUCCESS = 'notification/read/success',
    MARK_AS_READ_FAILURE = 'notification/read/failure',
    CLEAR_TYPE = 'notification/clear-type',
    CLEAR_TYPE_PENDING = 'notification/clear-type/pending',
    CLEAR_TYPE_SUCCESS = 'notification/clear-type/success',
    CLEAR_TYPE_FAILURE = 'notification/clear-type/failure',
}

export interface NotificationsPurgeAction extends Action {
    type: NotificationActionType.PURGE,
}

//#region multiple
export interface NotificationsFetchAction extends Action {
    type: NotificationActionType.FETCHING_MULTIPLE,
}

export interface NotificationsFetchPendingAction extends Action {
    type: NotificationActionType.FETCHING_MULTIPLE_PENDING,
}

export interface NotificationsFetchSuccessAction extends Action {
    type: NotificationActionType.FETCHING_MULTIPLE_SUCCESS,
    notifications: INotification[],
}

export interface NotificationsFetchFailureAction extends Action {
    type: NotificationActionType.FETCHING_MULTIPLE_FAILURE,
    error?: IRestError,
}

export interface NotificationsFetchSuccessCacheAction extends Action {
    type: NotificationActionType.FETCHING_MULTIPLE_SUCCESS_CACHED
}
//#endregion

//#region mark as read
export interface NotificationReadAction extends Action {
    type: NotificationActionType.MARK_AS_READ,
}

export interface NotificationReadPendingAction extends Action {
    type: NotificationActionType.MARK_AS_READ_PENDING,
}

export interface NotificationReadSuccessAction extends Action {
    type: NotificationActionType.MARK_AS_READ_SUCCESS,
    notification: INotification,
}

export interface NotificationReadFailureAction extends Action {
    type: NotificationActionType.MARK_AS_READ_FAILURE,
    error?: IRestError,
}
//#endregion mark as read

//#region clear type
export interface NotificationClearTypeAction extends Action {
    type: NotificationActionType.CLEAR_TYPE,
}

export interface NotificationClearTypePendingAction extends Action {
    type: NotificationActionType.CLEAR_TYPE_PENDING,
}

export interface NotificationClearTypeSuccessAction extends Action {
    type: NotificationActionType.CLEAR_TYPE_SUCCESS,
    notificationType: NotificationType,
}

export interface NotificationClearTypeFailureAction extends Action {
    type: NotificationActionType.CLEAR_TYPE_FAILURE,
    error?: IRestError,
}

export interface NotificationEventRecievedAction extends Action {
    type: NotificationActionType.EVENT,
    notification: INotification,
}
//#endregion clear type

export type Notficiations = NotificationsFetchAction | NotificationsFetchPendingAction | NotificationsFetchSuccessAction | NotificationsFetchFailureAction;
export type NotificationRead = NotificationReadAction | NotificationReadPendingAction | NotificationReadSuccessAction | NotificationReadFailureAction;
export type NotificationClearType = NotificationClearTypeAction | NotificationClearTypePendingAction | NotificationClearTypeSuccessAction | NotificationClearTypeFailureAction;

export type NotificationAction = NotificationsFetchAction | NotificationsFetchPendingAction | NotificationsFetchSuccessAction | NotificationsFetchFailureAction |
    NotificationsPurgeAction | NotificationReadAction | NotificationReadPendingAction | NotificationReadSuccessAction | NotificationReadFailureAction |
    NotificationClearTypeAction | NotificationClearTypePendingAction | NotificationClearTypeSuccessAction | NotificationClearTypeFailureAction |
    NotificationEventRecievedAction;

import React, { useCallback, useEffect } from 'react';
import currency from 'currency.js';
import { Modal, Form, Button, InputNumber, Input, Switch } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

import { ITract } from 'models/tract';
import { calcPaymentAmountFor } from 'utils/paymentCalc';

interface IFormValues {
    usePricePerAcre: boolean;
    acres: number;
    pricePerAcre: string;
    salesPrice: string;
    downPayment: string;
    years: number;
    interestRate: string;
    amountFinanced: string;
    paymentAmount: string;
    totalPayments: string;
}

interface ITractFinanceCalcModalProps {
    tract?: ITract;
    isVisible: boolean;
    close: () => void;
}

export const TractFinanceCalcModal: React.FC<ITractFinanceCalcModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();

    const handleFieldChange = useCallback(() => {
        if (!form.getFieldValue('pricePerAcre') || !form.getFieldValue('salesPrice')) {
            return;
        }

        const usePricePerAcre = form.getFieldValue('usePricePerAcre');
        const downPayment = form.getFieldValue('downPayment');

        let salesPrice = currency(form.getFieldValue('salesPrice'));
        if (usePricePerAcre) {
            salesPrice = currency(form.getFieldValue('pricePerAcre'), { precision: 14 }).multiply(form.getFieldValue('acres'));
        }

        console.log('sales price:', salesPrice, 'form value:', form.getFieldValue('salesPrice'));

        const calculatedPaymentAmount = calcPaymentAmountFor(form.getFieldValue('interestRate'), form.getFieldValue('years'), salesPrice, downPayment);
        const amountFinanced = currency(salesPrice, { precision: 2 }).subtract(downPayment);
        const totalPayments = currency(calculatedPaymentAmount).multiply(form.getFieldValue('years')).multiply(12);

        form.setFieldsValue({
            paymentAmount: currency(calculatedPaymentAmount, { precision: 2 }).format(),
            amountFinanced: amountFinanced.format(),
            totalPayments: currency(totalPayments, { precision: 2 }).format(),
        });

        if (usePricePerAcre) {
            //if we are using price per acre, then we adjust the sales price
            form.setFieldsValue({
                salesPrice: currency(salesPrice, { precision: 2 }).format(),
            });
        } else {
            //otherwise, we are using the sales price so we adjust the price per acre
            const pricePerAcre = currency(salesPrice, { precision: 14 }).divide(currency(form.getFieldValue('acres')));

            form.setFieldsValue({
                pricePerAcre: currency(pricePerAcre, { precision: 2 }).format(),
            });
        }
    }, [form]);

    useEffect(() => {
        handleFieldChange();
    }, [props.tract, handleFieldChange]);

    const onCloseClick = () => {
        props.close();
        form.resetFields();
    }

    if (!props.tract) {
        return null;
    }

    return  (
        <Modal
            open={props.isVisible}
            title={<span><CalculatorOutlined /> Loan Calculator for  {props.tract ? props.tract.label : ''}</span>}
            onOk={onCloseClick}
            onCancel={onCloseClick}
            forceRender
            footer={[
                <Button key="submit" type="primary" onClick={onCloseClick}>
                    Close
                </Button>
            ]}
        >
            <Form<IFormValues>
                form={form}
                layout="inline"
                colon={false}
                initialValues={{
                    usePricePerAcre: true,
                    acres: props.tract ? props.tract.acres : 0,
                    pricePerAcre: props.tract ? currency(props.tract.paymentOption.pricePerAcre, { precision: 2 }).format() : '',
                    salesPrice: props.tract ? props.tract.paymentOption.salesPrice : '',
                    downPayment: props.tract ? props.tract.paymentOption.downPayment : '',
                    years: props.tract ? props.tract.paymentOption.years : 30,
                    interestRate: props.tract ? props.tract.paymentOption.interestRate : '',
                }}
            >
                <Form.Item name="usePricePerAcre" valuePropName="checked">
                    <Switch checkedChildren="Adjust Price Per Acre" unCheckedChildren="Adjust Sales Price" />
                </Form.Item>

                <Form.Item name="acres" label="Acres">
                    <InputNumber
                        step={0.1}
                        min={0}
                        onBlur={handleFieldChange}
                    />
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.usePricePerAcre !== curr.usePricePerAcre}>
                    {({ getFieldValue }) => {
                        const usePricePerAcre = getFieldValue('usePricePerAcre');

                        return (
                            <React.Fragment>
                                <Form.Item name="pricePerAcre" label="Price Per Acre">
                                    <Input onBlur={handleFieldChange} prefix="$" disabled={!usePricePerAcre} />
                                </Form.Item>

                                <Form.Item name="salesPrice" label="Sales Price">
                                    <Input onBlur={handleFieldChange} prefix="$" disabled={usePricePerAcre} />
                                </Form.Item>
                            </React.Fragment>
                        );
                    }}
                </Form.Item>


                <Form.Item name="downPayment" label="Down Payment">
                    <Input onBlur={handleFieldChange} prefix="$" />
                </Form.Item>

                <Form.Item name="years" label="Years">
                    <InputNumber
                        min={0}
                        onBlur={handleFieldChange}
                    />
                </Form.Item>

                <Form.Item name="interestRate" label="Interest Rate">
                    <Input onBlur={handleFieldChange} suffix="%" />
                </Form.Item>

                {/* Automatically calculated fields */}
                <Form.Item name="amountFinanced" label="Amount Financed">
                    <Input disabled prefix="$" />
                </Form.Item>
                <Form.Item name="paymentAmount" label="Payment Amount">
                    <Input disabled prefix="$" />
                </Form.Item>
                <Form.Item name="totalPayments" label="Total of Payments">
                    <Input disabled prefix="$" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

import React from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Tooltip, Table, Empty } from 'antd';
import type { CardTabListType } from 'antd/lib/card';
import TimeAgo from 'react-timeago';

import { AccessControlledButton } from 'components/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import type { IClient } from 'models/client';
import type { INote } from 'models/notes';
import type { IMail } from 'models/mail';

import { NotesCard } from 'components/notes';
import { MailCard } from 'components/mail/mailCard';

enum ClientCommunicationTabKeys {
    Notes = 'note',
    Emails = 'email',
    SMSes = 'sms',
    Letters = 'letter',
}

interface ICommunicationAndNotesProps {
    orgId: string;
    client: IClient;
    notes: INote[];
    mails: IMail[];
    onNewMailClick: () => void;
    refresh: () => Promise<void>;
}

interface ICommunicationAndNotesState {
    selectedTab: ClientCommunicationTabKeys;
    isLoading: boolean;
    displayNewNotesModal: boolean;
}

export class ClientCommunicationAndNotes extends React.PureComponent<ICommunicationAndNotesProps, ICommunicationAndNotesState> {
    userChanged = false;

    state: Readonly<ICommunicationAndNotesState> = {
        selectedTab: ClientCommunicationTabKeys.Notes,
        isLoading: false,
        displayNewNotesModal: false,
    }

    tabList: CardTabListType[] = [
        { key: ClientCommunicationTabKeys.Notes, tab: 'Notes' },
        // { key: ClientCommunicationTabKeys.Emails, tab: 'Emails'},
        { key: ClientCommunicationTabKeys.SMSes, tab: 'SMS' },
        { key: ClientCommunicationTabKeys.Letters, tab: 'Letters' },
    ];

    componentDidUpdate() {
        if (this.userChanged) {
            return;
        }

        if (this.props.notes.length !== 0) {
            return;
        }

        if (this.props.mails.length !== 0 && this.state.selectedTab !== ClientCommunicationTabKeys.Letters) {
            this.setState({ selectedTab: ClientCommunicationTabKeys.Letters });
            return;
        }

        this.setState({ selectedTab: ClientCommunicationTabKeys.SMSes });
    }

    onTabSelection = (key: string) => {
        this.userChanged = true;
        this.setState({ selectedTab: key as ClientCommunicationTabKeys });
    }

    generateCommentTimeNode(dateStamp: string) {
        const date = dayjs(dateStamp);

        //Apr 14th, 2023 @ 7:32AM
        return (
            <Tooltip title={date.format('MMM D, YYYY @ h:mma')}>
                <TimeAgo date={date.toDate()} />
            </Tooltip>
        );
    }

    addNewClicked = () => {
        switch (this.state.selectedTab) {
            case ClientCommunicationTabKeys.Notes:
                this.setState({ displayNewNotesModal: true });
                return;
            case ClientCommunicationTabKeys.Emails:
            case ClientCommunicationTabKeys.SMSes:
                return;
            case ClientCommunicationTabKeys.Letters:
                this.props.onNewMailClick();
                return;
        }
    }

    refreshData = async () => {
        this.setState({ isLoading: true });

        await this.props.refresh();

        this.setState({ isLoading: false, displayNewNotesModal: false });
    }

    newNoteModalStatusUpdate = (open: boolean) => {
        this.setState({ displayNewNotesModal: open });
    }

    get addNewButton() {
        let feature = PermissionFeature.Client;
        let btnText = 'Add New';

        switch (this.state.selectedTab) {
            case ClientCommunicationTabKeys.Notes:
                btnText = 'Add Note';
                feature = PermissionFeature.ClientNotes;
                break;
            case ClientCommunicationTabKeys.Emails:
                btnText = 'Send Email';
                break;
            case ClientCommunicationTabKeys.SMSes:
                return null;
            case ClientCommunicationTabKeys.Letters:
                btnText = 'Send Letter';
                feature = PermissionFeature.ClientFiles;
                break;
        }

        return (
            <AccessControlledButton
                type="dashed"
                size="small"
                onClick={this.addNewClicked}
                icon={<PlusOutlined />}
                disabled={this.state.selectedTab === ClientCommunicationTabKeys.Emails}
                feature={feature}
                action={PermissionAction.Create}
                prevent="tooltip"
            >
                {btnText}
            </AccessControlledButton>
        );
    }

    get notesTab() {
        return (
            <NotesCard
                orgId={this.props.orgId}
                client={this.props.client}
                notes={this.props.notes}
                isLoading={this.state.isLoading}
                refresh={this.refreshData}
                displayCreateModal={this.state.displayNewNotesModal}
                modalStatusUpdate={this.newNoteModalStatusUpdate}
                simpleList
            />
        );
    }

    get emailsTab() {
        return (
            <Table
                locale={{ emptyText: <Empty description={`No emails have been sent to ${this.props.client.displayName} yet.`} /> }}
                pagination={{ hideOnSinglePage: true }}
            />
        );
    }

    get smsTab() {
        const link = `/${this.props.orgId}/communications?id=${this.props.client.id}-${this.props.client.primaryEntity.id}`;

        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Text messages have moved to the new Communication feature."
            >
                <AccessControlledButton
                    feature={PermissionFeature.Communication}
                    action={PermissionAction.Read}
                    prevent="tooltip"
                    href={link}
                >
                    Go to Communication
                </AccessControlledButton>
            </Empty>
        );
    }

    get lettersTab() {
        return (
            <MailCard client={this.props.client} mail={this.props.mails} simpleList />
        );
    }

    get selectedTab() {
        switch (this.state.selectedTab) {
            case ClientCommunicationTabKeys.Notes:
                return this.notesTab;
            case ClientCommunicationTabKeys.Emails:
                return this.emailsTab;
            case ClientCommunicationTabKeys.SMSes:
                return this.smsTab;
            case ClientCommunicationTabKeys.Letters:
                return this.lettersTab;
            default:
                return null;
        }
    }

    render() {
        return (
            <Card
                bordered={false}
                tabList={this.tabList}
                onTabChange={this.onTabSelection}
                activeTabKey={this.state.selectedTab}
                tabBarExtraContent={this.addNewButton}
            >
                {this.selectedTab}
            </Card>
        );
    }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';

import { OrganizationBillingStatus } from 'models/organization';
import { SimpleDate } from 'utils/formatting';

import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';

export const OrganizationStatusAlert: React.FC = () => {
    const org = useSelector(getSelectedOrg);
    const canCreate = useSelector(isSelectedOrgAllowedToCreate);

    if (!org || (canCreate && !org.billing.cancelsAtEndOfPeriod && org.billing.status !== OrganizationBillingStatus.Trialing)) {
        return null;
    }

    const s = org.billing.status.replace('_', ' ');
    const cancelsAt = new Date(org.billing.cancelsAt);

    return (
        <div className="action org-status">
            { !canCreate ? <Alert type="error" showIcon message={`${ org.name }'s subscription is ${ s }; creation of content is disabled.`} /> : null }
            { !org.billing.cancelsAtEndOfPeriod && org.billing.status === OrganizationBillingStatus.Trialing ? <Alert type="info" showIcon message={<React.Fragment>{ org.name } trial is currently active and ends on <SimpleDate date={org.billing.periodEndsAt} /></React.Fragment>} /> : null }
            { org.billing.cancelsAtEndOfPeriod ? <Alert type="warning" showIcon message={`${ org.name }'s subscription cancels on ${ cancelsAt.toLocaleDateString() } at ${ cancelsAt.toLocaleTimeString() }.`} /> : null }
        </div>
    );
}

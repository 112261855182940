import { IPagination } from './pagination';

export interface IRestResult<T> {
    data: Array<T>;
    pagination?: IPagination;
}

export interface IRestError {
    status: number;
    code: number;
    error: string;
    requestId: string;
}

export class UnauthorizedError extends Error {
    constructor() {
        super("Are you logged in? The server returned an unauthorized response");
    }
}

export class ForbiddenError extends Error {}

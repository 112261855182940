import React, { useRef } from 'react';
import type { InputRef } from 'antd';
import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';

interface ITableSearchProps extends FilterDropdownProps {
    placeholder: string;
}

export const TableSearchInput: React.FC<ITableSearchProps> = (props) => {
    const inputRef = useRef<InputRef>(null);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setSelectedKeys(e?.target?.value ? [e.target.value] : []);
    };

    const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        props.confirm();
    }

    const confirm = () => {
        props.confirm();
    };

    const reset = () => {
        if (typeof props.clearFilters !== 'function') {
            return;
        }

        props.clearFilters();
        inputRef.current?.focus();
    };

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={props.placeholder}
                value={props.selectedKeys[0]}
                onChange={onInputChange}
                onPressEnter={onPressEnter}
                autoFocus
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={reset}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={confirm}
                >
                    close
                </Button>
            </Space>
        </div>
    );
}

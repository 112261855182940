import { GlobalState } from 'store';
import { ITract } from 'models/tract';
import { TractsByInventoryId } from '../types/tracts';

export const isLoadingTracts = (state: GlobalState) => state.tracts.isFetching;
export const getTracts = (state: GlobalState): TractsByInventoryId => state.tracts.tractsByInventoryId;
export const getAllTracts = (state: GlobalState): ITract[] => Object.keys(state.tracts.tractsByInventoryId).map((id) => Object.values(state.tracts.tractsByInventoryId[id])).flat();

export const getTractsForInventory = (state: GlobalState, inventoryId: string): ITract[] => {
    if (!state.tracts.tractsByInventoryId[inventoryId]) {
        return [];
    }

    return Object.keys(state.tracts.tractsByInventoryId[inventoryId]).map((id: string) => state.tracts.tractsByInventoryId[inventoryId][id]);
};

export const getTractById = (state: GlobalState, inventoryId: string, tractId: string): ITract | undefined => {
    if (!state.tracts.tractsByInventoryId[inventoryId]) {
        return undefined;
    }

    return state.tracts.tractsByInventoryId[inventoryId][tractId];
};

import React from 'react';
import debounce from 'lodash.debounce';
import { AutoComplete, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { LabelInValueType, RawValueType } from 'rc-select/lib/Select';

import { IClient } from 'models/client';
import { getClients, getClientByID } from 'api/clients';

import { QuickClientCreateModal } from './quickClientCreate';

export interface IClientAutoCompleteValue {
    id: string;
    displayName?: string;
}

interface IClientAutoCompleteProps {
    disabled: boolean;
    orgId: string;
    value?: IClientAutoCompleteValue;
    onChange?: (value: IClientAutoCompleteValue) => void;
}

interface IClientAutoCompleteState {
    isSearching: boolean;
    isSelecting: boolean;
    value: IClientAutoCompleteValue;
    clients: IClient[];
}

export class ClientAutoComplete extends React.PureComponent<IClientAutoCompleteProps, IClientAutoCompleteState> {
    static getDerivedStateFromProps(props: IClientAutoCompleteProps, state: IClientAutoCompleteState): Partial<IClientAutoCompleteState> | null {
        if (props.value && props.value.id !== state.value.id && !state.isSearching) {
            return { value: props.value };
        }

        return null;
    }

    state: Readonly<IClientAutoCompleteState> = {
        isSearching: false,
        isSelecting: false,
        value: { id: '', displayName: '' },
        clients: [],
    }

    async componentDidMount() {
        if (this.props.value && this.props.value.id) {
            const client = await getClientByID(this.props.orgId, this.props.value.id);

            this.setState({ value: this.generateValue(client) });
        }
    }

    onSelect = (selectedValue: RawValueType | LabelInValueType): void => {
        if (!selectedValue) {
            return;
        }

        this.setState({ isSelecting: true }, async () => {
            const client = await getClientByID(this.props.orgId, selectedValue.toString());
            const value = this.generateValue(client);

            if (this.props.onChange) {
                this.props.onChange(value);
            }

            this.setState({
                value,
                isSelecting: false,
                isSearching: false,
                clients: [],
            });
        });
    }

    private generateValue(client: IClient): IClientAutoCompleteValue {
        return {
            id: client.id,
            displayName: `${ client.displayName } (${ client.entities.map((e) => e.label).join(', ') })`,
        }
    }

    searchFor = debounce(async (term: string) => {
        const result = await getClients(this.props.orgId, { search: term.trim() });

        this.setState({ isSearching: false, clients: result.data });
    }, 200);

    onSearch = async (searchValue: string): Promise<void> => {
        let clients = this.state.clients;
        if (searchValue) {
            this.searchFor(searchValue);
        } else {
            clients = [];
        }

        const value: IClientAutoCompleteValue = { id: '', displayName: searchValue };

        if (this.props.onChange) {
            this.props.onChange(value);
        }

        this.setState({
            value,
            isSearching: searchValue !== '',
            clients,
        });
    }

    onQuickCreate = (client: IClient) => {
        const value = this.generateValue(client);

        if (this.props.onChange) {
            this.props.onChange(value);
        }

        this.setState({
            value,
            isSelecting: false,
            isSearching: false,
            clients: [],
        });
    }

    render() {
        return (
            <Space.Compact block>
                <AutoComplete
                    onSelect={this.onSelect}
                    onSearch={this.onSearch}
                    value={this.state.value.displayName}
                    placeholder="Search for owner"
                    style={{width: '84%'}}
                    disabled={this.state.isSelecting || this.props.disabled}
                    allowClear
                >
                    {this.state.clients.map((client) => {
                        return (
                            <AutoComplete.Option key={client.id}>
                                <strong>{client.displayName}</strong><br />
                                <em>({client.entities.map((e) => e.label).join(', ')})</em>

                                <span style={{ position: 'absolute', right: '16px', top: '16px' }}><ArrowRightOutlined /></span>
                            </AutoComplete.Option>
                        );
                    })}
                </AutoComplete>
                <QuickClientCreateModal orgId={this.props.orgId} onCreated={this.onQuickCreate} disabled={this.state.isSelecting || this.props.disabled} />
            </Space.Compact>
        );
    }
}

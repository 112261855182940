import { Action } from 'redux';

import { IOrganization } from 'models/organization';
import { IRestError } from 'models/common/restResult';
import { IOrganizationCreationPayload } from 'models/payloads/organization';

export type OrganizationById = { [orgId: string]: IOrganization };

export type GlobalOrgState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    selectedOrgShortId: string;
    selectedOrgLongId: string;
    orgsByShortId: OrganizationById;
    orgsByLongId: OrganizationById;
    isCreating: boolean;
    createdId: string;
    connectedStripeAccount: string | undefined;
};

export enum OrgActionType {
    SELECT = 'org/select',
    SELECT_PENDING = 'org/select/pending',
    SELECT_SUCCESS = 'org/select/success',
    SELECT_FAILURE = 'org/select/failure',
    DESELECT = 'org/deselect',
    CREATE = 'org/create',
    CREATE_PENDING = 'org/create/pending',
    CREATE_PAYMENT_METHOD_PENDING = 'org/create/payment-method/pending',
    CREATE_SUCCESS = 'org/create/success',
    CREATE_FAILURE = 'org/create/failure',
    CREATE_CLEAR = 'org/create/clear',
    REFRESH = 'org/refresh',
    REFRESH_FAILURE = 'org/refresh/failure',
    EVENT_CREATED = 'org/event/created',
    EVENT_UPDATED = 'org/event/updated',
    EVENT_DELETED = 'org/event/deleted',
    SET_STRIPEACCOUNT = 'org/stripe/set',
    UNSET_STRIPEACCOUNT = 'org/stripe/unset',
}

//#region org select
export interface OrgSelectAction extends Action {
    type: OrgActionType.SELECT,
}

export interface OrgSelectPendingAction extends Action {
    type: OrgActionType.SELECT_PENDING,
}

export interface OrgSelectSuccessAction extends Action {
    type: OrgActionType.SELECT_SUCCESS,
    org: IOrganization,
}

export interface OrgSelectFailureAction extends Action {
    type: OrgActionType.SELECT_FAILURE,
    error?: IRestError,
}
//#endregion

export interface OrgDeselectAction extends Action {
    type: OrgActionType.DESELECT,
}

//#region org create
export interface OrgCreateAction extends Action {
    type: OrgActionType.CREATE,
    payload: IOrganizationCreationPayload,
}

export interface OrgCreatePendingAction extends Action {
    type: OrgActionType.CREATE_PENDING,
}

export interface OrgCreatePaymentMethodPendingAction extends Action {
    type: OrgActionType.CREATE_PAYMENT_METHOD_PENDING,
}

export interface OrgCreateSuccessAction extends Action {
    type: OrgActionType.CREATE_SUCCESS,
    org: IOrganization,
}

export interface OrgCreateFailureAction extends Action {
    type: OrgActionType.CREATE_FAILURE,
    error?: Partial<IRestError>,
}

export interface OrgCreateClearAction extends Action {
    type: OrgActionType.CREATE_CLEAR,
}
//#endregion

export interface OrgRefreshAction extends Action {
    type: OrgActionType.REFRESH,
    org: IOrganization,
}

export interface OrgRefreshFailureAction extends Action {
    type: OrgActionType.REFRESH_FAILURE,
    error?: IRestError,
}

export interface OrgCreatedEventRecievedAction extends Action {
    type: OrgActionType.EVENT_CREATED,
    org: IOrganization,
}

export interface OrgUpdatedEventRecievedAction extends Action {
    type: OrgActionType.EVENT_UPDATED,
    org: IOrganization,
}

export interface OrgDeletedEventRecievedAction extends Action {
    type: OrgActionType.EVENT_DELETED,
    lookup: Partial<IOrganization>,
}

export interface OrgSetStripeAccount extends Action {
    type: OrgActionType.SET_STRIPEACCOUNT;
    accountId: string;
}

export interface OrgUnsetStripeAccount extends Action {
    type: OrgActionType.UNSET_STRIPEACCOUNT;
}

export type OrgCreate = OrgCreateAction | OrgCreatePendingAction | OrgCreatePaymentMethodPendingAction | OrgCreateSuccessAction | OrgCreateFailureAction;
export type OrgRefresh = OrgRefreshAction | OrgRefreshFailureAction;
export type OrgEventAction = OrgCreatedEventRecievedAction | OrgUpdatedEventRecievedAction | OrgDeletedEventRecievedAction;

export type OrgAction = OrgSelectAction | OrgSelectPendingAction | OrgSelectSuccessAction | OrgSelectFailureAction | OrgDeselectAction | OrgCreate | OrgCreateClearAction | OrgRefresh | OrgEventAction | OrgSetStripeAccount | OrgUnsetStripeAccount;

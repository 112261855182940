import { Action } from 'redux';

import { IRestError } from '../../models/common/restResult';
import { IUser } from '../../models/user';
import { IUserRegistrationPayload, IUserRegistrationResult } from '../../models/payloads/userProfile';
import { IUserLoginResult } from '../../models/payloads/userLogin';

export type GlobalAuthState = {
    isLoggingIn: boolean;
    hasError: boolean;
    error?: IRestError;
    token: string;
    tokenExpiresAt: string;
    userId: string;
    user?: IUser;
};

export enum AuthActionType {
    LOGIN = 'auth/login',
    LOGIN_PENDING = 'auth/login/pending',
    LOGIN_AUTHORIZED = 'auth/login/authorized',
    LOGIN_SUCCESS = 'auth/login/success',
    LOGIN_FAILURE = 'auth/login/failure',
    LOGOUT = 'auth/logout',
    REGISTER = 'auth/register',
    REGISTER_PENDING = 'auth/register/pending',
    REGISTER_SUCCESS = 'auth/register/success',
    REGISTER_FAILURE = 'auth/register/failure',
    REFRESH_USER = 'auth/user/refresh',
    REFRESH_USER_FAILURE = 'auth/user/refresh/failure',
    RESET_PASSWORD = 'auth/user/reset',
    RESET_PASSWORD_PENDING = 'auth/user/reset/pending',
    RESET_PASSWORD_SUCCESS = 'auth/user/reset/success',
    RESET_PASSWORD_FAILURE = 'auth/user/reset/failure',
}

//#region login
export interface AuthLoginAction extends Action {
    type: AuthActionType.LOGIN,
}

export interface AuthLoginPendingAction extends Action {
    type: AuthActionType.LOGIN_PENDING,
}

export interface AuthLoginAuthorizedAction extends Action {
    type: AuthActionType.LOGIN_AUTHORIZED,
    result: IUserLoginResult,
    expiresAt: Date,
}

export interface AuthLoginSuccessAction extends Action {
    type: AuthActionType.LOGIN_SUCCESS,
    user: IUser,
}

export interface AuthLoginFailureAction extends Action {
    type: AuthActionType.LOGIN_FAILURE,
    error?: IRestError,
}
//#endregion

export interface AuthLogoutAction extends Action {
    type: AuthActionType.LOGOUT,
}

//#region register
export interface AuthRegisterAction extends Action {
    type: AuthActionType.REGISTER,
    payload: IUserRegistrationPayload,
}

export interface AuthRegisterPendingAction extends Action {
    type: AuthActionType.REGISTER_PENDING,
}

export interface AuthRegisterSuccessAction extends Action {
    type: AuthActionType.REGISTER_SUCCESS,
    result: IUserRegistrationResult,
    tokenExpiresAt: Date,
}

export interface AuthRegisterFailureAction extends Action {
    type: AuthActionType.REGISTER_FAILURE,
    error?: IRestError,
}
//#endregion register

export interface AuthRefreshUserAction extends Action {
    type: AuthActionType.REFRESH_USER,
    user: IUser,
}

export interface AuthRefreshUserFailureAction extends Action {
    type: AuthActionType.REFRESH_USER_FAILURE,
    error?: IRestError,
}

//#region reset password
export interface AuthResetUserPasswordAction extends Action {
    type: AuthActionType.RESET_PASSWORD,
}

export interface AuthResetUserPasswordPendingAction extends Action {
    type: AuthActionType.RESET_PASSWORD_PENDING,
}

export interface AuthResetUserPasswordSuccessAction extends Action {
    type: AuthActionType.RESET_PASSWORD_SUCCESS,
    result: IUserLoginResult,
    tokenExpiresAt: Date,
    user: IUser,
}

export interface AuthResetUserPasswordFailureAction extends Action {
    type: AuthActionType.RESET_PASSWORD_FAILURE,
    error?: IRestError,
}
//#endregion reset password

export type AuthLogin = AuthLoginAction | AuthLoginPendingAction | AuthLoginAuthorizedAction | AuthLoginSuccessAction | AuthLoginFailureAction;
export type AuthRegister = AuthRegisterAction | AuthRegisterPendingAction | AuthRegisterSuccessAction | AuthRegisterFailureAction;
export type AuthResetPassword = AuthResetUserPasswordAction | AuthResetUserPasswordPendingAction | AuthResetUserPasswordSuccessAction | AuthResetUserPasswordFailureAction;

export type AuthAction = AuthLoginAction | AuthLoginPendingAction | AuthLoginAuthorizedAction | AuthLoginSuccessAction | AuthLoginFailureAction | AuthLogoutAction | AuthRegisterAction | AuthRegisterPendingAction | AuthRegisterSuccessAction | AuthRegisterFailureAction | AuthRefreshUserAction | AuthRefreshUserFailureAction | AuthResetUserPasswordAction | AuthResetUserPasswordPendingAction | AuthResetUserPasswordSuccessAction | AuthResetUserPasswordFailureAction;

import { del, get, post, put } from './index';

import type { IUser } from 'models/user';
import type { IMember } from 'models/member';
import type { IMemberInvitePayload, IMemberInviteResult, IMemberAcceptInvitePayload, IMemberUpdateRolePayload } from './../models/payloads/members';

export const getMembers = (orgId: string) => get<IMember[]>(`/organizations/${ orgId }/members`, true);
export const inviteMember = (orgId: string, payload: IMemberInvitePayload) => post<IMemberInvitePayload, IMemberInviteResult>(`/organizations/${ orgId }/members`, payload, true);
export const updateMember = (orgId: string, payload: IMemberUpdateRolePayload) => put<IMemberUpdateRolePayload, void>(`/organizations/${ orgId }/members`, payload, true);
export const removeMember = (orgId: string, memberId: string) => del(`/organizations/${ orgId }/members/${ memberId }`, true);
export const getMemberInvite = (inviteCode: string, email: string) => get<{ member: IMember, user: IUser }>(`/user/invite?code=${ encodeURIComponent(inviteCode) }&email=${ encodeURIComponent(email) }`, false);
export const acceptMemberInvite = (payload: IMemberAcceptInvitePayload) => post<IMemberAcceptInvitePayload, void>('/user/invite', payload, true);

export const registerUmamiScript = (umamiScriptUrl: string, websiteId: string) => {
    if (umamiScriptUrl && websiteId) {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = umamiScriptUrl;
        script.async = true;
        script.defer = true;
        script.setAttribute('data-auto-track', 'false');
        script.setAttribute('data-website-id', websiteId);
        head.appendChild(script);
    }
}

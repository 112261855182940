import { GlobalAuthState, AuthActionType, AuthAction } from '../types/auth';

const initialAuthState: GlobalAuthState = {
    isLoggingIn: false,
    hasError: false,
    token: '',
    tokenExpiresAt: '',
    userId: '',
};

export function authReducer(state = initialAuthState, action: AuthAction): GlobalAuthState {
    switch (action.type) {
        case AuthActionType.LOGIN_PENDING:
        case AuthActionType.REGISTER_PENDING:
        case AuthActionType.RESET_PASSWORD_PENDING:
            return { ...state, isLoggingIn: true, user: undefined, userId: '', hasError: false, error: undefined };
        case AuthActionType.LOGIN_AUTHORIZED:
            return { ...state, userId: action.result.userId, token: action.result.token, tokenExpiresAt: action.expiresAt.toJSON() };
        case AuthActionType.LOGIN_SUCCESS:
        case AuthActionType.RESET_PASSWORD_SUCCESS:
            return { ...state, isLoggingIn: false, hasError: false, error: undefined, user: action.user };
        case AuthActionType.LOGIN_FAILURE:
        case AuthActionType.REGISTER_FAILURE:
        case AuthActionType.REFRESH_USER_FAILURE:
        case AuthActionType.RESET_PASSWORD_FAILURE:
            return { ...state, isLoggingIn: false, userId: '', user: undefined, hasError: true, error: action.error };
        case AuthActionType.LOGOUT:
            return { ...state, isLoggingIn: false, hasError: false, error: undefined, token: '', tokenExpiresAt: '', userId: '', user: undefined };
        case AuthActionType.REGISTER_SUCCESS:
            return { ...state, isLoggingIn: false, hasError: false, error: undefined, userId: action.result.user.id, token: action.result.token, tokenExpiresAt: action.tokenExpiresAt.toJSON(), user: action.result.user };
        case AuthActionType.REFRESH_USER:
                return { ...state, isLoggingIn: true, user: action.user, userId: action.user.id };
        default:
            return state;
    }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { Loading } from 'components/misc/loading';

import { useThunkDispatch } from 'store';
import { getUser } from 'store/selectors/auth';
import { orgSelect } from 'store/actions/org';

export const OrgSelectView: React.FC = () => {
    const user = useSelector(getUser);
    const dispatch = useThunkDispatch();
    const history = useHistory();

    if (!user || !user.organizations || !Array.isArray(user.organizations)) {
        return null;
    }

    if (user.organizations.length === 1) {
        const shortId = user.organizations[0].shortId!;

        setTimeout(() => history.push(`/${ shortId }/dashboard`), 1000);

        return (
            <Loading />
        );
    }

    const onOrgClick = async (orgShortId: string) => {
        await dispatch(orgSelect(orgShortId) as any);

        history.push(`/${ orgShortId }/dashboard`);
    };

    return (
        <div className="org-select-page">
            <Typography.Title level={1}>Hello, { user.firstName }!</Typography.Title>
            <Typography.Title level={2}>Please select an organization to continue.</Typography.Title>

            { user.organizations.map((o) => (
                <Button
                    size="large"
                    onClick={() => onOrgClick(o.shortId!)}
                    key={o.id}
                    style={{
                        marginRight: 16,
                        marginBottom: 16,
                    }}
                >{ o.label } <ArrowRightOutlined /></Button>
            ))}
        </div>
    );
}

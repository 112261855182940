import React from 'react';
import { Tag } from 'antd';

import { ClientStatus } from 'models/client';

interface IClientStatusTagProps {
    status: ClientStatus
};

export class ClientStatusTag extends React.PureComponent<IClientStatusTagProps> {
    getTagColor() {
        switch(this.props.status) {
            case ClientStatus.Active:
                return 'green';
            case ClientStatus.DoNotContact:
            case ClientStatus.Inactive:
                return 'red';
            case ClientStatus.Prospect:
                return 'gold';
            default:
                return 'purple';
        }
    }

    getTagLabel() {
        switch(this.props.status) {
            case ClientStatus.Active:
                return 'Active';
            case ClientStatus.Inactive:
                return 'Inactive';
            case ClientStatus.Prospect:
                return 'Prospect';
            case ClientStatus.DoNotContact:
                return 'Do Not Contact';
            default:
                return 'Unknown';
        }
    }

    render() {
        return (
            <Tag color={this.getTagColor()}>{this.getTagLabel()}</Tag>
        );
    }
}

import React from 'react';
import { Button, Empty, List, Modal, Spin } from 'antd';

import { FillablePDFFor, IFillablePDF } from 'models/fillablePDFs';
import { executeFillablePDF, getFillablePDFs } from 'api/fillablePDFs';

import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

interface IFillablePDFListModalProps {
    isVisible: boolean;
    orgId: string;
    for: FillablePDFFor;
    forId: string;
    close: () => void;
}

interface IFillablePDFListModalState {
    isLoading: boolean;
    hasLoaded: boolean;
    pdfs: IFillablePDF[];
    processing: { [key: string]: boolean };
}

export class FillablePDFListModal extends React.PureComponent<IFillablePDFListModalProps, IFillablePDFListModalState> {
    state: Readonly<IFillablePDFListModalState> = {
        isLoading: true,
        hasLoaded: false,
        pdfs: [],
        processing: {},
    };

    onClose = () => {
        this.props.close();
        this.setState({ hasLoaded: false });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData = () => {
        if (!this.props.isVisible || this.state.hasLoaded || this.state.pdfs.length > 0) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            try {
                const data = await getFillablePDFs(this.props.orgId, this.props.for);

                this.setState({ pdfs: data, isLoading: false, hasLoaded: true });
            } catch (e) {
                displayErrorNotification(e);
            }
        });
    }

    getExecuteOnClick = (pdf: IFillablePDF) => {
        return async () => {
            this.setState({
                processing: {
                    ...this.state.processing,
                    [pdf.id]: true,
                },
            });

            const result = await executeFillablePDF(this.props.orgId, pdf.id, {
                for: this.props.for,
                forId: this.props.forId,
            });

            trackUmami('Execute Fillable PDF');

            window.open(result.url, '_blank');

            this.setState({
                processing: {
                    ...this.state.processing,
                    [pdf.id]: false,
                },
            });
        };
    }

    listItemRender = (pdf: IFillablePDF) => {
        const processing = this.state.processing[pdf.id];

        const button = (
            <Button
                type="link"
                size="small"
                disabled={processing}
                loading={processing}
                onClick={this.getExecuteOnClick(pdf)}
            >{ processing ? 'Processing' : 'Execute' }</Button>
        );

        return (
            <List.Item key={pdf.id} actions={[button]}>
                <List.Item.Meta
                    title={pdf.name}
                    description={pdf.description ? pdf.description : '-'}
                />
            </List.Item>
        );
    }

    get empty() {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No fillable PDFs found for ${ this.props.for.replace('-', ' ')}s`}
            />
        );
    }

    render() {
        return (
            <Modal
                title={`Fillable PDFs for ${ this.props.for.replace('-', ' ')}s`}
                open={this.props.isVisible}
                footer={
                    <Button type="primary" disabled={this.state.isLoading} onClick={this.onClose}>
                        Close
                    </Button>
                }
            >
                <Spin spinning={this.state.isLoading}>
                    <List<IFillablePDF>
                        size="small"
                        bordered
                        dataSource={this.state.pdfs}
                        renderItem={this.listItemRender}
                        loading={this.state.isLoading}
                        locale={{ emptyText: this.empty }}
                    />
                </Spin>
            </Modal>
        );
    }
}

import { ThunkAction } from 'redux-thunk';
import decoder from 'jwt-decode';

import { GlobalState } from '../';

import { IRestError } from 'models/common/restResult';
import { IUser, JwtToken } from 'models/user';
import { loginUser, getUserLoggedIn, registerUser, resetPassword } from 'api/auth';
import { storeToken, revokeToken } from 'utils/auth';

import {
    AuthActionType, AuthLoginSuccessAction, AuthLoginFailureAction,
    AuthLoginPendingAction, AuthLoginAuthorizedAction, AuthLogoutAction,
    AuthRegister, AuthRegisterPendingAction, AuthRegisterFailureAction,
    AuthRegisterSuccessAction, AuthRefreshUserAction, AuthRefreshUserFailureAction, AuthResetPassword, AuthResetUserPasswordPendingAction, AuthResetUserPasswordFailureAction, AuthResetUserPasswordSuccessAction,
} from 'store/types/auth';

import { IUserRegistrationPayload, IUserRegistrationResult } from 'models/payloads/userProfile';
import { IUserLoginPayload, IUserLoginResult, IUserResetPasswordPayload } from 'models/payloads/userLogin';
import { OrgDeselectAction, OrgActionType } from 'store/types/org';
import { InventoryActionType, InventoriesPurgeAction } from 'store/types/inventories';
import { NotificationActionType, NotificationsPurgeAction } from 'store/types/notifications';
import { TractActionType, TractsPurgeAction } from 'store/types/tracts';
import { PermissionActionType, PermissionsPurgeAction } from 'store/types/permissions';

import { permissionsLoad } from './permissions';

export const authLogin = (payload: IUserLoginPayload): ThunkAction<Promise<AuthLoginSuccessAction | AuthLoginFailureAction>, GlobalState, null, AuthLoginPendingAction | AuthLoginAuthorizedAction | AuthLoginSuccessAction | AuthLoginFailureAction> => {
    return async (dispatch) => {
        dispatch(authLoginPending());

        try {
            const result = await loginUser(payload);

            const jwt = decoder<JwtToken>(result.token);
            const expiresAt = new Date(jwt.exp * 1000);
            if (new Date().getTime() > expiresAt.getTime()) {
                return dispatch(authLoginFailure({ error: 'the token to store has expired?!!', code: 888 } as IRestError))
            }

            storeToken(result.token);

            dispatch(authLoginAuthorized(result, expiresAt));

            const user = await getUserLoggedIn();

            await dispatch(permissionsLoad());
            return dispatch(authLoginSuccess(user));
        } catch (e) {
            return dispatch(authLoginFailure(e as IRestError));
        }
    }
}

export const authLogout = (): ThunkAction<Promise<InventoriesPurgeAction>, GlobalState, null, AuthLogoutAction | NotificationsPurgeAction | OrgDeselectAction | TractsPurgeAction | PermissionsPurgeAction | InventoriesPurgeAction> => {
    return async (dispatch) => {
        dispatch(authLogoutAction());
        dispatch({ type: NotificationActionType.PURGE });
        dispatch({ type: OrgActionType.DESELECT });
        dispatch({ type: TractActionType.PURGE });
        dispatch({ type: PermissionActionType.PURGE });
        return dispatch({ type: InventoryActionType.PURGE });
    }
}

export const authRegister = (payload: IUserRegistrationPayload): ThunkAction<Promise<AuthRegister>, GlobalState, null, AuthRegister> => {
    return async (dispatch) => {
        dispatch(authRegisterPending());

        try {
            const result = await registerUser(payload);

            const jwt = decoder<JwtToken>(result.token);
            const expiresAt = new Date(jwt.exp * 1000);
            if (new Date().getTime() > expiresAt.getTime()) {
                return dispatch(authRegisterFailure({ error: 'the token to store has expired?!!', code: 888 } as IRestError))
            }

            storeToken(result.token);

            return dispatch(authRegisterSuccess(result, expiresAt));
        } catch (e) {
            return dispatch(authRegisterFailure(e as IRestError));
        }
    }
}

export const authResetPassword = (payload: IUserResetPasswordPayload): ThunkAction<Promise<AuthResetPassword | AuthLoginAuthorizedAction>, GlobalState, null, AuthResetPassword | AuthLoginAuthorizedAction> => {
    return async (dispatch) => {
        dispatch(authResetPasswordPending());

        try {
            const result = await resetPassword(payload);

            const jwt = decoder<JwtToken>(result.token);
            const expiresAt = new Date(jwt.exp * 1000);
            if (new Date().getTime() > expiresAt.getTime()) {
                return dispatch(authResetPasswordFailure({ error: 'the token to store has expired already?!!', code: 888 } as IRestError))
            }

            storeToken(result.token);

            dispatch(authLoginAuthorized(result, expiresAt));

            const user = await getUserLoggedIn();

            return dispatch(authResetPasswordSuccess(result, user, expiresAt));
        } catch (e) {
            return dispatch(authResetPasswordFailure(e as IRestError));
        }
    }
}

export const authRefreshUser = (): ThunkAction<Promise<AuthRefreshUserAction | AuthRefreshUserFailureAction>, GlobalState, null, AuthRefreshUserAction | AuthRefreshUserFailureAction> => {
    return async (dispatch) => {
        try {
            const user = await getUserLoggedIn();

            await dispatch(permissionsLoad());

            return dispatch({ type: AuthActionType.REFRESH_USER, user });
        } catch (e) {
            return dispatch({ type: AuthActionType.REFRESH_USER_FAILURE, error: e as IRestError });
        }
    }
}

export function authLoginPending(): AuthLoginPendingAction {
    return {
        type: AuthActionType.LOGIN_PENDING,
    };
}

export function authLoginAuthorized(result: IUserLoginResult, expiresAt: Date): AuthLoginAuthorizedAction {
    return {
        type: AuthActionType.LOGIN_AUTHORIZED,
        result,
        expiresAt,
    };
}

export function authLoginSuccess(user: IUser): AuthLoginSuccessAction {
    return {
        type: AuthActionType.LOGIN_SUCCESS,
        user,
    }
}

export function authLoginFailure(error?: IRestError): AuthLoginFailureAction {
    return {
        type: AuthActionType.LOGIN_FAILURE,
        error,
    };
}

export function authLogoutAction(): AuthLogoutAction {
    revokeToken();

    return {
        type: AuthActionType.LOGOUT,
    };
}

export function authRegisterPending(): AuthRegisterPendingAction {
    return {
        type: AuthActionType.REGISTER_PENDING,
    };
}

export function authRegisterFailure(error?: IRestError): AuthRegisterFailureAction {
    return {
        type: AuthActionType.REGISTER_FAILURE,
        error,
    };
}

export function authRegisterSuccess(result: IUserRegistrationResult, tokenExpiresAt: Date): AuthRegisterSuccessAction {
    return {
        type: AuthActionType.REGISTER_SUCCESS,
        result,
        tokenExpiresAt,
    }
}

export function authResetPasswordPending(): AuthResetUserPasswordPendingAction {
    return {
        type: AuthActionType.RESET_PASSWORD_PENDING,
    }
}

export function authResetPasswordSuccess(result: IUserLoginResult, user: IUser, tokenExpiresAt: Date): AuthResetUserPasswordSuccessAction {
    return {
        type: AuthActionType.RESET_PASSWORD_SUCCESS,
        result,
        user,
        tokenExpiresAt,
    }
}

export function authResetPasswordFailure(error?: IRestError): AuthResetUserPasswordFailureAction {
    return {
        type: AuthActionType.RESET_PASSWORD_FAILURE,
        error,
    };
}

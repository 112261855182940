import React, { useState } from 'react';
import { Modal, notification, Typography } from 'antd';

import { IClient } from 'models/client';
import { deleteClientByID } from 'api/clients';
import { displayErrorNotification } from 'utils/errors';

interface IDeleteClientModalProps {
    orgId: string;
    client?: IClient;
    visible: boolean;
    close: (deleted: boolean) => Promise<void>;
}

export const DeleteClientModal: React.FC<IDeleteClientModalProps> = (props) => {
    const [deleting, setDeleting] = useState(false);

    if (!props.client) {
        return null;
    }

    const onCancelClick = () => props.close(false);

    const onDeleteClick = async () => {
        if (!props.client) {
            return;
        }

        setDeleting(true);

        try {
            await deleteClientByID(props.orgId, props.client.id);

            notification.success({ message: `${ props.client.displayName } has been successfully deleted.` });

            await props.close(true);
        } catch (e) {
            displayErrorNotification(e);
            setDeleting(false);
        }
    };

    const items = Array.isArray(props.client.associated) ? props.client.associated.length : 0;
    const canDelete = items === 0;

    let text = <Typography.Paragraph>To be filled in.</Typography.Paragraph>
    if (canDelete) {
        text = (
            <Typography.Paragraph>
                { props.client.displayName} can be successfully removed.
                They do not have any associated items.&nbsp;
                <Typography.Text type="danger">Are you sure you wish to continue?</Typography.Text>
            </Typography.Paragraph>
        );
    } else {
        text = (
            <Typography.Paragraph>
                { props.client.displayName} is unable to be deleted. They have { items } associated item{ items === 1 ? '' : 's' }.
                If you would like to still remove them, please remove the associated item{ items === 1 ? '' : 's' } first.&nbsp;
                <Typography.Text type="danger">Warning: doing so invalidate reports and tax documents.</Typography.Text>
            </Typography.Paragraph>
        );
    }

    return (
        <Modal
            open={props.visible}
            title={`Delete ${ props.client.displayName }?`}
            okText={canDelete ? 'Yes, delete' : 'Delete'}
            okButtonProps={{ disabled: !canDelete || deleting, loading: deleting, danger: true }}
            onOk={onDeleteClick}
            cancelButtonProps={{ disabled: deleting }}
            onCancel={onCancelClick}
            closable={deleting}
            maskClosable={false}
        >
            { text }
        </Modal>
    );
}

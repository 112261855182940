import React from 'react';
import dayjs from 'dayjs';
import { Card, Timeline, Button, Empty, Modal, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { AccessControlledButton } from 'components/permissions';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';

import { RelatedToType } from 'models/common/relatedTo';
import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { IClient } from 'models/client';
import { ITimelineEntry } from 'models/timelineEntry';

import { TimelineEntryIcon } from './timelineEntryIcon';
import { NewTimelineEntryDrawer } from './newDrawer';

interface ITimelineViewProps {
    view: 'card' | 'modal';
    visible?: boolean;
    related: IInventory | ITract | IClient;
    relatedType: RelatedToType;
    entries: ITimelineEntry[];
    refresh: () => Promise<void>;
    close?: () => void;
}

interface ITimelineViewState {
    drawerVisible: boolean;
}

class TimelineViewBase extends React.PureComponent<ITimelineViewProps, ITimelineViewState> {
    state: Readonly<ITimelineViewState> = {
        drawerVisible: false,
    }

    addTimelineItemClick = () => {
        this.setState({ drawerVisible: true });
    };

    onDrawerClose = async (saved: boolean) => {
        if (saved) {
            await this.props.refresh();
        }

        this.setState({ drawerVisible: false });
    };

    get addItemButton() {
        let feature = PermissionFeature.All;

        switch (this.props.relatedType) {
            case RelatedToType.CLIENT:
                feature = PermissionFeature.ClientTimeline;
                break;
            case RelatedToType.TRACT:
                feature = PermissionFeature.TractTimeline;
                break;
            case RelatedToType.INVENTORY:
                feature = PermissionFeature.Inventory;
                break;
        }

        return (
            <AccessControlledButton
                key="add-timeline-entry"
                type={this.props.view === 'card' ? 'dashed' : 'primary'}
                size={this.props.view === 'card' ? 'small' : 'middle'}
                icon={this.props.view === 'card' ? <PlusOutlined /> : null }
                onClick={this.addTimelineItemClick}
                feature={feature}
                action={PermissionAction.Create}
                prevent="hide"
            >Add Item</AccessControlledButton>
        );
    }

    get timeline() {
        return (
            <React.Fragment>
                {
                    this.props.entries.length === 0
                    ?
                        <Empty />
                    :
                        <Timeline
                            mode="alternate"
                            items={this.props.entries.map((t) => ({
                                key: t.id,
                                color: t.color,
                                dot: t.icon ?  <TimelineEntryIcon icon={t.icon} color={t.color} /> : null,
                                children: (
                                    <Typography.Paragraph ellipsis={{ rows: 1, expandable: true }}>
                                        {dayjs(t.date).format('MMM Do, YYYY @ h:mma')} {t.content}
                                    </Typography.Paragraph>
                                )
                            }))}
                        />
                }

                <NewTimelineEntryDrawer visible={this.state.drawerVisible} close={this.onDrawerClose} related={this.props.related} relatedType={this.props.relatedType} />
            </React.Fragment>
        );
    }

    get timelineCard() {
        return (
            <Card title="History / Timeline" bordered={false} extra={this.addItemButton}>
                {this.timeline}
            </Card>
        );
    }

    get timelineModal() {
        return (
            <Modal
                title="Timeline"
                open={this.props.visible}
                onCancel={this.props.close}
                footer={[ <Button key="close" onClick={this.props.close}>Close</Button>, this.addItemButton ]}
                destroyOnClose={true}
            >
                {this.timeline}
            </Modal>
        );
    }

    render() {
        switch (this.props.view) {
            case 'card':
                return this.timelineCard;
            default:
                return this.timelineModal;
        }
    }
}

export const TimelineView = TimelineViewBase;

import { GlobalState } from 'store';
import { ILoan } from 'models/loan';

export const isLoadingLoans = (state: GlobalState) => state.loans.isFetching;
export const getLoansForSelectedOrg = (state: GlobalState): ILoan[] => Object.values(state.loans.loansById).filter((c) => c.organization.id === state.org.selectedOrgLongId);
export const getLoansPagination = (state: GlobalState) => state.loans.pagination;
export const getLoanById = (state: GlobalState, orgId: string, loanId: string): ILoan | undefined => {
    const c = state.loans.loansById[loanId];
    if (!c) {
        return c;
    }

    if (c.organization.shortId !== orgId) {
        return undefined;
    }

    return c;
};

import React from 'react';
import { TimelineEntryIcons } from 'models/timelineEntry';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, ExclamationOutlined, InfoCircleOutlined, InfoOutlined, IssuesCloseOutlined, MessageOutlined, MinusCircleOutlined, MinusOutlined, PauseCircleOutlined, PlusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

//fontsize: 16px
export const TimelineEntryIcon: React.FunctionComponent<{ icon: TimelineEntryIcons, color: string }> = (props) => {
    const iconProps = {
        color: props.color,
    };

    switch (props.icon) {
        case TimelineEntryIcons.QuestionMark:
            return <QuestionCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.ExclamationMark:
            return <ExclamationOutlined {...iconProps} />;
        case TimelineEntryIcons.ExclamationMarkCircle:
            return <ExclamationCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.Info:
            return <InfoOutlined {...iconProps} />;
        case TimelineEntryIcons.InfoCircle:
            return <InfoCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.PauseCircle:
            return <PauseCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.Minus:
            return <MinusOutlined {...iconProps} />;
        case TimelineEntryIcons.MinusCircle:
            return <MinusCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.Plus:
            return <PlusOutlined {...iconProps} />;
        case TimelineEntryIcons.PlusCircle:
            return <PlusCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.CloseCircle:
            return <CloseCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.CheckMarkCircle:
            return <CheckCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.IssueClose:
            return <IssuesCloseOutlined {...iconProps} />;
        case TimelineEntryIcons.Clock:
            return <ClockCircleOutlined {...iconProps} />;
        case TimelineEntryIcons.Message:
            return <MessageOutlined {...iconProps} />;
        default:
            console.warn('unknown timeline entry icon!', props.icon);
            return null;
    }
}

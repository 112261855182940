import { GlobalState } from 'store';

import { IOrganization, OrganizationBillingStatus } from 'models/organization';

export const getOrgState = (state: GlobalState) => state.org;
export const getSelectedOrgShortId = (state: GlobalState) => state.org.selectedOrgShortId;
export const getSelectedOrgLongId = (state: GlobalState) => state.org.selectedOrgLongId;
export const getSelectedOrg = (state: GlobalState): IOrganization | undefined => state.org.orgsByShortId[state.org.selectedOrgShortId];
export const isFetchingOrg = (state: GlobalState) => state.org.isFetching;
export const isCreatingOrg = (state: GlobalState) => state.org.isCreating;
export const createdOrg = (state: GlobalState): IOrganization | undefined => state.org.orgsByLongId[state.org.createdId];

/**
 * Checks to see if the organization can create items based upon it's billing status
 *
 * @param state the global redux state
 * @returns whether the org can create or not
 */
export const isSelectedOrgAllowedToCreate = (state: GlobalState): boolean => {
    const org = state.org.orgsByShortId[state.org.selectedOrgShortId];

    if (!org) {
        return false;
    }

    switch (org.billing.status) {
        case OrganizationBillingStatus.Active:
        case OrganizationBillingStatus.Incomplete:
        case OrganizationBillingStatus.Trialing:
            return true;
        default:
            return false;
    }
}

export const getOrgError = (state: GlobalState) => {
    return {
        hasError: state.org.hasError,
        error: state.org.error,
    };
};

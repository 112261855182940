import React from 'react';
import { connect, DispatchProp, useStore } from 'react-redux';
import { Modal, notification, Alert } from 'antd';
import { injectStripe, ReactStripeElements, Elements, StripeProvider } from 'react-stripe-elements';

import { timeoutToPromise } from 'utils/promises';
import { displayErrorNotification } from 'utils/errors';
import { stripeApiKey } from 'utils/auth';

import { GlobalState } from 'store';
import { getUser } from 'store/selectors/auth';
import { getSelectedOrg } from 'store/selectors/org';
import { orgRefresh } from 'store/actions/org';

import { IInvoice, InvoicePaymentIntentStatus } from 'models/billing/invoices';

import { AddPaymentMethodModalBase } from './addPaymentMethodModal';

import { getPaymentIntentActionSecret } from 'api/organizations';

import './addPaymentMethodModal.css';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    user: getUser(state),
});

interface IPaymentIntentActionRequiredModalProps {
    visible: boolean;
    invoice?: IInvoice;
    close: () => void;
}

interface IPaymentIntentActionRequiredBaseProps extends IPaymentIntentActionRequiredModalProps, ReturnType<typeof mapStateToProps>, DispatchProp, ReactStripeElements.InjectedStripeProps { }

interface IPaymentIntentActionRequiredBaseState {
    isTakingAction: boolean;
    isAddPaymentMethodVisible: boolean;
    message: string;
    addedMethod?: string;
}

class PaymentIntentActionRequiredBase extends React.PureComponent<IPaymentIntentActionRequiredBaseProps, IPaymentIntentActionRequiredBaseState> {
    state: Readonly<IPaymentIntentActionRequiredBaseState> = {
        isTakingAction: false,
        isAddPaymentMethodVisible: false,
        message: '',
    }

    getDefaultPaymentMethod = () => {
        const p = this.props.selectedOrg!.billing.paymentMethods.find((p) => p.default);
        if (p) {
            return p.id;
        }

        return '';
    }

    closePaymentMethod = (added: boolean, paymentMethod: string) => {
        this.setState({ isAddPaymentMethodVisible: false });

        if (added) {
            this.setState({ addedMethod: paymentMethod }, this.onTakeActionClick);
        } else {
            this.setState({ isTakingAction: false });
        }
    }

    onTakeActionClick = () => {
        if (!this.props.invoice) {
            return;
        }

        if (!this.state.addedMethod && this.props.invoice.paymentIntent.status === InvoicePaymentIntentStatus.RequiresPaymentMethod && (this.getDefaultPaymentMethod() === '' || this.state.message)) {
            this.setState({ isTakingAction: true, isAddPaymentMethodVisible: true });
            return;
        }

        this.setState({ isTakingAction: true }, async () => {
            const { id } = this.props.selectedOrg!;

            const method = this.state.addedMethod ? this.state.addedMethod : this.getDefaultPaymentMethod();

            try {
                const secres = await getPaymentIntentActionSecret(id, this.props.invoice!.invoiceId);
                const result = await this.props.stripe!.handleCardPayment(secres.secret, { payment_method: method });

                if (result.error) {
                    console.log('error from stripe:', result.error);

                    displayErrorNotification({ message: result.error.message });
                    this.setState({ message: result.error.message || '', isTakingAction: false });
                } else {
                    notification.success({ message: 'Payment Action completed successfully.'});

                    await timeoutToPromise(4500);
                    await this.props.dispatch(orgRefresh(id) as any);
                    this.props.close();

                    this.setState({ isTakingAction: false, isAddPaymentMethodVisible: false, message: '' });
                }
            } catch (e) {
                displayErrorNotification(e, this.onTakeActionClick);
            }
        });
    }

    get alertMessage() {
        if (!this.state.message) {
            return null;
        }

        return (
            <Alert type="error" showIcon message={this.state.message} style={{ marginBottom: '15px' }} />
        );
    }

    render() {
        if (!this.props.selectedOrg || !this.props.stripe) {
            return null;
        }

        return (
            <React.Fragment>
                <Modal
                    open={this.props.visible}
                    title="Payment Action Required"
                    okText="Take Action"
                    onOk={this.onTakeActionClick}
                    okButtonProps={{ disabled: this.state.isTakingAction, loading: this.state.isTakingAction }}
                    onCancel={this.props.close}
                    cancelButtonProps={{ disabled: this.state.isTakingAction }}
                    closable={!this.state.isTakingAction}
                    className="payment-action-required-modal"
                    destroyOnClose
                >
                    { this.alertMessage }
                    <p>Action is required to complete your payment. Please click the "Take Action" button to start the process.</p>
                </Modal>

                <AddPaymentMethodModalBase visible={this.state.isAddPaymentMethodVisible} close={this.closePaymentMethod} for={this.props.selectedOrg} />
            </React.Fragment>
        );
    }
}

const PaymentIntentActionRequiredModalBase = connect(mapStateToProps)(injectStripe(PaymentIntentActionRequiredBase));

export const PaymentIntentActionRequiredModal: React.FunctionComponent<IPaymentIntentActionRequiredModalProps> = (props) => {
    const s = useStore<GlobalState>();

    return (
        <StripeProvider apiKey={stripeApiKey} stripeAccount={s.getState().org.connectedStripeAccount}>
            <Elements>
                <PaymentIntentActionRequiredModalBase invoice={props.invoice} visible={props.visible} close={props.close} />
            </Elements>
        </StripeProvider>
    );
}

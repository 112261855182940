import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

import { IClient } from 'models/client';
import { IEntity } from 'models/entity';

import { EditClientModal } from 'components/clients/editModal';
import { DeleteClientModal } from 'components/clients/deleteModal';
import { ActionMenuItemMap, ActionsMenu } from 'components/actions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

interface IClientActionButtonsProps {
    orgId: string;
    client?: IClient;
    entities: IEntity[];
    refreshData: () => Promise<void>;
}

export const ClientActionButtons: React.FC<IClientActionButtonsProps> = (props) => {
    const history = useHistory();
    const [setEditVisible, isEditVisible] = useState(false);
    const [setDeleteVisible, isDeleteVisible] = useState(false);

    if (!props.client) {
        return null;
    }

    const actions: ActionMenuItemMap<IClient> = {
        edit: {
            icon: <EditOutlined />,
            text: 'Edit',
            action: async () => isEditVisible(true),
            permission: {
                feature: PermissionFeature.Client,
                action: PermissionAction.Update,
                prevent: 'disable',
            },
        },
        delete: {
            icon: <DeleteOutlined />,
            text: 'Delete',
            action: async () => isDeleteVisible(true),
            permission: {
                feature: PermissionFeature.Client,
                action: PermissionAction.Delete,
                prevent: 'disable',
            },
        },
    };

    const onEditClose = async (saved: boolean) => {
        if (saved) {
            await props.refreshData();
        }

        isEditVisible(false);
    }

    const onDeleteClose = async (deleted: boolean) => {
        if (deleted) {
            history.push(`/${ props.orgId }/clients`);
            return;
        }

        isDeleteVisible(false);
    }

    return (
        <React.Fragment>
            <ActionsMenu<IClient> asButton record={props.client} actions={actions} />

            <EditClientModal
                orgId={props.orgId}
                visible={setEditVisible}
                clientId={props.client.id}
                initialLabel={props.client.displayName}
                initialStatus={props.client.status}
                initialType={props.client.type}
                initialLanguage={props.client.preferences?.language}
                initialAllowedMethods={props.client.billing.allowedPaymentMethods}
                close={onEditClose}
            />

            <DeleteClientModal
                orgId={props.orgId}
                visible={setDeleteVisible}
                client={props.client}
                close={onDeleteClose}
            />
        </React.Fragment>
    );
}

import { Action } from 'redux';

import { IInventory } from 'models/inventory';
import { IRestError } from 'models/common/restResult';
import { ILookup } from 'models/common/lookup';

export type InventoriesById = { [inventoryId: string]: IInventory };

export type GlobalInventoryState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    inventoriesById: InventoriesById;
};

export enum InventoryActionType {
    PURGE = 'inventories/purge',
    EVENT_CREATED = 'inventories/event/created',
    EVENT_UPDATED = 'inventories/event/updated',
    EVENT_DELETED = 'inventories/event/deleted',
    FETCHING_MULTIPLE = 'inventories/fetch',
    FETCHING_MULTIPLE_PENDING = 'inventories/fetch/pending',
    FETCHING_MULTIPLE_SUCCESS = 'inventories/fetch/success',
    FETCHING_MULTIPLE_FAILURE = 'inventories/fetch/failure',
    FETCHING_MULTIPLE_SUCCESS_CACHED = 'inventories/fetch/success/cached',
    FETCHING_ONE = 'inventory/fetch',
    FETCHING_ONE_PENDING = 'inventory/fetch/pending',
    FETCHING_ONE_SUCCESS = 'inventory/fetch/success',
    FETCHING_ONE_FAILURE = 'inventory/fetch/failure',
}

export interface InventoriesPurgeAction extends Action {
    type: InventoryActionType.PURGE;
}

export interface InventoriesFetchAction extends Action {
    type: InventoryActionType.FETCHING_MULTIPLE;
}

export interface InventoriesFetchPendingAction extends Action {
    type: InventoryActionType.FETCHING_MULTIPLE_PENDING;
}

export interface InventoriesFetchSuccessAction extends Action {
    type: InventoryActionType.FETCHING_MULTIPLE_SUCCESS;
    inventories: IInventory[];
}

export interface InventoriesFetchFailureAction extends Action {
    type: InventoryActionType.FETCHING_MULTIPLE_FAILURE;
    error?: IRestError;
}

export interface InventoriesFetchSuccessCacheAction extends Action {
    type: InventoryActionType.FETCHING_MULTIPLE_SUCCESS_CACHED
}

export interface InventoryFetchAction extends Action {
    type: InventoryActionType.FETCHING_ONE;
}

export interface InventoryFetchPendingAction extends Action {
    type: InventoryActionType.FETCHING_ONE_PENDING;
    inventoryId: string;
}

export interface InventoryFetchSuccessAction extends Action {
    type: InventoryActionType.FETCHING_ONE_SUCCESS;
    inventory: IInventory;
}

export interface InventoryFetchFailureAction extends Action {
    type: InventoryActionType.FETCHING_ONE_FAILURE;
    error?: IRestError;
}

export interface InventoryCreatedEventRecievedAction extends Action {
    type: InventoryActionType.EVENT_CREATED;
    inventory: IInventory;
}

export interface InventoryUpdatedEventRecievedAction extends Action {
    type: InventoryActionType.EVENT_UPDATED;
    inventory: IInventory;
}

export interface InventoryDeletedEventRecievedAction extends Action {
    type: InventoryActionType.EVENT_DELETED;
    lookup: Partial<ILookup>;
}

export type InventoryEventAction = InventoryCreatedEventRecievedAction | InventoryUpdatedEventRecievedAction | InventoryDeletedEventRecievedAction;
export type InventoryFetchOneAction = InventoryFetchAction | InventoryFetchPendingAction | InventoryFetchSuccessAction | InventoryFetchFailureAction;
export type InventoryAction = InventoryFetchOneAction | InventoriesFetchAction | InventoriesFetchPendingAction | InventoriesFetchSuccessAction | InventoriesFetchFailureAction | InventoriesFetchSuccessCacheAction | InventoriesPurgeAction | InventoryEventAction;

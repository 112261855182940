import React, { useState } from 'react';
import { Modal, Form, Typography, Input, message } from 'antd';

import { updateSubscription } from 'api/organizations';

interface IFormValues {
    reason: string;
}

interface IConfirmCancelSubscriptionModalProps {
    visible: boolean;
    orgId: string;
    close: (cancelled: boolean) => void;
}

export const ConfirmCancelSubscriptionModal: React.FC<IConfirmCancelSubscriptionModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [saving, setSaving] = useState(false);

    const close = () => props.close(false);

    const confirm = async () => {
        try {
            const values = await form.validateFields();
            setSaving(true);

            await updateSubscription(props.orgId, { reason: values.reason });

            setTimeout(() => {
                setSaving(false);
                message.success('Subscription successfully cancelled.');

                form.resetFields();
                props.close(true);
            }, 5000);
        } catch (e) {
            console.warn('failed to cancel:', e);
            setSaving(false);
        }
    };

    return (
        <Modal
            open={props.visible}
            title="Confirm Cancel Lendiom"
            maskClosable={false}
            keyboard={false}
            closable={!saving}
            onCancel={close}
            cancelText="Nevermind!"
            onOk={confirm}
            okText="Confirm Cancellation"
            okButtonProps={{ loading: saving, disabled: saving, danger: true }}
            cancelButtonProps={{ disabled: saving, type: 'primary' }}
        >
            <Form<IFormValues> form={form} onFinish={confirm}>
                <Typography.Paragraph>Please confirm you would like to cancel your Lendiom subscription. Before you go, we ask you provide us some insight why you are canceling.</Typography.Paragraph>

                <Form.Item name="reason" rules={[{ required: true, message: 'Please provide us a reason. Thank you!' }]}>
                    <Input.TextArea disabled={saving} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

import decoder from 'jwt-decode';

import { IUserLoginResult } from 'models/payloads/userLogin';
import { IUserRegistrationResult } from 'models/payloads/userProfile';
import { JwtToken } from 'models/user';

//import { StripeProvider }  from 'react-stripe-elements';
export const stripeApiKey = `${process.env.REACT_APP_STRIPE_KEY}`;

//#region jwt token items
export const jwtTokenKey = 'MyLendiomToken';
export const jwtExpiresAtKey = 'MyLendiomTokenExpiresAt';

export function getJwtToken(): string {
    const token = window.localStorage.getItem(jwtTokenKey);
    const expiresAtString = window.localStorage.getItem(jwtExpiresAtKey);

    if (!token || !expiresAtString) {
        return '';
    }

    const expiresAt = new Date(JSON.parse(expiresAtString));
    if (new Date().getTime() < expiresAt.getTime()) {
        return token;
    } else {
        console.log('auth token has expired');
        window.localStorage.removeItem(jwtTokenKey);
        window.localStorage.removeItem(jwtExpiresAtKey);
    }

    return '';
}

export function getBearerToken(): { Authorization: string } {
    const token = getJwtToken();
    if (!token) {
        return {
            Authorization: '',
        };
    }

    return {
        Authorization: `Bearer ${ token }`,
    };
}

export function storeToken(token: string) {
    const jwt = decoder<JwtToken>(token);

    const expiresAt = new Date(jwt.exp * 1000);
    if (new Date().getTime() > expiresAt.getTime()) {
        console.log('the token to store has expired?!!');
        return;
    }

    window.localStorage.setItem(jwtTokenKey, token);
    window.localStorage.setItem(jwtExpiresAtKey, JSON.stringify(expiresAt));
}

export function revokeToken(): void {
    window.localStorage.removeItem(jwtTokenKey);
    window.localStorage.removeItem(jwtExpiresAtKey);
}

export function storeTokenFromResult(result: IUserLoginResult | IUserRegistrationResult) {
    storeToken(result.token);
}

export function hasValidToken(): boolean {
    return !!getJwtToken();
}
//#endregion

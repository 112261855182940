import React, { useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { ClientBillingAllowedPaymentMethods, ClientStatus, ClientType, IClient } from 'models/client';
import { Language } from 'models/common/language';

import { trackUmami } from 'utils/umami';
import { displayErrorNotification } from 'utils/errors';

import { updateClient } from 'api/clients';

interface IFormValues {
    label: string;
    status: ClientStatus;
    type: ClientType;
    language: Language;
    paymentMethods: ClientBillingAllowedPaymentMethods[];
}

interface IEditClientModalProps {
    visible: boolean;
    orgId: string;
    clientId: string;
    initialLabel?: string;
    initialStatus?: ClientStatus;
    initialType?: ClientType;
    initialLanguage?: Language;
    initialAllowedMethods?: ClientBillingAllowedPaymentMethods[];
    close: (saved: boolean) => Promise<void>;
}

export const EditClientModal: React.FC<IEditClientModalProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [isSaving, setSaving] = useState(false);

    const cancelClick = () => {
        props.close(false);
        form.resetFields();
        setSaving(false);
    };

    const onEditClick = () => {
        setSaving(true);

        form
            .validateFields()
            .then(async (values) => {
                try {
                    const clientUpdate: Partial<IClient> = {
                        id: props.clientId,
                        displayName: values.label,
                        status: values.status,
                        type: values.type,
                        preferences: {
                            language: values.language,
                        },
                        billing: {
                            allowedPaymentMethods: values.paymentMethods,
                        },
                    };

                    await updateClient(props.orgId, clientUpdate);
                    trackUmami('Update Client');

                    await props.close(true);
                    form.resetFields();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    setSaving(false);
                }
            })
            .catch((e) => {
                setSaving(false);
                console.warn('failed to validate the edit client form:', e);
            });
    };

    return (
        <Modal
            open={props.visible}
            title={`Edit Client: ${ props.initialLabel }`}
            okText="Save"
            closable={!isSaving}
            maskClosable={false}
            okButtonProps={{ disabled: isSaving, loading: isSaving }}
            cancelButtonProps={{ disabled: isSaving }}
            onOk={onEditClick}
            onCancel={cancelClick}
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                disabled={isSaving}
                initialValues={{
                    label: props.initialLabel,
                    status: props.initialStatus,
                    type: props.initialType,
                    language: props.initialLanguage,
                    paymentMethods: props.initialAllowedMethods,
                }}
            >
                <Form.Item name="label" label="Label" rules={[{ required: true, message: 'A name is required for the rename to be effective.' }]} style={{ marginBottom: 0 }}>
                    <Input />
                </Form.Item>

                <Form.Item name="status" label="Status" style={{ marginTop: '15px' }} rules={[{ required: true, message: 'Please state the status of client.' }, { pattern: /^(?!.*unknown).*$/, message: 'Unknown is not a valid option, please select a different one.' }]}>
                    <Select placeholder="Select the status">
                        <Select.Option value={ClientStatus.Active}>Active</Select.Option>
                        <Select.Option value={ClientStatus.Inactive}>Inactive</Select.Option>
                        <Select.Option value={ClientStatus.Prospect}>Prospect</Select.Option>
                        <Select.Option value={ClientStatus.DoNotContact}>Do Not Contact</Select.Option>
                        <Select.Option value={ClientStatus.Unknown} disabled>Unknown</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the type of client.' }, { pattern: /^(?!.*unidentified).*$/, message: 'Unidentified is not a valid option, please select a different one.' }]}>
                    <Select placeholder="Please select a type">
                        <Select.Option value={ClientType.Individual}>Individual</Select.Option>
                        <Select.Option value={ClientType.Family}>Family</Select.Option>
                        <Select.Option value={ClientType.Company}>Company</Select.Option>
                        <Select.Option value={ClientType.Unidentified} disabled>Unidentified</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="language"
                    label="Language"
                    rules={[{ required: true, message: 'Please select a language.' }]}
                    extra="For any automated communication, which language should we use to communicate with the client?"
                >
                    <Select placeholder="Please select a language">
                        <Select.Option value={Language.English}>English <span role="img" aria-label="USA Flag">🇺🇸</span></Select.Option>
                        <Select.Option value={Language.Spanish}>Spanish <span role="img" aria-label="Mexico Flag">🇲🇽</span></Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="paymentMethods"
                    label="Allowed Payment Methods"
                    rules={[{
                        required: true,
                        message: 'At least one payment method is required for online payments.',
                        type: 'array',
                        min: 1,
                    }]}
                    extra="When paying online, which payment methods are they allowed to use? You must select at least one. Note: any existing payment methods will be allowed, this only applies to new ones."
                >
                    <Select mode="multiple">
                        <Select.Option value={ClientBillingAllowedPaymentMethods.BankAccounts}>Bank Accounts</Select.Option>
                        <Select.Option value={ClientBillingAllowedPaymentMethods.CreditDebitCards}>Credit/Debit Cards</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

import { GlobalNotificationState, NotificationAction, NotificationActionType, NotificationsById } from '../types/notifications';

const initialAuthState: GlobalNotificationState = {
    fetchedAt: '',
    isFetching: false,
    hasError: false,
    notificationsById: {},
};

export function notificationReducer(state = initialAuthState, action: NotificationAction): GlobalNotificationState {
    switch (action.type) {
        case NotificationActionType.FETCHING_MULTIPLE:
        case NotificationActionType.MARK_AS_READ_PENDING:
        case NotificationActionType.CLEAR_TYPE_PENDING:
            return { ...state, isFetching: true, hasError: false };
        case NotificationActionType.MARK_AS_READ_SUCCESS:
            return { ...state, isFetching: false, notificationsById: { ...state.notificationsById, [action.notification.id]: action.notification } };
        case NotificationActionType.FETCHING_MULTIPLE_SUCCESS:
            return {
                ...state, isFetching: false, fetchedAt: new Date().toJSON(),
                notificationsById: { ...state.notificationsById, ...action.notifications.reduce((v: NotificationsById, p) => { v[p.id] = p; return v; }, {}) },
            };
        case NotificationActionType.EVENT:
            return {
                ...state,
                notificationsById: { ...state.notificationsById, [action.notification.id]: action.notification },
            };
        case NotificationActionType.FETCHING_MULTIPLE_FAILURE:
        case NotificationActionType.MARK_AS_READ_FAILURE:
        case NotificationActionType.CLEAR_TYPE_FAILURE:
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case NotificationActionType.CLEAR_TYPE_SUCCESS:
            return {
                ...state, isFetching: false, hasError: false, error: undefined,
                notificationsById: Object.values(state.notificationsById).filter((n) => n.type !== action.notificationType).reduce((v: NotificationsById, p) => { v[p.id] = p; return v; }, {}),
            };
        case NotificationActionType.PURGE:
            return { ...state, fetchedAt: '', isFetching: false, hasError: false, error: undefined, notificationsById: {} };
        default:
            return state;
    }
}

import { GlobalLoanState, LoanAction, LoanActionType, LoansById } from "store/types/loans";
import { displayErrorNotification } from "utils/errors";

const initialAuthState: GlobalLoanState = {
    isFetching: false,
    hasError: false,
    loansById: {},
    pagination: {
        offset: 0,
        limit: 10,
        count: 0,
    },
};

export function loanReducer(state = initialAuthState, action: LoanAction): GlobalLoanState {
    switch (action.type) {
        case LoanActionType.FETCHING_MULTIPLE:
        case LoanActionType.FETCHING_MULTIPLE_PENDING:
        case LoanActionType.FETCHING_ONE:
        case LoanActionType.FETCHING_ONE_PENDING:
        case LoanActionType.FETCHING_RELATED_PENDING:
            return { ...state, isFetching: true, hasError: false };
        case LoanActionType.FETCHING_ONE_SUCCESS:
            return { ...state, isFetching: false, loansById: { ...state.loansById, [action.loan.id]: action.loan } };
        case LoanActionType.FETCHING_MULTIPLE_SUCCESS:
            return { ...state, isFetching: false, loansById: { ...action.loans.reduce((v: LoansById, p) => { v[p.id] = p; return v; }, {}) }, pagination: { ...state.pagination, ...action.pagination } };
        case LoanActionType.FETCHING_RELATED_SUCCESS:
            return { ...state, isFetching: false, loansById: { ...state.loansById, ...action.loans.reduce((v: LoansById, p) => { v[p.id] = p; return v; }, {}) } };
            // return { ...state, isFetching: false, fetchedAt: new Date().toJSON(), loansById: { ...state.loansById, ...action.loans.reduce((v: LoansById, p) => { v[p.id] = p; return v; }, {}) } };
        case LoanActionType.FETCHING_MULTIPLE_FAILURE:
        case LoanActionType.FETCHING_ONE_FAILURE:
        case LoanActionType.FETCHING_RELATED_FAILURE:
            displayErrorNotification(action.error);
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case LoanActionType.PURGE:
            //return { ...state, fetchedAt: '', isFetching: false, hasError: false, loansById: {} };
            return { ...state, isFetching: false, hasError: false, loansById: {} };
        case LoanActionType.EVENT_CREATED:
        case LoanActionType.EVENT_UPDATED:
            return {
                ...state,
                loansById: { ...state.loansById, [action.loan.id]: action.loan },
            };
        case LoanActionType.EVENT_DELETED:
            if (!action.lookup.id) {
                return state;
            }

            const loansById = { ...state.loansById };
            delete loansById[action.lookup.id];

            return {
                ...state,
                loansById,
            };
        default:
            return state;
    }
}

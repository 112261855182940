import React from 'react';
import currency from 'currency.js';

import { AlertOutlined, AlertTwoTone, CheckCircleTwoTone, DownOutlined, FilePdfOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, Typography, Dropdown, Tooltip, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { MenuInfo } from 'rc-menu/lib/interface';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import { Currency } from 'models/currency';
import { IInvoice, InvoiceStatus, InvoicePaymentIntentStatus } from 'models/billing/invoices';
import { getInvoices, getBalanceDue } from 'api/billing';

import { PaymentIntentActionRequiredModal } from 'components/billing/actionNeededModal';

import { displayErrorNotification } from 'utils/errors';

interface IInvoicesProps {
    orgId: string;
}

interface IInvoicesState {
    isLoading: boolean;
    invoices: IInvoice[];
    isActionModalVisible: boolean;
    selectedInvoice?: IInvoice;
    balanceDue: Currency;
}

export class InvoicesView extends React.PureComponent<IInvoicesProps, IInvoicesState> {
    state: Readonly<IInvoicesState> = {
        isLoading: true,
        invoices: [],
        isActionModalVisible: false,
        balanceDue: '0.00',
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IInvoicesProps) {
        if (this.props.orgId === prevProps.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = async () => {
        try {
            const invoices = await getInvoices(this.props.orgId);
            const balances = await getBalanceDue(this.props.orgId);
            this.setState({
                invoices,
                balanceDue: balances.snailMail,
            });
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    columns: ColumnProps<IInvoice>[] = [
        {
            title: 'Number', key: 'number', dataIndex: 'number',
            render: (value: string) => value || '-',
        },
        {
            title: 'Status', key: 'status', dataIndex: 'status', className: 'title-caps',
            render: (status: InvoiceStatus, record: IInvoice) => {
                let icon: React.ReactNode = null;

                if (record.paid) {
                    icon = <CheckCircleTwoTone twoToneColor="#52c41a" title="Invoice is paid" />;
                } else {
                    switch (record.paymentIntent.status) {
                        case InvoicePaymentIntentStatus.RequiresAction:
                        case InvoicePaymentIntentStatus.RequiresCapture:
                        case InvoicePaymentIntentStatus.RequiresConfirmation:
                        case InvoicePaymentIntentStatus.RequiresPaymentMethod:
                            icon = (
                                <Tooltip overlay="Action is required, please see the actions menu.">
                                    <AlertTwoTone twoToneColor="#eb2f96" />
                                </Tooltip>
                            );
                            break;
                        default:
                            break;
                    }
                }

                return (
                    <React.Fragment>{ status }&nbsp;{ icon }</React.Fragment>
                );
            },
        },
        {
            title: 'Amount', key: 'amount', dataIndex: 'amount',
            render: (amount: string) => currency(amount, { precision: 2}).format(true),
        },
        {
            title: 'Date', key: 'date', dataIndex: 'date', defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (date: string) => new Date(date).toLocaleString(),
        },
        {
            title: '', key: 'actions',
            render: (nothing: any, record: IInvoice) => {
                //draft invoices don't have a pdf url
                if (record.status === InvoiceStatus.Draft) {
                    return null;
                }

                return (
                    <Dropdown menu={{ items: this.getInvoiceMenuItemsFor(record), onClick: this.onActionMenuClick(record) }}>
                        <Button type="link">
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ]

    closeActionModal = () => {
        this.loadData();
        this.setState({ selectedInvoice: undefined, isActionModalVisible: false });
    }

    onActionMenuClick(invoice: IInvoice) {
        return async (e: MenuInfo) => {
            e.domEvent.preventDefault();

            switch (e.key) {
                case 'download':
                    window.open(invoice.pdfUrl, '_blank');
                    return;
                case 'other-action':
                    this.setState({ isActionModalVisible: true, selectedInvoice: invoice });
                    return;
                default:
                    return;
            }
        };
    }

    getInvoiceMenuItemsFor(invoice: IInvoice): Array<ItemType> {
        const items: Array<ItemType> = [
            { key: 'download', icon: <FilePdfOutlined />, label: 'Download' },
        ];

        switch (invoice.paymentIntent.status) {
            case InvoicePaymentIntentStatus.RequiresAction:
            case InvoicePaymentIntentStatus.RequiresCapture:
            case InvoicePaymentIntentStatus.RequiresConfirmation:
                items.push({ key: 'other-action', icon: <AlertOutlined />, label: 'Take Action: Needs Confirmation' });
                break;
            case InvoicePaymentIntentStatus.RequiresPaymentMethod:
                items.push({ key: 'other-action', icon: <AlertOutlined />, label: 'Take Action: Needs Payment Method' });
                break;
        }

        return items;
    }

    onRefreshClick = () => {
        this.setState({ isLoading: true }, this.loadData);
    }

    tableHeader = () => {
        const balanceDue = currency(this.state.balanceDue, { precision: 2 });
        let tag: React.ReactNode | null = null;
        if (balanceDue.intValue > 0) {
            tag = <Tag>Balance Due: { balanceDue.format(true) }</Tag>;
        }

        return (
            <div className="table-header-with-button">
                <Typography.Title level={3}>Invoices { tag }</Typography.Title>
                <Button type="primary" icon={<ReloadOutlined />} onClick={this.onRefreshClick}>Refresh</Button>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Table<IInvoice>
                    dataSource={this.state.invoices}
                    loading={this.state.isLoading}
                    columns={this.columns}
                    rowKey="id"
                    pagination={{ hideOnSinglePage: true }}
                    bordered={false}
                    title={this.tableHeader}
                />

                <PaymentIntentActionRequiredModal
                    invoice={this.state.selectedInvoice}
                    visible={this.state.isActionModalVisible}
                    close={this.closeActionModal}
                />
            </React.Fragment>
        );
    }
}

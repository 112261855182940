import React from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { hasAccessTo } from 'store/selectors/permissions';

interface IAccessControlledProps {
    feature: PermissionFeature;
    action: PermissionAction;
}

export const AccessControlled: FC<IAccessControlledProps> = (props) => {
    const hasAccess = useSelector(hasAccessTo(props.feature, props.action));
    if (!hasAccess) {
        return null;
    }

    return (
        <React.Fragment>
            { props.children }
        </React.Fragment>
    );
}

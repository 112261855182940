import { Action } from 'redux';

import { IClient } from 'models/client';
import { IPagination } from 'models/common/pagination';
import { IRestError } from 'models/common/restResult';
import { ILookup } from 'models/common/lookup';

export type ClientsById = { [clientId: string]: IClient };

export type GlobalClientState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    clientsById: ClientsById;
    pagination: IPagination;
};

export enum ClientActionType {
    PURGE = 'clients/purge',
    EVENT_CREATED = 'clients/event/created',
    EVENT_UPDATED = 'clients/event/updated',
    EVENT_DELETED = 'clients/event/deleted',
    FETCHING_MULTIPLE = 'clients/fetch',
    FETCHING_MULTIPLE_PENDING = 'clients/fetch/pending',
    FETCHING_MULTIPLE_SUCCESS = 'clients/fetch/success',
    FETCHING_MULTIPLE_FAILURE = 'clients/fetch/failure',
    FETCHING_ONE = 'client/fetch',
    FETCHING_ONE_PENDING = 'client/fetch/pending',
    FETCHING_ONE_SUCCESS = 'client/fetch/success',
    FETCHING_ONE_FAILURE = 'client/fetch/failure',
}

export interface ClientsPurgeAction extends Action {
    type: ClientActionType.PURGE;
}

export interface ClientsFetchAction extends Action {
    type: ClientActionType.FETCHING_MULTIPLE;
}

export interface ClientsFetchPendingAction extends Action {
    type: ClientActionType.FETCHING_MULTIPLE_PENDING;
}

export interface ClientsFetchSuccessAction extends Action {
    type: ClientActionType.FETCHING_MULTIPLE_SUCCESS;
    clients: IClient[];
    pagination?: IPagination;
    filtered: boolean;
}

export interface ClientsFetchFailureAction extends Action {
    type: ClientActionType.FETCHING_MULTIPLE_FAILURE;
    error?: IRestError;
}

export interface ClientFetchAction extends Action {
    type: ClientActionType.FETCHING_ONE;
}

export interface ClientFetchPendingAction extends Action {
    type: ClientActionType.FETCHING_ONE_PENDING;
}

export interface ClientFetchSuccessAction extends Action {
    type: ClientActionType.FETCHING_ONE_SUCCESS;
    client: IClient;
}

export interface ClientFetchFailureAction extends Action {
    type: ClientActionType.FETCHING_ONE_FAILURE;
    error?: IRestError;
}

export interface ClientCreatedEventRecievedAction extends Action {
    type: ClientActionType.EVENT_CREATED;
    client: IClient;
}

export interface ClientUpdatedEventRecievedAction extends Action {
    type: ClientActionType.EVENT_UPDATED;
    client: IClient;
}

export interface ClientDeletedEventRecievedAction extends Action {
    type: ClientActionType.EVENT_DELETED;
    lookup: Partial<ILookup>;
}

export type ClientEventAction = ClientCreatedEventRecievedAction | ClientUpdatedEventRecievedAction | ClientDeletedEventRecievedAction;

export type ClientAction = ClientFetchAction | ClientFetchPendingAction | ClientFetchSuccessAction | ClientFetchFailureAction | ClientsFetchAction | ClientsFetchPendingAction | ClientsFetchSuccessAction | ClientsFetchFailureAction | ClientsPurgeAction | ClientEventAction;

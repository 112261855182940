import React, { lazy, Suspense } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row, Col, Table, message, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, IdcardOutlined, StarOutlined, StarTwoTone } from '@ant-design/icons';
import { FaRegAddressCard } from 'react-icons/fa';
import type { ColumnProps } from 'antd/lib/table';
import type { Key } from 'antd/lib/table/interface';

import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { IClient, ClientType } from 'models/client';
import { IEntity } from 'models/entity';

import { EntityPhoneNumber } from './phoneNumber';
import { EntityAddress } from './address';
import { AddEditEntityDrawer } from './addEditDrawer';
import { EntityList } from './list';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { displayErrorNotification } from 'utils/errors';
import { SimpleDate } from 'utils/formatting';

import { setPrimaryEntity, verifyClientEntityAddress, deleteClientEntity, downloadClientEntityVCard } from 'api/clients';

const ViewEntityTaxInfoModal = lazy(() => import('./viewTaxInfo'));

interface IEntityTableProps {
    client: IClient;
    entities: IEntity[];
    pageSize: number;
    refresh: () => void;
};

interface IEntityTableState {
    expandedRows: string[];
    isEditVisible: boolean;
    isTaxInfoVisible: boolean;
    editingEntity?: IEntity;
    viewingEntity?: IEntity;
}

export class EntityTable extends React.PureComponent<IEntityTableProps, IEntityTableState> {
    state: Readonly<IEntityTableState> = {
        expandedRows: [],
        isEditVisible: false,
        isTaxInfoVisible: false,
    }

    columns: ColumnProps<IEntity>[] = [
        {
            title: 'Full Name', dataIndex: 'fullName', key: 'fullName',
            render: (fullName: string, record: IEntity) => {
                const name = `${ record.title ? record.title + ' ' : ''}${ fullName }`;
                const isPrimary = this.props.client.primaryEntity.id === record.id;

                if (isPrimary) {
                    return (
                        <React.Fragment>
                            <StarTwoTone twoToneColor="#ffd700" /> { name }
                        </React.Fragment>
                    );
                }

                return name;
            }
        },
        {
            title: 'Email', dataIndex: 'email', key: 'email',
            render: (value: string) => {
                if (!value) {
                    return null;
                }

                return (
                    <Typography.Text copyable={{ text: value }}>
                        <a href={'mailto:'+value}>{value}</a>
                    </Typography.Text>
                );
            }
        },
        {
            title: 'Phone Number', dataIndex: 'phoneNumbers', key: 'phoneNumber',
            render: (value: any, record: IEntity) => <EntityPhoneNumber entity={record} />,
        },
        {
            dataIndex: '', key: 'action',
            render: (nothing: any, record: IEntity) => (
                <ActionsMenu<IEntity> record={record} actions={this.actions} onClick={this.onActionMenuClick} />
            ),
        },
    ]

    componentDidUpdate(prevProps: IEntityTableProps) {
        if (prevProps.entities.length === this.props.entities.length) {
            return;
        }

        if (this.props.entities.length === 0) {
            return;
        }

        this.setState({ expandedRows: [this.props.entities[0].id] });
    }

    expandedRowRender = (record: IEntity) => {
        const { taxInfo } = record;
        return (
            <Row>
                <Col sm={24} md={12}>
                    <EntityAddress entity={record} showTags />
                </Col>
                <Col sm={24} md={12}>
                    {!taxInfo || !taxInfo.type || !taxInfo.short
                        ? <span>No tax information</span>
                        : <span style={{ textTransform: 'uppercase' }}>{ taxInfo.type }: ***{ taxInfo.short }</span>
                    }
                    <br />
                    <span>Birthday: <SimpleDate date={record.birthDate} /></span>
                </Col>
            </Row>
        );
    }

    onExpandedRowsChange = (expandedRows: readonly Key[]) => {
        this.setState({ expandedRows: expandedRows as string[] });
    }

    actions: ActionMenuItemMap<IEntity> = {
        edit: {
            icon: <EditOutlined />,
            text: 'Edit',
            permission: { feature: PermissionFeature.ClientEntities, action: PermissionAction.Update, prevent: 'disable' },
        },
        'set-primary': {
            icon: <StarOutlined />,
            text: 'Make Primary',
            hidden: () => this.props.client.type === ClientType.Individual,
            disabled: (entity) => this.props.client.primaryEntity.id === entity.id,
            permission: { feature: PermissionFeature.ClientEntities, action: PermissionAction.Update, prevent: 'hide' },
        },
        'verify-address': {
            icon: <FaRegAddressCard />,
            text: 'Verify Address',
            disabled: (entity) => {
                const hasAnAddress = Array.isArray(entity.addresses) && entity.addresses.length >= 1;
                const hasVerifiedAddress = hasAnAddress ? entity.addresses![0].verified! : false;

                return !hasAnAddress || hasVerifiedAddress;
            },
            permission: { feature: PermissionFeature.ClientEntities, action: PermissionAction.Update, prevent: 'disable' },
        },
        viewTaxId: {
            icon: <EyeOutlined />,
            text: 'View Tax ID',
            hidden: (e) => !e.taxInfo || !e.taxInfo.short,
            permission: { feature: PermissionFeature.ClientSecrets, action: PermissionAction.Read, prevent: 'hide' },
        },
        remove: {
            icon: <DeleteOutlined />,
            text: 'Remove',
            hidden: () => this.props.client.type === ClientType.Individual,
            disabled: (entity) => this.props.client.primaryEntity.id === entity.id,
            permission: { feature: PermissionFeature.ClientEntities, action: PermissionAction.Delete, prevent: 'hide' },
        },
        download: { icon: <IdcardOutlined />, text: 'Download vCard' },
    }

    onActionMenuClick = async (entity: IEntity, actionKey: string) => {
        try {
            const { client } = this.props;

            switch (actionKey) {
                case 'edit':
                    this.openEdit(entity);
                    return;
                case 'set-primary':
                    await setPrimaryEntity(client.organization.id, client.id, entity.id);
                    message.success(`${ entity.fullName } is now the primary entity for ${ client.displayName }`);
                    return;
                case 'verify-address':
                    await verifyClientEntityAddress(client.organization.id, client.id, entity.id);
                    message.success(`${ entity.fullName }'s address is now verified.`);
                    return;
                case 'viewTaxId':
                    this.setState({ isTaxInfoVisible: true, viewingEntity: entity });
                    return;
                case 'remove':
                    await deleteClientEntity(client.organization.id, client.id, entity.id);
                    this.props.refresh();
                    message.success(`${ entity.fullName } has been removed from ${ client.displayName }.`);
                    break;
                case 'download':
                    await downloadClientEntityVCard(client.organization.id, client.id, entity.id);
                    message.success(`Successfully downloaded ${ entity.fullName }'s vCard.`);
                    break;
                default:
                    return;
            }
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    openEdit = (entity: IEntity) => {
        this.setState({ isEditVisible: true, editingEntity: entity });
    }

    closeEditDraw = async () => {
        this.setState({ isEditVisible: false, editingEntity: undefined });
    }

    closeViewTaxDraw = () => {
        this.setState({ isTaxInfoVisible: false, viewingEntity: undefined });
    }

    get tableOrList() {
        if (isMobileOnly) {
            return (
                <EntityList
                    client={this.props.client}
                    entities={this.props.entities}
                    pageSize={this.props.pageSize}
                    refresh={this.props.refresh}
                    openEdit={this.openEdit}
                />
            );
        }

        return (
            <Table<IEntity>
                className="entity-table"
                rowKey="id"
                dataSource={this.props.entities}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: this.props.pageSize,
                }}
                expandable={{
                    expandedRowRender: this.expandedRowRender,
                    expandedRowKeys: this.state.expandedRows,
                    onExpandedRowsChange: this.onExpandedRowsChange,
                }}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                { this.tableOrList }

                <AddEditEntityDrawer
                    isVisible={this.state.isEditVisible}
                    orgId={this.props.client.organization.id}
                    clientId={this.props.client.id}
                    clientType={this.props.client.type}
                    entity={this.state.editingEntity}
                    close={this.closeEditDraw}
                    save
                />

                <Suspense fallback={null}>
                    <ViewEntityTaxInfoModal
                        open={this.state.isTaxInfoVisible}
                        entity={this.state.viewingEntity}
                        close={this.closeViewTaxDraw}
                    />
                </Suspense>
            </React.Fragment>
        );
    }
}

import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Result, Typography } from 'antd';

import type { PermissionAction, PermissionFeature } from 'models/permissions/features';
import { hasAccessTo } from 'store/selectors/permissions';
import { getSelectedOrgShortId } from 'store/selectors/org';

interface IAccessControlledWrapperProps {
    feature: PermissionFeature;
    action: PermissionAction;
}

export const AccessControlledWrapper: FC<PropsWithChildren<IAccessControlledWrapperProps>> = (props) => {
    const history = useHistory();
    const orgId = useSelector(getSelectedOrgShortId);
    const canAccess = useSelector(hasAccessTo(props.feature, props.action));

    if (!canAccess) {
        return (
            <Result
                status="403"
                title="403"
                subTitle={
                    <React.Fragment>
                        Sorry, you are not authorized to access this page.<br />
                        You are missing the<Typography.Text keyboard>{ props.action }</Typography.Text>permission on the<Typography.Text keyboard>{ props.feature }</Typography.Text>feature.
                    </React.Fragment>
                }
                extra={<Button type="primary" onClick={() => history.push(`/${ orgId }/dashboard`)}>Back Home</Button>}
            />
        );
    }

    return (
        <React.Fragment>{ props.children }</React.Fragment>
    );
}

import { GlobalState } from '../../store';
import { INotification, NotificationType } from '../../models/notification';

const sortByDate = (a: INotification, b: INotification) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

export const isLoadingNotifications = (state: GlobalState) => state.notifications.isFetching;
export const getNotifications = (state: GlobalState): INotification[] => Object.values(state.notifications.notificationsById).filter((n) => n.type === NotificationType.NOTIFICATIONS).sort(sortByDate);
export const getMessageNotifications = (state: GlobalState): INotification[] => Object.values(state.notifications.notificationsById).filter((n) => n.type === NotificationType.MESSAGES).sort(sortByDate);
export const getReminderNotifications = (state: GlobalState): INotification[] => Object.values(state.notifications.notificationsById).filter((n) => n.type === NotificationType.REMINDERS).sort(sortByDate);

export const getUnreadCount = (state: GlobalState): number => {
    let count = 0;

    Object.values(state.notifications.notificationsById).forEach((n) => {
        if (!n.read) {
            count++;
        }
    });

    return count;
};

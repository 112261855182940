import React from 'react';
import { Tag } from 'antd';

import { PropertyTaxStatus } from '../../models/propertyTax';

interface IPropertyTaxStatusTagProps {
    status: PropertyTaxStatus
};

export const PropertyTaxStatusTag: React.FC<IPropertyTaxStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case PropertyTaxStatus.New:
        case PropertyTaxStatus.Draft:
            color = 'gold';
            break;
        case PropertyTaxStatus.Finalizing:
            color = 'volcano';
            break;
        case PropertyTaxStatus.InProgress:
            color = 'green';
            break;
        case PropertyTaxStatus.Reimbursed:
            color = 'cyan';
            break;
    }

    let label = 'Unknown';
    switch(props.status) {
        case PropertyTaxStatus.New:
            label = 'New';
            break;
        case PropertyTaxStatus.Draft:
            label = 'Draft'
            break;
        case PropertyTaxStatus.Finalizing:
            label = 'Finalizing';
            break;
        case PropertyTaxStatus.InProgress:
            label = 'In Progress';
            break;
        case PropertyTaxStatus.Reimbursed:
            label = 'Reimbursed';
            break;
    }

    return (
        <Tag color={color}>{label}</Tag>
    );
});

import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Button, message } from 'antd';
import { DownOutlined, QuestionOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import type { PermissionFeature, PermissionAction } from 'models/permissions/features';
import { getPermissionEnforcementsForOrg } from 'store/selectors/permissions';
import { getSelectedOrgLongId } from 'store/selectors/org';
import { getUserId } from 'store/selectors/auth';
import { canAccess } from 'utils/permissions';

export type ActionMenuItemMap<TRecord> = { [actionKey: string] : IActionMenuItem<TRecord> };

export interface IActionMenuItem<TRecord> {
    isDivider?: boolean;
    isSubMenu?: boolean;
    items?: ActionMenuItemMap<TRecord>;
    icon?: React.ReactNode;
    text?: ((record: TRecord, actionKey: string) => string) | string;
    hidden?: (record: TRecord, actionKey: string) => boolean;
    disabled?: ((record: TRecord, actionKey: string) => boolean) | boolean;
    action?: (record: TRecord, actionKey: string) => Promise<void>;
    permission?: {
        feature: PermissionFeature,
        action: PermissionAction,
        prevent: 'hide' | 'disable',
    };
}

interface IActionsMenuProps<TRecord> {
    record: TRecord;
    actions: ActionMenuItemMap<TRecord>;
    asButton?: boolean;
    onClick?: (record: TRecord, actionKey: string) => Promise<void>;
    permission?: {
        feature: PermissionFeature,
        action: PermissionAction,
    }
}

export function ActionsMenu<TRecord>(props: React.PropsWithChildren<IActionsMenuProps<TRecord>>): JSX.Element {
    const orgId = useSelector(getSelectedOrgLongId);
    const userId = useSelector(getUserId);
    const cached = useSelector(getPermissionEnforcementsForOrg);

    if (props.permission && props.permission.feature && props.permission.action && cached && !canAccess(cached, orgId, userId, props.permission.feature, props.permission.action)) {
        return (
            <React.Fragment>-</React.Fragment>
        );
    }

    const onMenuClick = (e: MenuInfo) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();


        let topLevelKey = e.key;
        if (e.keyPath.length > 1) {
            topLevelKey = e.keyPath.reverse()[0];
        }

        let action = props.actions[topLevelKey];
        if (!action) {
            message.warning(`Unknown action clicked: ${ e.key }`);
            return;
        }

        if (action.isDivider) {
            return;
        }

        if (action.isSubMenu && typeof action.items !== 'undefined') {
            action = action.items[e.key];
        }

        if (typeof action.action === 'function') {
            action.action(props.record, e.key as string);
            return;
        }

        if (typeof props.onClick === 'function') {
            props.onClick(props.record, e.key as string);
            return;
        }

        message.warning(`The action "${ e.key }" does not have a valid action function.`);
    };

    const mapActionsToMenuObjects = (actionMap: ActionMenuItemMap<TRecord>, requireIcon: boolean): Array<ItemType> => {
        return Object.keys(actionMap).map((actionKey: string) => {
            const action = actionMap[actionKey];

            let disabled = false;

            if (action.permission && action.permission.feature && action.permission.action && cached && !canAccess(cached, orgId, userId, action.permission.feature, action.permission.action)) {
                switch (action.permission.prevent) {
                    case 'disable':
                        disabled = true;
                        break;
                    case 'hide':
                        return null;
                }
            }

            if (action.isDivider) {
                return { key: actionKey, type: 'divider' };
            }

            if (typeof action.hidden === 'function' && action.hidden(props.record, actionKey)) {
                return null;
            }

            let label = action.text as string;
            if (typeof action.text === 'function') {
                label = action.text(props.record, actionKey);
            }

            if (action.isSubMenu) {
                if (!action.items || Object.keys(action.items).length === 0) {
                    console.warn('Invalid submenu action item! At least one item is required.');
                    return null;
                }

                return {
                    key: actionKey,
                    label: (
                        <React.Fragment>
                            { action.icon ? action.icon : <QuestionOutlined /> } { label ? label : 'invalid action' }
                        </React.Fragment>
                    ),
                    children: mapActionsToMenuObjects(action.items, false),
                };
            }

            let icon = action.icon;
            if (requireIcon && !icon) {
                icon = <QuestionOutlined />;
            }

            if (typeof action.disabled === 'function' && !disabled) {
                disabled = action.disabled(props.record, actionKey)
            } else if (typeof action.disabled === 'boolean' && !disabled) {
                disabled = action.disabled;
            }

            return {
                key: actionKey,
                label: (
                    <React.Fragment>
                        { icon } { label ? label : 'invalid action' }
                    </React.Fragment>
                ),
                disabled,
            };
        });
    };

    return (
        <Dropdown menu={{ items: mapActionsToMenuObjects(props.actions, true), onClick: onMenuClick}}>
            <Button type={props.asButton ? 'default' : 'link'}>
                Actions <DownOutlined />
            </Button>
        </Dropdown>
    );
}

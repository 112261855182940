import React, { useState } from 'react';
import { Modal, Typography, message } from 'antd';

import { IOrganizationBilling } from 'models/organization';
import { displayErrorNotification } from 'utils/errors';

import { updateSubscription } from 'api/organizations';

interface IReactivateSubscriptionModalProps {
    visible: boolean;
    close: () => void;
    orgId: string;
    billingInfo: IOrganizationBilling;
}

export const ReactivateSubscriptionModal: React.FC<IReactivateSubscriptionModalProps> = (props) => {
    const [reactivating, setReactivating] = useState(false);

    const onCloseClick = () => props.close();
    const onReactivateClick = async () => {
        setReactivating(true);

        try {
            await updateSubscription(props.orgId, { resubscribe: true });

            setTimeout(() => {
                setReactivating(false);
                message.success('Subscription reactivated successfully!');

                props.close();
            }, 5000);
        } catch (e) {
            displayErrorNotification(e);
            setReactivating(false);
        }
    };

    const periodEndDate = new Date(props.billingInfo.periodEndsAt);
    const mailTo = `mailto:support@lendiom.com?subject=Subscription%20Reactivation%20Questions%20(${ encodeURIComponent(props.billingInfo.subscriptionId) })`;

    return (
        <React.Fragment>
            <Modal
                open={props.visible}
                title="Lendiom Subscription Reactivation"
                okButtonProps={{ disabled: reactivating, loading: reactivating }}
                okText="Reactivate!"
                cancelText="Nevermind"
                onCancel={onCloseClick}
                onOk={onReactivateClick}
                cancelButtonProps={{ type: 'dashed', danger: true, disabled: reactivating }}
                maskClosable={!reactivating}
                keyboard={!reactivating}
            >
                <Typography.Paragraph>Reactivating your subscription to Lendiom will resume your subscription and enable billing again.</Typography.Paragraph>
                <Typography.Paragraph>By clicking <Typography.Text strong>Reactivate</Typography.Text>, your subscription will renew on { periodEndDate.toLocaleDateString() } at { periodEndDate.toLocaleTimeString() }. If you have any questions, please send us an email at: <a href={ mailTo } target="_blank" rel="noopener noreferrer">support@lendiom.com</a></Typography.Paragraph>
            </Modal>
        </React.Fragment>
    );
}

import React from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Row, Col, Card, Form, FormInstance, List, Divider, Input, Select, Button, ColProps } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

import { AccessControlledWrapper } from 'components/permissions/acWrapperComponent';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { breadCrumbItemRender } from 'utils/breadCrumbs';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

import { AddEditEntityDrawer } from 'components/entity/addEditDrawer';
import { EntityInfoCard } from 'components/entity/infoCard';

import { ClientType, ClientStatus, ClientBillingAllowedPaymentMethods } from 'models/client';
import { IEntity } from 'models/entity';
import { IClientCreationPayload } from 'models/payloads/clients';
import { Language } from 'models/common/language';

import { createClient } from 'api/clients';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

interface IFormValues {
    displayName: string;
    type: ClientType;
    status: ClientStatus;
    language: Language;
    paymentMethods: ClientBillingAllowedPaymentMethods[];
}

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
});

interface INewClientProps extends ReturnType<typeof mapStateToProps>, RouteComponentProps<IOrgIdPathParams> { }

interface INewClientFormState {
    valueChangeEventId: string;
    isSaving: boolean;
    addEntityDrawerVisible: boolean;
    editEntityDrawerVisible: boolean;
    entities: IEntity[];
    editingEntity?: IEntity;
}

class NewClientFormBase extends React.PureComponent<INewClientProps, INewClientFormState> {
    state: Readonly<INewClientFormState> = {
        valueChangeEventId: '',
        isSaving: false,
        addEntityDrawerVisible: false,
        editEntityDrawerVisible: false,
        entities: [],
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    valuesChanged = () => {
        this.setState({ valueChangeEventId: shortid.generate() });
    }

    handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        try {
            const values = await this.formRef.current!.validateFields();

            this.setState({ isSaving: true }, async () => {
                try {
                    const payload: IClientCreationPayload = {
                        client: {
                            displayName: values.displayName,
                            type: values.type,
                            status: values.status,
                            preferences: {
                                language: values.language,
                            },
                            billing: {
                                allowedPaymentMethods: values.paymentMethods,
                            },
                        },
                        entities: this.state.entities.map((e) => {
                            e.id = '';

                            return e;
                        }),
                    };

                    const { orgId } = this.props.match.params;
                    const client = await createClient(orgId, payload);
                    trackUmami('Create Client');

                    this.props.history.push(`/${ orgId }/clients/${ client.id }`);
                } catch (e) {
                    displayErrorNotification(e);
                    this.setState({ isSaving: false });
                }
            });
        } catch (e) {
            console.warn('client creation form validation failed:', e);
        }
    }

    //#region client display name input
    get displayName() {
        return (
            <Form.Item
                name="displayName"
                label="Display Name"
                extra="Display name of the client is what is displayed since clients are made up of several entities (individuals, family, or business)."
                rules={[{ required: true, message: 'Display name is required.' }]}
            >
                <Input
                    name="displayName"
                    placeholder="Client's display name"
                    disabled={this.state.isSaving}
                />
            </Form.Item>
        );
    }
    //#endregion

    //#region client type selector
    get clientType() {
        return (
            <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the type of client.' }]}>
                <Select placeholder="Please select a type">
                    <Select.Option value={ClientType.Individual}>Individual</Select.Option>
                    <Select.Option value={ClientType.Family}>Family</Select.Option>
                    <Select.Option value={ClientType.Company}>Company</Select.Option>
                </Select>
            </Form.Item>
        );
    }
    //#endregion

    //#region client status selector
    get clientStatus() {
        return (
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please state the status of client.' }]}>
                <Select placeholder="Select the status">
                    <Select.Option value={ClientStatus.Active}>Active</Select.Option>
                    <Select.Option value={ClientStatus.Inactive}>Inactive</Select.Option>
                    <Select.Option value={ClientStatus.Prospect}>Prospect</Select.Option>
                    <Select.Option value={ClientStatus.DoNotContact}>Do Not Contact</Select.Option>
                </Select>
            </Form.Item>
        );
    }
    //#endregion

    //#region client language selector
    get clientLanguage() {
        return (
            <Form.Item
                name="language"
                label="Language"
                rules={[{ required: true, message: 'Please select the client\'s preferred language.' }]}
                extra="For any automated communication, which language should we use to communicate with the client?"
            >
                <Select placeholder="Select a language">
                    <Select.Option value={Language.English}>English <span role="img" aria-label="USA Flag">🇺🇸</span></Select.Option>
                    <Select.Option value={Language.Spanish}>Spanish <span role="img" aria-label="Mexico Flag">🇲🇽</span></Select.Option>
                </Select>
            </Form.Item>
        );
    }
    //#endregion

    get clientMethods() {
        return (
            <Form.Item
                name="paymentMethods"
                label="Allowed Payment Methods"
                rules={[{
                    required: true,
                    message: 'At least one payment method is required for online payments.',
                    type: 'array',
                    min: 1,
                }]}
                extra="When paying online, which payment methods are they allowed to use? You must select at least one."
            >
                <Select mode="multiple">
                    <Select.Option value={ClientBillingAllowedPaymentMethods.BankAccounts}>Bank Accounts</Select.Option>
                    <Select.Option value={ClientBillingAllowedPaymentMethods.CreditDebitCards}>Credit/Debit Cards</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    //#region entity divider
    get entityDivider() {
        const type = this.formRef.current ? this.formRef.current.getFieldValue('type') : '';
        let label = 'Entities';

        switch (type) {
            case ClientType.Individual:
                label = 'Individual';
                break;
            case ClientType.Family:
                label = 'Family Members';
                break;
            case ClientType.Company:
                label = 'Company Members';
                break;
        }

        const buttonDisabled = !type || (type === ClientType.Individual && this.state.entities.length >= 1);

        return (
            <Row>
                <Col span={14}>
                    <Divider orientation="left">
                        Client {label}&nbsp;&nbsp;&nbsp;
                        <Button size="small" type="dashed" shape="round" icon={<PlusOutlined />} disabled={buttonDisabled} onClick={this.addEntityClicked}>
                            Add
                        </Button>
                    </Divider>
                </Col>

                <AddEditEntityDrawer clientType={type} save={false} isVisible={this.state.addEntityDrawerVisible} close={this.addEntityDrawerClosed} />
                <AddEditEntityDrawer entity={this.state.editingEntity} clientType={type} save={false} isVisible={this.state.editEntityDrawerVisible} close={this.editEntityDrawerClosed} />
            </Row>
        );
    }
    //#endregion

    //#region entities
    addEntityClicked = () => {
        this.setState({ addEntityDrawerVisible: true });
    }

    addEntityDrawerClosed = async (entity?: Partial<IEntity>) => {
        this.setState({ addEntityDrawerVisible: false });

        if (entity) {
            this.setState({ entities: this.state.entities.concat(entity as IEntity) });
        }
    }

    editEntityDrawerClosed = async (entity?: Partial<IEntity>) => {
        this.setState({ editEntityDrawerVisible: false, editingEntity: undefined });

        if (entity) {
            const { entities } = this.state;
            const index = entities.findIndex((e) => e.id === entity.id);
            if (index >= 0) {
                entities[index] = entity as IEntity;
                this.setState({ entities: Array.from(entities) });
            }
        }
    }

    entityCardMenuItemClicked = (which: 'edit' | 'delete' | 'details', entity: IEntity) => {
        if (which === 'delete') {
            this.setState({ entities: this.state.entities.filter((e) => e.id !== entity.id) });
            return;
        } else if (which === 'edit') {
            this.setState({ editEntityDrawerVisible: true, editingEntity: entity });
            return;
        }
    }

    get entityCards() {
        return (
            <List<IEntity>
                grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
                dataSource={this.state.entities}
                renderItem={(entity) => (
                    <List.Item>
                        <EntityInfoCard
                            entity={entity}
                            genericTitle={false}
                            menuItemClicked={this.entityCardMenuItemClicked}
                            showViewMenuItem={false}
                        />
                    </List.Item>
                )}
            />
        );
    }
    //#endregion

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.match.params.orgId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ this.props.match.params.orgId }/clients`,
                    breadcrumbName: `${ this.props.selectedOrg.name }'s Clients`
                },
                {
                    path: `/${ this.props.match.params.orgId }/client/new`,
                    breadcrumbName: 'New Client',
                },
            ],
        };
    }

    render() {
        const headerProps: PageContainerProps = {
            title: 'New Client Creation',
            extra: <Button type="primary" onClick={this.handleSubmit} icon={<SaveOutlined />} disabled={this.state.isSaving} loading={this.state.isSaving}>Save</Button>,
            onBack: () => this.props.history.push(`/${ this.props.match.params.orgId }/clients`),
            breadcrumb: this.breadcrumbProps,
        };

        const labelCol: ColProps = {
            xs: { span: 24 }, sm: { span: 8 }, lg: { span: 5 }, xl: { span: 4 },
        };

        const wrapperCol: ColProps = {
            xs: { span: 24 }, sm: { span: 16 }, lg: { span: 12, offset: 1 }, xl: { span: 6, offset: 0 },
        };

        return (
            <PageContainer {...headerProps}>
                <Row style={{ marginTop: 24 }}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <Row>
                                <Col span={14}>
                                    <Divider orientation="left">Client Information</Divider>
                                </Col>
                            </Row>

                            <Form<IFormValues>
                                ref={this.formRef}
                                onValuesChange={this.valuesChanged}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                colon={false}
                                initialValues={{
                                    type: ClientType.Family,
                                    status: ClientStatus.Active,
                                    language: Language.English,
                                    paymentMethods: [ClientBillingAllowedPaymentMethods.BankAccounts, ClientBillingAllowedPaymentMethods.CreditDebitCards],
                                }}
                            >
                                {this.displayName}
                                {this.clientType}
                                {this.clientStatus}
                                {this.clientLanguage}
                                {this.clientMethods}

                                {this.entityDivider}

                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                        {this.entityCards}
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </PageContainer>
        );
    }
}

const NewClientFormWithProps = connect(mapStateToProps)(withRouter(NewClientFormBase));

export const NewClient: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Client}
        action={PermissionAction.Create}
        children={<NewClientFormWithProps />}
    />
);

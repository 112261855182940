import type { GlobalState } from 'store';
import { PermissionAction } from 'models/permissions/features';
import type { IFeatureAccessPermissions, PermissionFeature } from 'models/permissions/features';
import { serializeEnforceRequest } from 'utils/permissions';

export const getPermissionsLoading = (state: GlobalState) => state.permission.isLoading;
export const getArePermissionsLoaded = (state: GlobalState) => typeof state.permission.myPermissions !== 'undefined';
export const getArePermissionLoadedForOrg = (state: GlobalState) => typeof state.permission.myPermissions !== 'undefined' && !!state.permission.cachedEnforcements && typeof state.permission.cachedEnforcements[state.org.selectedOrgLongId] !== 'undefined';
export const getPermissionEnforcementsForOrg = (state: GlobalState) => state.permission.cachedEnforcements && state.permission.cachedEnforcements[state.org.selectedOrgLongId];
export const hasAccessTo = (feature: PermissionFeature, action: PermissionAction) => (state: GlobalState) => {
    const key = serializeEnforceRequest(state.org.selectedOrgLongId, state.auth.userId, feature, action);

    if (!state.permission.cachedEnforcements) {
        return false;
    }

    if (!state.permission.cachedEnforcements[state.org.selectedOrgLongId]) {
        return false;
    }

    return state.permission.cachedEnforcements[state.org.selectedOrgLongId][key];
};

export const hasAccessToFeature = (feature: PermissionFeature) => (state: GlobalState): IFeatureAccessPermissions => {
    const result: IFeatureAccessPermissions = {
        create: false,
        read: false,
        update: false,
        delete: false,
    };

    if (!state.permission.cachedEnforcements || !state.permission.cachedEnforcements[state.org.selectedOrgLongId]) {
        return result;
    }

    Object.values(PermissionAction).forEach((action) => {
        const key = serializeEnforceRequest(state.org.selectedOrgLongId, state.auth.userId, feature, action);
        result[action] = state.permission.cachedEnforcements![state.org.selectedOrgLongId][key] || false;
    });

    return result;
};

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    InfoCircleOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import { Card, Button, Dropdown } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IEntity } from 'models/entity';
import { formatPhoneNumberForDisplay, formatPhoneNumberForLink } from 'utils/numberFormatting';

interface IEntityCardPropsType extends RouteComponentProps {
    showViewMenuItem: boolean,
    entity: IEntity,
    genericTitle: boolean,
    menuItemClicked: (which: 'edit' | 'delete' | 'details', entity: IEntity) => void,
}

interface IEntityCardState {}

class EntityInfoCardBase extends React.PureComponent<IEntityCardPropsType> {
    state: Readonly<IEntityCardState> = {}

    onActionMenuClick = (e: MenuInfo): void => {
        switch (e.key) {
            case 'details':
            case 'edit':
            case 'delete':
                this.props.menuItemClicked(e.key, this.props.entity);
                break;
            default:
                return;
        }
    }

    get entityActionsMenu() {
        const items: Array<ItemType> = [
            {
                key: 'edit',
                label: (
                    <React.Fragment>
                        <EditOutlined /> Edit
                    </React.Fragment>
                ),
            },
            {
                key: 'delete',
                label: (
                    <React.Fragment>
                        <DeleteOutlined /> Delete
                    </React.Fragment>
                ),
            },
        ];

        if (this.props.showViewMenuItem) {
            items.unshift({
                key: 'details',
                disabled: true,
                label: (
                    <React.Fragment>
                        <InfoCircleOutlined /> View
                    </React.Fragment>
                ),
            });
        }

        return items;
    }

    get entityActionsButton() {
        return (
            <Dropdown key="operations-dropdown" menu={{ items: this.entityActionsMenu, onClick: this.onActionMenuClick  }} trigger={['click', 'hover']}>
                <Button type="dashed" size="small" icon={<ToolOutlined />}>Actions <DownOutlined /></Button>
            </Dropdown>
        );
    }

    get entityPrimaryAddress() {
        const { addresses } = this.props.entity;

        if (!addresses || addresses.length === 0) {
            return (
                <div>
                    <span>No addresses</span>
                </div>
            );
        }

        const adr = addresses[0];

        return (
            <div>
                {adr.streetAddresses.length >= 1 ? adr.streetAddresses[0] : '' }<br />
                {adr.streetAddresses.length >= 2 ? <span>{adr.streetAddresses[1]} <br /></span> : '' }
                {adr.city}, {adr.state} {adr.zipCode}{adr.plus4Code ? '-' + adr.plus4Code : ''}
            </div>
        );
    }

    get entityPrimaryPhoneNumber() {
        const { phoneNumbers } = this.props.entity;

        return (
            <div>
                {!phoneNumbers || phoneNumbers.length === 0
                    ?
                    <span>No phone numbers</span>
                    :
                    <a href={'tel:' + formatPhoneNumberForLink(phoneNumbers[0].number)}>{formatPhoneNumberForDisplay(phoneNumbers[0].number)}</a>
                }
            </div>
        );
    }

    get taxIdInformation() {
        const { taxInfo } = this.props.entity;

        return (
            <div>
                {!taxInfo || !taxInfo.type || !taxInfo.short
                    ? <span>No tax information</span>
                    : <span style={{ textTransform: 'uppercase' }}>{ taxInfo.type }: ***{ taxInfo.short }</span>
                }
            </div>
        );
    }

    get entityInformation() {
        return (
            <div>
                {this.taxIdInformation}
                {this.entityPrimaryAddress}
                {this.entityPrimaryPhoneNumber}
            </div>
        );
    }

    getTitle(): string {
        let title = "Entity Info";
        if (this.props.entity.fullName) {
            title = this.props.entity.fullName;
        } else if (this.props.entity.firstName && this.props.entity.lastName) {
            title = this.props.entity.firstName + ' ' + this.props.entity.lastName;
        }

        return title;
    }

    render() {
        return (
            <Card
                title={this.getTitle()}
                bordered={false}
                extra={this.entityActionsButton}
            >
                <Card.Meta
                    title={this.props.entity.fullName}
                    description={this.entityInformation}
                />
            </Card>
        );
    }
}

export const EntityInfoCard = withRouter(EntityInfoCardBase);

import React, { useState } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import currency from 'currency.js';
import { Row, Col, DatePicker, Form, Input, Modal, notification } from 'antd';

import type { IPropertyTax, IPropertyTaxForTract } from 'models/propertyTax';
import { displayErrorNotification } from 'utils/errors';

import { payPropertyTax } from 'api/propertyTaxes';

interface IFormValues {
    amount: string;
    date: Dayjs | null;
}

interface IRecordTaxPaymentModalProps {
    visible: boolean;
    propertyTax?: IPropertyTax;
    tractRecord?: IPropertyTaxForTract;
    close: (saved: boolean) => Promise<void>;
}

export const RecordTaxPaymentModal: React.FC<IRecordTaxPaymentModalProps> = (props) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm<IFormValues>();

    const onCloseClick = () => {
        props.close(false);
    };

    const onSaveClick = async () => {
        if (!props.propertyTax || !props.tractRecord) {
            return;
        }

        const values = await form.validateFields();

        if (!values.date) {
            return;
        }

        setSaving(true);

        try {
            await payPropertyTax(props.propertyTax.organization.id, props.propertyTax.inventoryId, props.propertyTax.id, {
                tractId: props.tractRecord.tractId,
                amount: values.amount,
                date: values.date?.toJSON(),
            });

            notification.success({ message: 'Successfully recorded the property tax payment.' });
            await props.close(true);
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            title="Record Tax Payment"
            open={props.visible}
            okText="Save"
            onOk={onSaveClick}
            onCancel={onCloseClick}
            okButtonProps={{ disabled: saving, loading: saving }}
            cancelButtonProps={{ disabled: saving }}
            maskClosable={false}
            closable={!saving}
        >
            <Form<IFormValues>
                form={form}
                layout="vertical"
                initialValues={{
                    amount: props.tractRecord ? currency(props.tractRecord.amountOwed, { precision: 2 }).toString() : '0.00',
                    date: dayjs(),
                }}
            >
                <Row>
                    <Col span={11}>
                        <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please state how much they paid!' }]}>
                            <Input
                                prefix="$"
                                style={{ width: '100%' }}
                                disabled={saving}
                            />
                        </Form.Item>
                    </Col>
                    <Col offset={1} span={12}>
                        <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please set the date they paid it!' }]}>
                            <DatePicker
                                format="MM/DD/YYYY"
                                disabled={saving}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

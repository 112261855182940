import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';

import { NotificationsView } from '../notifications';
import { OrganizationStatusAlert } from './orgStatusAlert';

import type { IUser } from 'models/user';

export function getHeaderActions(user?: IUser): JSX.Element[] {
    const result: JSX.Element[] = [
        <OrganizationStatusAlert key="org-status-alert" />,
    ];

    if (user) {
        result.push(<NotificationsView />);
    } else {
        result.push(<Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />);
    }

    result.push(
        <Tooltip title="Help" placement="bottom">
            <a href={`${process.env.REACT_APP_DOCS_URL}`} target="_blank" rel="noopener noreferrer" className="action" >
                <QuestionCircleOutlined />
            </a>
        </Tooltip>
    );

    return result;
}

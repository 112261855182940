export const trackUmami = (eventValue: string) => {
    const { umami } = global as any;

    if (typeof umami !== 'function') {
        return;
    }

    try {
        umami(eventValue);
    } catch (e) {
        console.warn('failed to track an event with Umami', e);
    }
};

import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';

export interface IFillablePDF extends IObjectDates, IOrganizational {
    id: string;
    name: string;
    description: string;
    for: FillablePDFFor;
    uploadedFileId: string;

    fields: IFillablePDFField[];
}

export enum FillablePDFFor {
    CashLoan = 'cash-loan',
    TractLoan = 'tract-loan',
    Client = 'client',
    Tract = 'tract',
}

export interface IFillablePDFField {
    name: string;
    description: string;

    mappedProperty: string;
    type: FillablePDFFieldType;
    dateFormat: FillablePDFFieldDateFormat;

    //for internal usage only
    mappedPropertyArray: any[];
}

export enum FillablePDFFieldType {
    Text = 'text',
    Currency = 'currency',
    Percentage = 'percentage',
    Date = 'date',
}

export enum FillablePDFFieldDateFormat {
    DayOfMonth = 'day-of-month', // 15th
    FormalDate = 'formal-date', // May 15th, 2021
    FullDate = 'full-date', // 5/15/2021
    Year = 'year', // 2021
}

export interface IExecuteFillablePDFPayload {
    for: FillablePDFFor;
    forId: string;
}

import { get, post, put } from './index';

import { IUser } from '../models/user';
import { IUserVerifyEmailPayload, IUserForgotPasswordPayload, IUserResetPasswordPayload } from '../models/payloads/userLogin';
import { IUserRegistrationPayload, IUserRegistrationResult } from '../models/payloads/userProfile';
import { IUserLoginPayload, IUserLoginResult } from '../models/payloads/userLogin';

export const registerUser = (payload: IUserRegistrationPayload) => post<IUserRegistrationPayload, IUserRegistrationResult>('/user', payload, true);
export const loginUser = (payload: IUserLoginPayload) => post<IUserLoginPayload, IUserLoginResult>('/user/login', payload, true);
export const resendEmailVerification = () => get<void>('/user/verify-email', true);
export const verifyEmail = (payload: IUserVerifyEmailPayload) => post<IUserVerifyEmailPayload, any>('/user/verify-email', payload, true);
export const requestPasswordReset = (payload: IUserForgotPasswordPayload) => post<IUserForgotPasswordPayload, any>('/user/reset-password', payload, false);
export const resetPassword = (payload: IUserResetPasswordPayload) => put<IUserResetPasswordPayload, IUserLoginResult>('/user/reset-password', payload, false);

export const getUserLoggedIn = () => get<IUser>('/user', true);

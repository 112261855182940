import React from 'react';
import currency from 'currency.js';
import { Card, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { getUpcomingPayments } from 'api/dashboard';
import { IUpcomingLoanPayment } from 'models/dashboard';

import { ShortDate } from 'utils/formatting';
import { displayErrorNotification } from 'utils/errors';

interface IUpcomingPaymentsCardProps {
    orgId: string;
    orgShortId: string;
}

interface IUpcomingPaymentsCardState {
    loading: boolean;
    payments: IUpcomingLoanPayment[];
}

const pageSize = 5;

export class UpcomingPaymentsCard extends React.PureComponent<IUpcomingPaymentsCardProps, IUpcomingPaymentsCardState> {
    state: Readonly<IUpcomingPaymentsCardState> = {
        loading: true,
        payments: [],
    };

    columns: ColumnProps<IUpcomingLoanPayment>[] = [
        {
            title: 'Due Date', dataIndex: ['nextDueDate'], key: 'nextDueDate',
            render: (date: string) => <ShortDate value={date} />,
        },
        {
            title: 'Loan', dataIndex: ['loan', 'label'], key: 'loanLabel',
            render: (loanLabel: string, trans) => <Link to={`/${ this.props.orgShortId }/loans/${ trans.loan.id }`}>{ loanLabel }</Link>
        },
        {
            title: 'Partially Paid', dataIndex: ['nextPayment', 'isPartiallyPaid'], key: 'isPartiallyPaid', align: 'center',
            render: (isPartiallyPaid: boolean) => isPartiallyPaid ? 'Yes' : 'No',
        },
        {
            title: 'Amt Due', dataIndex: ['nextPayment', 'payment'], key: 'paymentAmount', align: 'right',
            render: (amount: string, payment) => {
                const amtDue = currency(amount, { precision: 2 }).subtract(payment.nextPayment.paymentReceived).format(true);

                const overlay = (
                    <React.Fragment>
                        Payment amount: { currency(amount, { precision: 2 }).format(true) }<br />
                        Received amount: { currency(payment.nextPayment.paymentReceived, { precision: 2 }).format(true) }
                    </React.Fragment>
                );

                return (
                    <Tooltip overlay={overlay}>
                        { amtDue }
                    </Tooltip>
                );
            },
        }
    ];

    componentDidMount() {
        if (this.props.orgId) {
            this.loadUpcomingPayments();
        }
    }

    componentDidUpdate(prevProps: IUpcomingPaymentsCardProps) {
        if (prevProps.orgId === this.props.orgId) {
            return;
        }
        this.loadUpcomingPayments();
    }

    loadUpcomingPayments = () => {
        this.setState({ loading: true }, async () => {
            try {
                const payments = await getUpcomingPayments(this.props.orgId);

                this.setState({ payments });
            } catch (e) {
                displayErrorNotification(e, this.loadUpcomingPayments);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    render() {
        return (
            <Card loading={this.state.loading} bordered={false} title="Upcoming Loan Payments*" style={{ height: '100%' }}>
                <Table<IUpcomingLoanPayment>
                    size="small"
                    columns={this.columns}
                    dataSource={this.state.payments}
                    rowKey={(r) => r.loan.id}
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        style: { marginBottom: 0 },
                        pageSize,
                    }}
                />

                <em style={{ position: 'relative', top: '-25px', paddingLeft: '8px', fontSize: '12px' }}>*excludes draft and in-active loans</em>
            </Card>
        );
    }
}

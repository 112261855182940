import React from 'react';

import { IEntity } from 'models/entity';

import { Address } from 'components/misc/address';

interface IEntityAddressProps {
    entity: IEntity;
    showTags: boolean;
}

export const EntityAddress: React.FC<IEntityAddressProps> = React.memo((props) => {
    const entity = props.entity;

    if (!Array.isArray(entity.addresses) || entity.addresses.length <= 0) {
        return (
            <div style={{ color: '#ccc' }}>No addresses yet.</div>
        );
    }

    return (
        <Address value={entity.addresses[0]} showTags={props.showTags} />
    );
});

import { ThunkDispatch } from 'redux-thunk';
import { authLogout } from './auth';

import { GlobalState } from './../index';
import { AuthLogoutAction } from './../types/auth';

import { IRestError } from './../../models/common/restResult';

export const checkErrorForUnauthorized = async (e: IRestError, dispatch: ThunkDispatch<GlobalState, null, AuthLogoutAction>) => {
    if (e.status === 401) {
        await dispatch(authLogout());
    }
}

import React from 'react';
import currency from 'currency.js';
import shortid from 'shortid';
import debounce from 'lodash.debounce';
import { DispatchProp } from 'react-redux';
import { GiftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Form, List, Input, Button, Card, notification } from 'antd';

import { ICoupon } from 'models/payment/coupon';
import { IOrganization } from 'models/organization';
import { IOrganizationSubscribePayload } from 'models/payloads/organization';

import { getCoupon } from 'api/coupon';
import { createSubscription } from 'api/organizations';

import { displayErrorNotification } from 'utils/errors';
import { orgSelect } from 'store/actions/org';

interface IOrgCreationThirdStepProps extends DispatchProp {
    onSuccess: () => void;
    organization: IOrganization;
}

interface IOrgCreationThirdStepState {
    isSaving: boolean;
    selectedPlan: 'Monthly' | 'Yearly' | '';
    couponValue: string;
    couponTouched: boolean;
    couponIsValid: boolean;
    verifyingCoupon: boolean;
    coupon?: ICoupon;
}

export class OrgCreationThirdStep extends React.PureComponent<IOrgCreationThirdStepProps, IOrgCreationThirdStepState> {
    private couponInputName = shortid.generate();

    state: Readonly<IOrgCreationThirdStepState> = {
        isSaving: false,
        selectedPlan: '',
        couponValue: '',
        couponTouched: false,
        couponIsValid: false,
        verifyingCoupon: false,
    }

    constructor(props: IOrgCreationThirdStepProps) {
        super(props);

        this.onCouponChange = debounce(this.onCouponChange, 1000).bind(this);
    }

    onPlanSelection = (which: 'Monthly' | 'Yearly' | '') => {
        if (this.state.isSaving) {
            return;
        }

        this.setState({ selectedPlan: which });
    }

    getPlanTitle = (which: 'Monthly' | 'Yearly') => {
        const monthlyCost = currency(50);
        const yearlyCost = monthlyCost.multiply(currency(12));
        const { coupon } = this.state;
        if (!coupon) {
            return <div>${ which === 'Monthly' ? monthlyCost.format(false) : yearlyCost.format(false) } { which }</div>;
        }

        const isPercentage = coupon.amountOff === '0';
        const percentageOffPerMonth = currency(1).subtract(currency(coupon.percentOff));
        const exactOffPerMonth = currency(coupon.amountOff);

        if (which === 'Monthly') {
            let monthlyPrice = percentageOffPerMonth.multiply(monthlyCost).format(false);
            if (!isPercentage) {
                monthlyPrice = monthlyCost.subtract(exactOffPerMonth).format(false);
            }

            return (
                <div>
                    <span className="striked">${ monthlyCost.format(false) }</span> ${ monthlyPrice } { which }*
                </div>
            );
        }

        let yearlyPrice = '';
        if (coupon.forever) {
            if (isPercentage) {
                yearlyPrice = currency(12).multiply(percentageOffPerMonth).multiply(monthlyCost).format(false);
            } else {
                yearlyPrice = monthlyCost.subtract(exactOffPerMonth).multiply(12).format(false);
            }
        } else {
            if (isPercentage) {
                const durationRegularCost = currency(coupon.durationInMonths).multiply(monthlyCost);
                yearlyPrice = currency(yearlyCost).subtract(durationRegularCost.subtract(durationRegularCost.multiply(percentageOffPerMonth))).format(false);
            } else {
                let amountOff = exactOffPerMonth.multiply(coupon.durationInMonths);
                if (coupon.durationInMonths > 12) {
                    amountOff = exactOffPerMonth.multiply(12);
                }

                yearlyPrice = currency(yearlyCost).subtract(amountOff).format(false);
            }
        }

        return (
            <div>
                <span className="striked">${ yearlyCost.format(false) }</span> ${ yearlyPrice } { which }*
            </div>
        );
    };

    //25-off-3months
    //25-off-4ever

    getPlanPricingDetails = (which: 'Monthly' | 'Yearly') => {
        const { coupon } = this.state;
        if (!coupon) {
            return null;
        }

        if (coupon.forever) {
            return (
                <List.Item>
                    <em>* until the subscription is cancelled</em>
                </List.Item>
            );
        }

        if (which === 'Monthly') {
            return (
                <List.Item>
                    <em>* for the first { coupon.durationInMonths } months, $50 each month after</em>
                </List.Item>
            );
        }

        return (
            <List.Item>
                <em>* for the first year, $600 each year after</em>
            </List.Item>
        );
    }

    onCouponChange = (couponId: string) => {
        const stateUpdate: IOrgCreationThirdStepState = {
            ...this.state,
            couponTouched: true,
            couponValue: couponId,
        };

        if (!couponId) {
            stateUpdate.coupon = undefined;
            this.setState(stateUpdate);
            return;
        }

        stateUpdate.verifyingCoupon = true;
        this.setState(stateUpdate, async () => {
            try {
                const c = await getCoupon(couponId);

                stateUpdate.coupon = c;
                if (c.enabled) {
                    stateUpdate.couponIsValid = true;
                } else {
                    stateUpdate.couponIsValid = false;
                }
            } catch (e) {
                stateUpdate.couponIsValid = false;
                stateUpdate.coupon = undefined;
            }

            stateUpdate.verifyingCoupon = false;
            this.setState(stateUpdate);
        });
    }

    getCouponValidateStatus = () => {
        if ((!this.state.couponTouched || !this.state.couponValue) || this.state.verifyingCoupon) {
            return '';
        }

        if (this.state.couponIsValid) {
            return 'success';
        }

        return 'error';
    }

    getCouponHelp = () => {
        if ((!this.state.couponTouched || !this.state.couponValue) || this.state.verifyingCoupon) {
            return '';
        }

        if (this.state.couponIsValid && this.state.coupon) {
            return this.state.coupon.name;
        }

        return 'Invalid coupon.';
    }

    onSignUpClick = () => {
        const { selectedPlan, coupon } = this.state;

        if (!selectedPlan) {
            notification.warning({ message: 'No plan has been selected, please select one to sign up.' })
            return;
        }

        this.setState({ isSaving: true }, async () => {
            const payload: IOrganizationSubscribePayload = {
                plan: selectedPlan,
                coupon: coupon ? coupon.id : '',
            };

            try {
                await createSubscription(this.props.organization.id, payload);
                await this.props.dispatch(orgSelect(this.props.organization.id) as any);
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ isSaving: false });
                return;
            }

            notification.success({ message: 'Sign up completed. Welcome to Lendiom!' });
            this.props.onSuccess();
        });
    }

    get planCards() {
        return (
            <Row justify="space-between" gutter={32} className="plan-selections">
                <Col span={12}>
                    <Card
                        title={this.getPlanTitle('Monthly')}
                        bordered={false}
                        className={this.state.selectedPlan === 'Monthly' ? 'active' : ''}
                        actions={[ <Button type={this.state.selectedPlan === 'Monthly' ? 'primary' : 'default'} key="select-monthly" onClick={() => this.onPlanSelection('Monthly')} disabled={this.state.verifyingCoupon || this.state.isSaving}>Select Monthly</Button> ]}
                    > { /* onClick={() => this.onPlanSelection('Monthly')}> */ }
                        <List bordered={false} pagination={false} size="small">
                            <List.Item>- 31 day trial</List.Item>
                            <List.Item>- unlimited inventories</List.Item>
                            <List.Item>- unlimited clients</List.Item>
                            <List.Item>- 100 active loans</List.Item>
                            <List.Item>- 50GB data storage</List.Item>
                            {this.getPlanPricingDetails('Monthly')}
                        </List>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title={this.getPlanTitle('Yearly')}
                        bordered={false}
                        className={this.state.selectedPlan === 'Yearly' ? 'active' : ''}
                        actions={[ <Button type={this.state.selectedPlan === 'Yearly' ? 'primary' : 'default'} key="select-yearly" onClick={() => this.onPlanSelection('Yearly')} disabled={this.state.verifyingCoupon || this.state.isSaving}>Select Yearly</Button> ]}
                    >{ /* </Col>onClick={() => this.onPlanSelection('Yearly')}> */ }
                        <List bordered={false} pagination={false} size="small">
                            <List.Item>- 31 day trial</List.Item>
                            <List.Item>- unlimited inventories</List.Item>
                            <List.Item>- unlimited clients</List.Item>
                            <List.Item>- 100 active loans</List.Item>
                            <List.Item>- 50GB data storage</List.Item>
                            {this.getPlanPricingDetails('Yearly')}
                        </List>
                    </Card>
                </Col>
            </Row>
        );
    }

    get planBottomRow() {
        let disabled = this.state.isSaving || this.state.selectedPlan === '' || this.state.verifyingCoupon;
        if (this.state.couponValue && !this.state.couponIsValid) {
            disabled = true;
        }

        return (
            <Row gutter={32}>
                <Col span={12} className="coupon">
                    <Form.Item validateStatus={this.getCouponValidateStatus()} help={this.getCouponHelp()}>
                        <Input
                            size="large"
                            placeholder="Coupon"
                            onChange={(e) => this.onCouponChange(e.target.value.trim())}
                            autoComplete={this.couponInputName}
                            addonAfter={this.state.verifyingCoupon ? <LoadingOutlined /> : <GiftOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} offset={6}>
                    <Button
                        key="submit"
                        size="large"
                        type="primary"
                        onClick={this.onSignUpClick}
                        disabled={disabled}
                        loading={this.state.isSaving}>Start Trial</Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Layout>
                {this.planCards}
                {this.planBottomRow}
            </Layout>
        );
    }
}

import { IObjectDates } from "./common/objectDates";
import { IOrganizational } from "./common/organizational";
import { Currency } from "./currency";

export interface IEscrow {
    balance: Currency;
    lastEntryDate?: string;
    paymentAmount: Currency;
    applicationStep: EscrowApplicationStep;
}

export enum EscrowApplicationStep {
    BeforeFees = 'before-fees',
    BeforeInterest = 'before-interest',
    AfterPrincipal = 'after-principal',
}

export interface IEscrowEntry extends IObjectDates, IOrganizational {
    amount: Currency;
    type: EscrowEntryType;
    status: EscrowEntryStatus;
    date: string;
    balance: Currency;
    description: string;
    comment: string;
    commentText: string;
}

export enum EscrowEntryType {
    Credit = 'credit',
    Disbursement = 'disbursement',
}

export enum EscrowEntryStatus {
    Pending = 'pending',
    Success = 'success',
    Failure = 'failure',
    Reversed = 'reversed',
}

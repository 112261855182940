import { ThunkAction } from 'redux-thunk';

import type { ILookup } from 'models/common/lookup';
import type { IRestError } from 'models/common/restResult';
import type { IRestRequestFilter } from 'models/common/restRequest';
import type { IInventory, InventoryType } from 'models/inventory';
import { InventoryCategory } from 'models/inventory';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { getInventories, getInventoryByID } from 'api/inventories';

import {
    InventoryActionType,
    InventoriesFetchPendingAction,
    InventoriesFetchSuccessCacheAction,
    InventoriesFetchSuccessAction,
    InventoriesFetchFailureAction,
    InventoryCreatedEventRecievedAction,
    InventoryUpdatedEventRecievedAction,
    InventoryDeletedEventRecievedAction,
    InventoryFetchOneAction,
    InventoryFetchPendingAction,
    InventoryFetchSuccessAction,
    InventoryFetchFailureAction,
} from 'store/types/inventories';

export const fetchLandInventories = (searchValue?: string, type?: InventoryType): ThunkAction<Promise<InventoriesFetchSuccessAction | InventoriesFetchSuccessCacheAction | InventoriesFetchFailureAction>, GlobalState, null, InventoriesFetchPendingAction | InventoriesFetchSuccessCacheAction | InventoriesFetchSuccessAction | InventoriesFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(inventoriesFetchingPending());

        try {
            const filters: IRestRequestFilter[] = [
                { key: 'category', value: InventoryCategory.Land },
            ];

            if (type) {
                filters.push({ key: 'type', value: type });
            }

            const inventories = await getInventories(getSelectedOrgShortId(getState()), {
                limit: 100,
                search: searchValue || undefined,
                filters,
            });

            return dispatch(inventoriesFetchingSuccess(inventories.data));
        } catch (e) {
            return dispatch(inventoriesFetchingFailure(e as IRestError));
        }
    }
}

export const fetchInventory = (inventoryId: string): ThunkAction<Promise<InventoryFetchOneAction>, GlobalState, null, InventoryFetchOneAction> => {
    return async (dispatch, getState) => {
        dispatch(inventoryFetchingPending(inventoryId));

        try {
            const inventory = await getInventoryByID(getSelectedOrgShortId(getState()), inventoryId);
            return dispatch(inventoryFetchingSuccess(inventory));
        } catch (e) {
            return dispatch(inventoryFetchingFailure(e as IRestError));
        }
    }
}

export function inventoriesFetchingPending(): InventoriesFetchPendingAction {
    return {
        type: InventoryActionType.FETCHING_MULTIPLE_PENDING,
    };
}

export function inventoriesFetchingSuccess(inventories: IInventory[]): InventoriesFetchSuccessAction {
    return {
        type: InventoryActionType.FETCHING_MULTIPLE_SUCCESS,
        inventories: inventories
    }
}

export function inventoriesFetchingSuccessFromCache(): InventoriesFetchSuccessCacheAction {
    return {
        type: InventoryActionType.FETCHING_MULTIPLE_SUCCESS_CACHED,
    }
}

export function inventoriesFetchingFailure(error?: IRestError): InventoriesFetchFailureAction {
    return {
        type: InventoryActionType.FETCHING_MULTIPLE_FAILURE,
        error,
    };
}

export function inventoryFetchingPending(inventoryId: string): InventoryFetchPendingAction {
    return {
        type: InventoryActionType.FETCHING_ONE_PENDING,
        inventoryId: inventoryId,
    };
}

export function inventoryFetchingSuccess(inventory: IInventory): InventoryFetchSuccessAction {
    return {
        type: InventoryActionType.FETCHING_ONE_SUCCESS,
        inventory,
    }
}

export function inventoryFetchingFailure(error?: IRestError): InventoryFetchFailureAction {
    return {
        type: InventoryActionType.FETCHING_ONE_FAILURE,
        error,
    };
}

export function inventoryCreatedEventRecieved(inventory: IInventory): InventoryCreatedEventRecievedAction {
    return {
        type: InventoryActionType.EVENT_CREATED,
        inventory,
    };
}

export function inventoryUpdatedEventRecieved(inventory: IInventory): InventoryUpdatedEventRecievedAction {
    return {
        type: InventoryActionType.EVENT_UPDATED,
        inventory,
    };
}

export function inventoryDeletedEventRecieved(lookup: Partial<ILookup>): InventoryDeletedEventRecievedAction {
    return {
        type: InventoryActionType.EVENT_DELETED,
        lookup,
    };
}

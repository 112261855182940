import type { Action } from "redux";
import type { Enforcer } from 'casbin';

import type { IMyPermissions } from "models/permissions/myPermissions";
import type { IRestError } from "models/common/restResult";

export type cachedEnforcements = Record<string, boolean>;
export type cachedEnforcementsPerOrg = Record<string, cachedEnforcements>;

export type GlobalPermissionState = {
    isLoading: boolean;
    hasError: boolean;
    error?: IRestError;
    myPermissions?: IMyPermissions;
    enforcer?: Enforcer;
    cachedEnforcements?: cachedEnforcementsPerOrg;
};

export enum PermissionActionType {
    PURGE = 'permissions/purge',
    LOADING = 'permissions/loading',
    LOADING_FAILURE = 'permissions/loading/failure',
    LOADING_SUCCESS = 'permissions/loading/success',
    LOADING_ORG = 'permissions/org/loading',
    LOADING_ORG_FAILURE = 'permissions/org/loading/failure',
    LOADING_ORG_SUCCESS = 'permissions/org/loading/success',
}

export interface PermissionsPurgeAction extends Action {
    type: PermissionActionType.PURGE;
}

export interface PermissionLoadingAction extends Action {
    type: PermissionActionType.LOADING;
}

export interface PermissionLoadingSuccessAction extends Action {
    type: PermissionActionType.LOADING_SUCCESS;
    permissions: IMyPermissions;
    enforcer: Enforcer;
}

export interface PermissionLoadingFailureAction extends Action {
    type: PermissionActionType.LOADING_FAILURE;
    error?: IRestError;
}

export interface PermissionOrgLoadingAction extends Action {
    type: PermissionActionType.LOADING_ORG;
    orgLongId: string;
}

export interface PermissionOrgLoadingSuccessAction extends Action {
    type: PermissionActionType.LOADING_ORG_SUCCESS;
    orgLongId: string;
    cachedEnforcements: cachedEnforcements;
}

export interface PermissionOrgLoadingFailureAction extends Action {
    type: PermissionActionType.LOADING_ORG_FAILURE;
    error?: IRestError;
}

export type PermissionAction = PermissionsPurgeAction | PermissionLoadingAction | PermissionLoadingSuccessAction | PermissionLoadingFailureAction | PermissionOrgLoadingAction | PermissionOrgLoadingSuccessAction | PermissionOrgLoadingFailureAction;

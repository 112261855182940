import { Currency } from 'models/currency';
import { IOrganizational } from 'models/common/organizational';
import { IObjectDates } from 'models/common/objectDates';

export interface IPropertyTax extends IOrganizational, IObjectDates {
    id: string;
    inventoryId: string;

    taxYear: number;
    status: PropertyTaxStatus;
    totalOwed: Currency;
    totalCollected: Currency;
    perAcre: Currency;
    dueDate: string;

    tracts: IPropertyTaxForTract[];
}

export enum PropertyTaxStatus {
    New = '',
    Draft = 'draft',
    Finalizing = 'finalizing',
    InProgress = 'in-progress',
    Reimbursed = 'reimbursed',
}

export interface IPropertyTaxForTract {
    tractId: string;
    tractLoanId?: string;
    tractLabel: string;

    acres: number;
    amountOwed: Currency;
    amountPaid: Currency;
    customAmount: boolean;
    transactionId: string;

    noticeLetterSentDate: string;
    noticeTextSentDate: string;
    dateReminded: string;
    datePaid: string;

    partOfTaxBill: boolean;
}

import { ThunkAction } from 'redux-thunk';

import { IRelatedTo } from 'models/common/relatedTo';
import { ILookup } from 'models/common/lookup';
import { IRestError } from 'models/common/restResult';
import { IPagination } from 'models/common/pagination';
import { ILoan, LoanStatus } from 'models/loan';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { getLoans, getLoanByID } from 'api/loans';
import { IRestRequestFilter } from 'models/common/restRequest';

import {
    LoanActionType,
    LoansFetchPendingAction,
    LoansFetchSuccessAction,
    LoansFetchFailureAction,
    LoanCreatedEventRecievedAction,
    LoanUpdatedEventRecievedAction,
    LoanDeletedEventRecievedAction,
    LoanFetchPendingAction,
    LoanFetchSuccessAction,
    LoanFetchFailureAction,
    LoansFetchRelatedPendingAction,
    LoansFetchRelatedSuccessAction,
    LoansFetchRelatedFailureAction,
    LoanRelatedAction,
} from 'store/types/loans';

export const fetchLoans = (page: number, pageSize: number, sortBy: string, labelFilter?: string, statusFilter?: LoanStatus[]): ThunkAction<Promise<LoansFetchSuccessAction | LoansFetchFailureAction>, GlobalState, null, LoansFetchPendingAction | LoansFetchSuccessAction | LoansFetchFailureAction> => {
    return async (dispatch, getState) => {
        // const now = new Date();
        // const fetchedAtPlusFive = new Date(getState().loans.fetchedAt);
        // if (!isNaN(fetchedAtPlusFive.getTime())) {
        //     fetchedAtPlusFive.setTime(fetchedAtPlusFive.getTime() + fiveMinutes);
        // }

        dispatch(loansFetchingPending());

        // const existing = getLoansForSelectedOrg(getState());
        // //when we have existing loans AND now is sooner than when it was fetched at plus five minutes
        // //we then return the cached results
        // if (existing.length > 0 && !isNaN(fetchedAtPlusFive.getTime()) && now.getTime() < fetchedAtPlusFive.getTime()) {
        //     return dispatch(loansFetchingSuccessFromCache());
        // }

        try {
            let filters: IRestRequestFilter[] | undefined = undefined;
            if (Array.isArray(statusFilter) && statusFilter.length !== 0) {
                filters = statusFilter.map((v) => ({ key: 'status', value: v }));
            }

            const loanResults = await getLoans(getSelectedOrgShortId(getState()), { limit: pageSize, offset: (page - 1) * pageSize, orderBy: sortBy, search: labelFilter, filters });

            return dispatch(loansFetchingSuccess(loanResults.data, !!labelFilter, loanResults.pagination));
        } catch (e) {
            return dispatch(loansFetchingFailure(e as IRestError));
        }
    }
}

export const fetchRelatedLoans = (relatedTo: IRelatedTo[]): ThunkAction<Promise<LoanRelatedAction>, GlobalState, null, LoanRelatedAction> => {
    return async (dispatch, getState) => {
        dispatch(loansFetchingRelatedPending());

        const orgId = getSelectedOrgShortId(getState());
        const toFetch = relatedTo.map((r) => getLoanByID(orgId, r.id));

        try {
            const loans = await Promise.all(toFetch);
            return dispatch(loansFetchingRelatedSuccess(loans));
        } catch (e) {
            return dispatch(loansFetchingRelatedFailure(e as IRestError));
        }
    }
}

export const fetchLoanById = (loanId: string): ThunkAction<Promise<LoanFetchSuccessAction | LoanFetchFailureAction>, GlobalState, null, LoanFetchPendingAction | LoanFetchSuccessAction | LoanFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(loanFetchingPending());

        try {
            const orgId = getSelectedOrgShortId(getState());
            const loan = await getLoanByID(orgId, loanId);

            return dispatch(loanFetchingSuccess(loan));
        } catch (e) {
            return dispatch(loanFetchingFailure(e as IRestError));
        }
    }
}

export function loansFetchingPending(): LoansFetchPendingAction {
    return {
        type: LoanActionType.FETCHING_MULTIPLE_PENDING,
    };
}

export function loansFetchingSuccess(loans: ILoan[], filtered: boolean, pagination?: IPagination): LoansFetchSuccessAction {
    return {
        type: LoanActionType.FETCHING_MULTIPLE_SUCCESS,
        loans,
        pagination,
        filtered,
    };
}

export function loansFetchingFailure(error?: IRestError): LoansFetchFailureAction {
    return {
        type: LoanActionType.FETCHING_MULTIPLE_FAILURE,
        error,
    };
}

export function loanFetchingPending(): LoanFetchPendingAction {
    return {
        type: LoanActionType.FETCHING_ONE_PENDING,
    };
}

export function loanFetchingSuccess(loan: ILoan): LoanFetchSuccessAction {
    return {
        type: LoanActionType.FETCHING_ONE_SUCCESS,
        loan,
    };
}

export function loanFetchingFailure(error?: IRestError): LoanFetchFailureAction {
    return {
        type: LoanActionType.FETCHING_ONE_FAILURE,
        error,
    };
}

export function loanCreatedEventRecieved(loan: ILoan): LoanCreatedEventRecievedAction {
    return {
        type: LoanActionType.EVENT_CREATED,
        loan,
    };
}

export function loanUpdatedEventRecieved(loan: ILoan): LoanUpdatedEventRecievedAction {
    return {
        type: LoanActionType.EVENT_UPDATED,
        loan,
    };
}

export function loanDeletedEventRecieved(lookup: Partial<ILookup>): LoanDeletedEventRecievedAction {
    return {
        type: LoanActionType.EVENT_DELETED,
        lookup,
    };
}

export function loansFetchingRelatedPending(): LoansFetchRelatedPendingAction {
    return {
        type: LoanActionType.FETCHING_RELATED_PENDING,
    };
}

export function loansFetchingRelatedSuccess(loans: ILoan[]): LoansFetchRelatedSuccessAction {
    return {
        type: LoanActionType.FETCHING_RELATED_SUCCESS,
        loans,
    };
}

export function loansFetchingRelatedFailure(error?: IRestError): LoansFetchRelatedFailureAction {
    return {
        type: LoanActionType.FETCHING_RELATED_FAILURE,
        error,
    };
}

import { get, post, postForm, del, put } from './index';

import { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import { IInventory, InventoryStatus } from 'models/inventory';
import { ISetInventoryDetailsPayload, ISetInventoryNamePayload, ISetInventoryStatusPayload } from 'models/payloads/inventories';
import { INote } from 'models/notes';
import { IFile, FileMap } from 'models/file';
import { IDescription } from 'models/common/description';
import type { IRestRequest } from 'models/common/restRequest';
import type { IRestResult } from 'models/common/restResult';

export const createInventory = (orgId: string, inventory: Partial<IInventory>) => post<Partial<IInventory>, IInventory>(`/organizations/${ orgId }/inventories`, inventory, true);
export const getInventories = (orgId: string, req?: IRestRequest) => get<IRestResult<IInventory>>(`/organizations/${ orgId }/inventories`, true, req);
export const getInventoryByID = (orgId: string, inventoryId: string) => get<IInventory>(`/organizations/${ orgId }/inventories/${ inventoryId }`, true);
export const deleteInventoryByID = (orgId: string, inventoryId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }`, true);
export const setInventoryDetails = (orgId: string, inventoryId: string, payload: ISetInventoryDetailsPayload) => put<ISetInventoryDetailsPayload, void>(`/organizations/${ orgId }/inventories/${ inventoryId }`, payload, true);
export const setInventoryStatus = (orgId: string, inventoryId: string, status: InventoryStatus) => put<ISetInventoryStatusPayload, IInventory>(`/organizations/${ orgId }/inventories/${ inventoryId }/status`, { status }, true);
export const setInventoryName = (orgId: string, inventoryId: string, name: string) => put<ISetInventoryNamePayload, IInventory>(`/organizations/${ orgId }/inventories/${ inventoryId }/name`, { name }, true);
export const verifyInventoryAddress = (orgId: string, inventoryId: string) => post<{}, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/verify-address`, {}, true);
export const updateInventoryDescription = (orgId: string, inventoryId: string, description: string) => put<{ description: string }, IDescription>(`/organizations/${ orgId }/inventories/${ inventoryId }/description`, { description }, true);

export const getInventoryNotes = (orgId: string, inventoryId: string) => get<INote[]>(`/organizations/${ orgId }/inventories/${ inventoryId }/notes`, true);
export const addInventoryNote = (orgId: string, inventoryId: string, note: Partial<INote>) => post<Partial<INote>, INote>(`/organizations/${ orgId }/inventories/${ inventoryId }/notes`, note, true);
export const updateInventoryNote = (orgId: string, inventoryId: string, note: Partial<INote>) => put<Partial<INote>, INote>(`/organizations/${ orgId }/inventories/${ inventoryId }/notes/${ note.id }`, note, true);
export const deleteInventoryNote = (orgId: string, inventoryId: string, noteId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/notes/${ noteId }`, true);

export const getInventoryFilesAsVFS = (orgId: string, inventoryId: string) => get<FileMap>(`/organizations/${ orgId }/inventories/${ inventoryId }/files?as=vfs`, true);
export const getInventoryFile = (orgId: string, inventoryId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/files/${ fileId }`, true);
export const uploadInventoryFile = (orgId: string, inventoryId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/files`, formData, true);
export const updateInventoryFile = (orgId: string, inventoryId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/files/${ fileId }`, payload, true);
export const createNewInventoryFileFolder = (orgId: string, inventoryId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const deleteInventoryFile = (orgId: string, inventoryId: string, fileId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/files/${ fileId }`, true);
